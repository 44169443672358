import { Link } from 'react-router-dom';

import Money02 from '../../layout/themes/images/icon/money-02.png';
import Eye03 from  '../../layout/themes/images/icon/eye-03.png';
import MenuFocused from  '../../layout/themes/images/icon/menu-focused-02-02.png';

import Home02Image from  '../../layout/themes/images/icon/home-02.png';
import Dashboard01Image from  '../../layout/themes/images/icon/dashboard-01.png';
import Money03Image from  '../../layout/themes/images/icon/money-03.png';
import Vente02Image from  '../../layout/themes/images/icon/vente-02.png';
import RechargementTotal02Image from  '../../layout/themes/images/icon/rechargement-total-02.png';
import Annulation02Image from  '../../layout/themes/images/icon/annulations-02.png';

import Phone03Image from  '../../layout/themes/images/icon/phone-03.png';
import Search2Image from  '../../layout/themes/images/icon/search-02.png';
import Password03Image from  '../../layout/themes/images/icon/password-03.png';
import Close02Image from '../../layout/themes/images/icon/close-02.png';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../common/constants';


function  MobileMenu () {
  const auth = useContext(AuthContext);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);  

  useEffect(()=>{
    document.addEventListener("click", closeDrawer);
    return ()=>document.removeEventListener("click", closeDrawer);
  }, [])

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };


return (
  <>
    <div
    className="mobile-menu container-fluid no-padding-section bg-white visible-xs animated fadeInUp animated-slow"
  >
    <div className="no-padding-section container-fluid full-height">
      <div
        id="button-slider"
        className="button-slider thin-border-top no-padding-section shadow-6"
        data-pgc="app-button-slider"
        data-pgc-field="app-button-slidee"
      >
        <div
          id="button-slider-demo"
          className="owl-carousel bg-transparent owl-theme"
          style={{ opacity: 1, display: "block" }}
        >
          <div className="owl-wrapper-outer">
            <div
              className="owl-wrapper"
              style={{
                width: 760,
                left: 0,
                display: "block",
                transition: "all 0ms ease 0s",
                transform: "translate3d(0px, 0px, 0px)"
              }}
            >
              <div className="owl-item" style={{ width: 95 }}>
                <Link to="/dashboard" target="_self">
                  <div className="item">
                    <img src={Dashboard01Image} className="invert" />
                    <h1>Terminaux</h1>
                  </div>
                </Link>
              </div>
              <div className="owl-item" style={{ width: 95 }}>
                <Link to="/dashboard/consultations" target="_self">
                  <div className="item">
                    <img src={Eye03} className="invert" />
                    <h1>Historique</h1>
                  </div>
                </Link>
              </div>
              <div className="owl-item" style={{ width: 95 }}>
                <Link to="/dashboard/consultations-operation" target="_self">
                  <div className="item">
                    <img src={Money02} className="invert" />
                    <h1>Opérations</h1>
                  </div>
                </Link>
              </div>
              <div className="owl-item" style={{ width: 95 }}>
                <a onClick={(event)=>{
                  event.stopPropagation()
                  setIsDrawerOpen(true);
                  
                }} >
                  <div className="item">
                    <img src={MenuFocused} />
                    <h1>Menu</h1>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="owl-controls clickable" style={{ display: "none" }}>
            <div className="owl-pagination">
              <div className="owl-page active">
                <span className="" />
              </div>
            </div>
            <div className="owl-buttons">
              <div className="owl-prev">prev</div>
              <div className="owl-next">next</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
  </div>
    <div
    className={`modal fade pg-show-modal responsive-menu responsive-modal-menu no-padding-section ${isDrawerOpen ? 'in' : ''}`}
    id="modal1"
    tabIndex={-1}
    role="dialog"
    aria-hidden="true"
    style={{marginLeft: '0px', height: '100%', overflow: 'hidden', display: isDrawerOpen ? 'block' : 'none'}}
    
    >
    <div
      className="modal-dialog modal-lg no-padding-section full-height"
      style={{
        width: "100%",
        backgroundColor: "transparent",
        marginTop: 0,
        marginLeft: 0,
        marginBottom: 0
      }}
    >
      
      <div className="modal-content full-height" style={{ backgroundColor: "transparent" }}>
        <div className="modal-body no-padding-section full-height" >
          <div
            className="modal-body no-border pad0 full-height container-fluid"
            style={{
              marginBottom: "-55px",
              paddingBottom: 160,
              padding: 0,
              marginTop: 0
            }}
          >
            <div
              className="margin-top0 bg-white full-height col-lg-4 col-md-4 sub-section animated animated-very-fast theme-maincolorback-alternative col-xs-9 col-sm-9 fadeInLeft"
              style={{
                boxShadow:
                  "rgba(0, 0, 0, 0.22) 0px 25.6px 57.6px 0px, rgba(0, 0, 0, 0.18) 0px 4.8px 14.4px 0px",
                backgroundColor: "white",
                overflow: "auto"
              }}
              onClick={(event)=>{event.stopPropagation()}}
            >
              <button
                type="button"
                data-dismiss="modal"
                style={{
                  width: "auto",
                  marginRight: 0,
                  paddingRight: 1,
                  paddingLeft: 0,
                  paddingTop: 0,
                  marginLeft: 0,
                  backgroundColor: "transparent",
                  border: "none"
                }}
                onClick={closeDrawer}
              >
                <img
                  src={Close02Image}
                  className="pull-right"
                  style={{ marginTop: 10, marginRight: 16, width: 50 }}
                />
              </button>
              <div
                className="container-fluid no-padding-section bg-transparent"
                data-pgc="mainmenu"
                data-pgc-field="mainmenu_content"
              >
                <div
                  className="appsv3-admin-bloc animated fadeIn col-xs-12 col-sm-12 col-md-12 col-lg-12 sub-section bg-transparent"
                  data-pgc="appsv3-admin-bloc"
                  style={{ backgroundColor: "transparent" }}
                >
                  <div className="container-fluid appsv3-admin-bloc-title">
                    <p data-pgc-field="appsv3-admin-bloc-title">Mylonaci</p>
                  </div>
                  <div
                    className="container-fluid gc-optionslider-link"
                    style={{ padding: 0 }}
                  >
                    <nav>
                      <ul>
                        <a target="_self" href="/">
                          <li>
                            <img src={Home02Image} />
                            Accueil
                          </li>
                        </a>
                      </ul>
                    </nav>
                  </div>
                </div>
                <div
                  className="appsv3-admin-bloc animated fadeIn col-xs-12 col-sm-12 col-md-12 col-lg-12 sub-section"
                  data-pgc="appsv3-admin-bloc"
                  style={{ backgroundColor: "transparent" }}
                >
                  <div className="container-fluid appsv3-admin-bloc-title">
                    <Link to="/dashboard/consultations" target="_self"  onClick={closeDrawer}>
                      <p data-pgc-field="appsv3-admin-bloc-title">Consultation</p>
                    </Link>
                  </div>
                  <div
                    className="container-fluid gc-optionslider-link"
                    style={{ padding: 0 }}
                  >
                    <nav className='drawer'>
                      <ul>
                        <Link target="_self" to="/dashboard"  onClick={closeDrawer}>
                          <li>
                            <img src={Dashboard01Image} />
                            Terminaux
                          </li>
                        </Link>
                        <Link to="operations" onClick={closeDrawer} target="_self">
                          <li className="optionpage1_nav">
                            <img src={Money03Image} />
                            Opérations
                          </li>
                        </Link>
                        
                        <Link to="chiffres-affaires" onClick={closeDrawer} target="_self">
                          <li className="optionpage1_nav">
                            <img src={Vente02Image} />
                            Chiffre d'affaire
                          </li>
                        </Link>                     
                        <Link to="commissions" onClick={closeDrawer} target="_self">
                          <li className="optionpage1_nav">
                            <img src={RechargementTotal02Image} />
                            Commissions
                          </li>
                        </Link>
                      </ul>
                    </nav>
                  </div>
                </div>
                <div
                  className="appsv3-admin-bloc animated fadeIn col-xs-12 col-sm-12 col-md-12 col-lg-12 sub-section"
                  data-pgc="appsv3-admin-bloc"
                  style={{ backgroundColor: "transparent" }}
                >
                  <div className="container-fluid appsv3-admin-bloc-title">
                    <Link onClick={closeDrawer} to="/dashboard/consultations-operation" target="_self">
                      <p data-pgc-field="appsv3-admin-bloc-title">Opérations</p>
                    </Link>
                  </div>
                  <div
                    className="container-fluid gc-optionslider-link"
                    style={{ padding: 0 }}
                  >
                    <nav>
                      <ul>
                        <Link onClick={closeDrawer} target="_self" to="/dashboard/rechargements">
                          <li>
                            <img src={Annulation02Image} />
                            Rechargement
                          </li>
                        </Link>
                      </ul>
                    </nav>
                  </div>
                </div>
                <div
                  className="appsv3-admin-bloc animated fadeIn col-xs-12 col-sm-12 col-md-12 col-lg-12 sub-section"
                  data-pgc="appsv3-admin-bloc"
                  style={{ backgroundColor: "transparent" }}
                >
                  <div className="container-fluid appsv3-admin-bloc-title">
                    <a href="/profil" target="_self">
                      <p data-pgc-field="appsv3-admin-bloc-title">
                        Profil et paramètres
                      </p>
                    </a>
                  </div>
                  <div
                    className="container-fluid gc-optionslider-link"
                    style={{ padding: 0 }}
                  >
                    <nav>
                      <ul>
                        <a target="_self" href="/profil">
                          <li>
                            <img src={Phone03Image} />
                            Profil
                          </li>
                        </a>
                        <a target="_self" href="/dashboard/codes-distributeurs">
                          <li  className="optionpage1_nav" >
                            <img src={Search2Image} />
                            <div className='optionpage1_nav_title'>Mes codes</div> 
                          </li>
                        </a>
                        <a href="/profil" target="_self" />
                        <a href="/profil" target="_self">
                          <li className="optionpage1_nav">
                            <img src={Password03Image} />
                            Mot de passe
                          </li>
                        </a>
                        <a onClick={auth.onLogout} target="_self">
                          <li className="optionpage1_nav">
                            <img src={Password03Image} />
                            Se deconnecter
                          </li>
                        </a>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
              <div
                className="shadow-6 no-border responsive-modal-menu"
                style={{}}
                data-animation-pro-1=""
                data-animation-pro-trigger-1="class"
                data-animation-pro-class-name-1="animate"
                data-animation-pro-target-1="> .modal-content"
                data-animation-pro-init-state-1="opacity:0;transform:translateX(-100%);"
                data-animation-pro-class-removed-step-1-1="opacity:0;transform:translateX(100%);"
                data-animation-pro-2=""
                data-animation-pro-trigger-2="class"
                data-animation-pro-class-name-2="animate"
                data-animation-pro-class-removed-rm-classes-2="open"
                data-animation-pro-delay-2={500}
                data-animation-pro-easing-1="ease-out-quad"
                data-animation-pro-step-1-1="opacity:1;transform:translateX(0);"
                data-animation-pro-duration-1={300}
                data-animation-pro-class-removed-step-2-1="transform:translateX(-100%);"
                data-pgc-field="modal-lg"
              >
                <div
                  className="no-border shadow-6"
                  style={{
                    opacity: 0,
                    transform: "translateX(-100%)",
                    paddingRight: 0,
                    height: "100%"
                  }}
                >
                  <div data-pgc-field="modal-title">
                    <div
                      className="col-lg-9 col-md-9 col-sm-9 col-xs-9"
                      style={{ verticalAlign: "middle", display: "inline-block" }}
                    ></div>
                    <div
                      className="col-lg-3 col-md-3 col-sm-3 col-xs-3"
                      style={{ margin: 0, padding: 0 }}
                    ></div>
                  </div>
                  <div data-pgc="appsv3-admin-tabpanes">
                    <ul className="nav nav-tabs hidden">
                      <li className="active"></li>
                      <li className="hidden">
                        <a href="#tab2" data-toggle="tab">
                          Recherche
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content appsv3-tabpanes responsive-menu theme-maincolorback-alternative full-height">
                      <div className="container-fluid">
                        <button
                          type="button"
                          data-animation-pro-1=""
                          data-animation-pro-trigger-1="click"
                          data-animation-pro-target-1="^ .ap-modal | .modal-bg"
                          data-animation-pro-rm-classes-1="open"
                          data-animation-pro-sd-click-rm-classes-1="open"
                          data-animation-pro-2=""
                          data-animation-pro-trigger-2="click"
                          data-animation-pro-target-2="^ .ap-modal"
                          data-animation-pro-rm-classes-2="animate"
                          data-animation-pro-sd-click-rm-classes-2="animate"
                          data-pgc-field="btn-close-title"
                          style={{
                            width: 25,
                            marginRight: 0,
                            paddingRight: 1,
                            paddingLeft: 0,
                            paddingTop: 0,
                            marginLeft: 40,
                            backgroundColor: "transparent",
                            border: "none"
                          }}
                        >
                          <img
                            src={Close02Image}
                            className="pull-right"
                            style={{ marginTop: 10, marginRight: 16, width: 50 }}
                          />
                        </button>
                      </div>
                      <div
                        className="tab-pane active theme-maincolorback-alternative"
                        id="tab1"
                      >
                        <div className="col-lg-11 col-md-11 col-sm-11 col-xs-11 full-height no-padding-section"></div>
                      </div>
                      <div className="tab-pane" id="tab2">
                        <div className="form-group">
                          <div className="appsv3-search">
                            <input
                              type="text"
                              className="form-control"
                              id="formInput20"
                              placeholder="Recherche"
                              style={{ paddingLeft: 60 }}
                            />
                            <button type="button" className="btn">
                              <span className="glyphicon glyphicon-search" />
                            </button>
                          </div>
                          <div className="appsv3-search-result">
                            <p>
                              ragraphLorem ipsum dolor sit amet, consectetur
                              adipiscing elit. Phasellus pulvinar faucibus neque,
                              nec rhoncus nunc ultrices sit amet. Curabitur ac
                              sagittis neque, vel egestas est. Aenean elementum,
                              erat at aliquet hendrerit, elit nisl posuere tortor,
                              id suscipit diam dui sed nisi
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="full-height col-lg-8 col-md-8 col-xs-2 col-sm-2"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

</>
    )
}

export default MobileMenu;