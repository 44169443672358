import {useState, useEffect} from 'react';


import ToTtop from '../../layout/themes/images/icon/to-top-02-02.png';
import LogoLonaci from '../../layout/themes/images/lonaci_logo.png';
import WorkImage from '../../layout/themes/images/hero/work.jpeg'

import SoftAndroid from '../../layout/themes/images/icon/soft-03.png';
import SoftIphone from '../../layout/themes/images/icon/soft-04.png';
import { UseAuthentification } from '../../common/hooks';

import { Button } from '../../components';
import { UserService } from '../../common/services';

import {Layout} from '..'
import { Link } from 'react-router-dom';


function ButtonProfil ({ isEditable, onEdit, onSave, onCancel}) {
    const [isLoading, setLoading] = useState(false)
    return (
         isEditable ? 
         <div>
            <Button {...{isLoading}} type="button" className="btn btn-default pull-right" onClick={async() =>{
                try{
                setLoading(true);
                 await onSave();
                }finally{
                 setLoading(false);
                }
            }} >Valider</Button>
            <button type="button" className="btn btn-default pull-right"  onClick={onCancel} style={{marginRight : '10px'}} >Annuler</button>
         </div> : 
         <button type="button" className="btn btn-default pull-right" onClick={onEdit} >Modifier</button> 
    )
}

function  Profil () {

    const auth  = UseAuthentification();
    const [user, setUser] = useState({})
    const [passWord, setPassWord] = useState({oldPassword : "", newPassword : "", confirmNewPassword : ""})
    const [isEditable, setIsEditable] = useState({name : false, email : false, mdp : false, mobile : false});
    
    useEffect(() => {
         setUser({email : auth?.currentUser?.email, nom : auth?.currentUser?.nom, prenom : auth?.currentUser?.prenom, mobile : auth?.currentUser?.contact})
    }, [auth?.currentUser])

    const onUpdate = async () => await UserService.update(user)

    const onChangePassWord = async () => {  
        if(passWord.newPassword && passWord.oldPassword && passWord.confirmNewPassword){
           const data = await UserService.changePassword(passWord);
           if(data?.status) {
            setIsEditable(_old => ({..._old, mdp : false}))
            setPassWord({})
           }else{
            alert(data.message)
           }
        }
    }


    return (
     <Layout>
        <div style={{ marginTop: "10px" }}>
            <div className="container bg-transparent" style={{backgroundImage:'url(/images/mainback.jpg)',width: '40px', height: '40px', opacity: 1, position: 'fixed', zIndex: 6000, bottom: '30px', right: '30px', textIndent: '-9999px', background: 'url("icon_top.png") no-repeat', padding: '0px'}}>
                <a href="#" className="scrollup pull-right" style={{textAlign: 'right'}}>
                <button type="button" className="btn bg-white margin-top0 black thin-border theme-opacity-half" style={{color: '#ffffff', width: '60px', height: '60px', paddingLeft: '10px', paddingRight: '10px', paddingTop: '10px'}}>
                    <img src={ToTtop} width="30px" className="invert" />
                    <span className="hidden">TOP</span>
                </button>
                </a>
            </div>

            <div  className="navbar-header bg-white" style={{marginTop: '-28px'}}>
                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-12 hidden-lg hidden-md hidden-xs hidden-sm" style={{paddingLeft: '0px'}}>
                <a href="#fullmenu" data-toggle="modal">
                    <button type="button" className="navbar-toggle pull-left" style={{width: '100%'}}> 
                    <div style={{float: 'left!important'}}>
                        <img src={LogoLonaci} className="margin-top5" style={{marginLeft: '15px'}} width="100px" />
                    </div>
                    <span className="sr-only">Toggle navigation</span> 
                    </button>
                </a>                 
                </div>
                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-12 hidden-lg hidden-md hidden-sm visible-xs margin-top5">
                <ul className="nav navbar-nav pad-bottom0 no-border bg-transparent" style={{width: '100%'}}> 
                    <li className="margin-top0 pull-right hidden">
                    <a href="#searchin" className="ivonedlink animated fadeIn theme-text-medium black margin-bottom0 margin-top20" data-pgc="iconelink" data-toggle="modal"><span className="glyphicon glyphicon-search" style={{marginTop: '5px', float: 'left'}} /></a>
                    </li>
                    {!auth?.isAuthenticated  && (<li className="nav-item pull-right">
                    <a data-hover="dropdown" data-delay={0} data-close-others="false" href="/login" target="_self" className="white theme-maincolorback-dark" style={{color: '#fff !important'}}>SE CONNECTER </a> 
                    </li>)}                     
                </ul>                 
                </div>             
            </div>

            <header className="hidden-xs hidden-sm no-padding-section no-border" data-pgc="scrolling_header" data-pgc-field="scrolling_header" style={{paddingTop: '0px!IMPORTANT', width: '100%'}}>
                <div className="pad0 hidden-xs hidden-sm visible-md visible-lg container bg-transparent" style={{padding: '0px!important', marginBottom: '0px'}}>
                <div className="container-fluid alpha animated fadeIndown fadeInDown bg-transparent hidden" data-pgc="dubai" data-pgc-field="dubainavigation" style={{marginBottom: '0px'}}>
                    <div className="row header alpha animated fadeIn bg-transparent">
                    <div className="col-md-9 col-lg-9 col-sm-9 visible-xs hidden-lg hidden-md col-xs-8">
                        <a href="#fullmenu2" data-toggle="modal" className="pull-right hidden-lg hidden-md visible-sm visible-xs silver responsive-link"><span className="glyphicon glyphicon-menu-hamburger" /></a> 
                    </div>
                    <div className="hidden-xs full-height bg-transparent col-lg-5 col-md-5 col-sm-5" style={{height: '100%', display: 'inline-block', float: 'left'}}>
                        <ul className="nav navbar-nav pad-bottom0 no-border topped" style={{width: '100%'}}> 
                        <li className="margin-top0 hidden">
                            <a href="#searchin" className="ivonedlink animated fadeIn theme-text-medium black margin-bottom0 margin-top20" data-pgc="iconelink" data-toggle="modal"><span className="glyphicon glyphicon-search" style={{marginTop: '5px', float: 'left'}} /></a>
                        </li>
                        <li className="nav-item">
                            <a data-hover="dropdown" data-delay={0} data-close-others="false" href="#particuliers" target="_self">PARTICULIERS<i className="fa fa-angle-down" /></a> 
                        </li>
                        <li className="nav-item">
                            <a data-hover="dropdown" data-delay={0} data-close-others="false" href="#entreprises" target="_self">ENTREPRISES<i className="fa fa-angle-down" /></a> 
                        </li>                                 
                        </ul>
                    </div>
                    <div className="hidden-xs full-height col-lg-7 col-md-7 col-sm-7 col-xs-7" style={{height: '100%', display: 'inline-block', float: 'left'}}>
                        <ul className="nav navbar-nav pad-bottom0 no-border topped" style={{width: '100%'}}> 
                        <li className="margin-top0 pull-right hidden">
                            <a href="#searchin" className="ivonedlink animated fadeIn theme-text-medium black margin-bottom0 margin-top20" data-pgc="iconelink" data-toggle="modal"><span className="glyphicon glyphicon-search" style={{marginTop: '5px', float: 'left'}} /></a>
                        </li>
                        <li className="nav-item pull-right">
                            <a data-hover="dropdown" data-delay={0} data-close-others="false" href="#pricing" target="_self" className="white">Inscription <i className="fa fa-angle-down" /></a> 
                        </li>
                        {!auth?.isAuthenticated  && (<li className="nav-item pull-right">
                            <a data-hover="dropdown" data-delay={0} data-close-others="false" href="/login" target="_self" className="white theme-maincolorback2">SE CONNECTER<i className="fa fa-angle-down" /></a> 
                        </li>)}
                        <li className="dropdown nav-item pull-right hidden"> 
                            <a href="#" className="dropdown-toggle" data-toggle="dropdown">Dropdown <b className="caret" /></a> 
                            <ul className="dropdown-menu"> 
                            <li>
                                <a href="#">Action</a>
                            </li>                                         
                            <li>
                                <a href="#">Another action</a>
                            </li>                                         
                            <li>
                                <a href="#">Something else here</a>
                            </li>                                         
                            <li className="divider" />                                         
                            <li>
                                <a href="#">Separated link</a>
                            </li>                                         
                            <li className="divider" />                                         
                            <li>
                                <a href="#">One more separated link</a>
                            </li>                                         
                            </ul>                                     
                        </li>
                        <li className="nav-item pull-right">
                            <a data-hover="dropdown" data-delay={0} data-close-others="false" href="#success-story" target="_self">SUPPORTS<i className="fa fa-angle-down" /></a> 
                        </li>
                        <li className="nav-item pull-right">
                            <a data-hover="dropdown" data-delay={0} data-close-others="false" href="#missions" target="_self" className="soft-scroll">FAQ   <i className="fa fa-angle-down" /></a> 
                        </li>                                 
                        </ul>
                    </div>
                    </div>                     
                </div> 
                </div>
            </header>

            <div style={{ margin: '-24px' }} className="container-fluid no-padding-section bg-transparent pad0" data-pgc-field="main_content">
                <div  className="container-fluid sub-section no-padding-section" style={{ padding: "0px!important" }}>
                    <div className="content-appv2 full-height bg-offwhite visible-md visible-lg visible-sm visible-xs col-xs-12 col-sm-12 no-padding-section col-md-12 col-lg-12"
                        style={{ overflow: "auto" }}>
                    <div className="thefillcontent full-height">
                        
                        <div
                        data-spy="scroll"
                        data-target=".navbar"
                        data-offset={70}
                        className="no-padding-section container-fluid"
                        >
                        <div className="row gc-user-profile-1" style={{ margin: 0 }}
                            data-pgc-define="userprofilehome"
                            data-pgc-define-name="userprofile_home"
                            data-pgc-section="userprofile">
                                <div
                                className="row App-profil__row__header gc-user-profile-header animated fadeInUp"
                                style={{backgroundColor: '#09C', color: '#FFF', backgroundImage: 'url("/images/mainback.jpg")', backgroundSize: 'cover', padding: '1px'}}
                                data-pgc-edit="user_profiletop"
                                >
                                <div className="col-md-2 gc-user-profile-picture">
                                    
                                </div>
                                <div className="col-md-7 col-xs-12 col-sm-12">
                                        <h3>Identifiant de connexion</h3>
                                        <p style={{fontSize: '28px !important'}} className='white'>
                                            {auth?.currentUser?.email ?? ''}
                                        </p>
                                    </div>
                                <div className="col-md-3 col-xs-12 col-sm-12" >
                                    <h3>Identifiant unique UID</h3>
                                    <p className='white'> {auth?.currentUser?.lcaisseId ?? ''} </p>
                                </div>
                            
                                </div>
                            <div className="row" style={{ margin: 0 }}>
                            <div
                                style={{ padding: 0 }}
                                className="col-md-2 gc-user-profile-left"
                                data-pgc-edit="userleft">
                                <ul className="list-group">
                                    {/* <Link to="/dashboard">
                                        <li className="list-group-item" style={{fontSize: '10px !important', fontWeight: 'bold'}}>Compte</li>
                                    </Link> */}
                                    <Link  onClick={auth.onLogout}>
                                        <li className="list-group-item">Deconnexion</li>
                                    </Link>
                                </ul>
                            </div>
                            <div className="gc-profil-content col-md-10 bg-offwhite">
                                <div className="profil_content_dynamic">
                                <div className="englobe">
                                    {/* <h1 data-pgc-edit="user_content ttle">Informations générales</h1> */}
                                    <div className="col-lg-12 col-md-6 col-sm-6 col-xs-12" style={{marginTop: '10px'}}>
                                            <div className="bg-offwhite">
                                                <div className="appsv3-admin-bloc animated fadeIn col-xs-12 col-sm-12 col-md-12 col-lg-12 sub-section" style={{    marginBottom: '-45px'}}>
                                                <div className="container-fluid appsv3-admin-bloc-title">
                                                    <h1 data-pgc-edit="user_content ttle">Informations générales</h1>
                                                </div>
                                                <div className="thin-border-top sub-section">
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    <div className="container-fluid no-padding-section" style={{background : 'white'}}>
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                <div style={{minHeight: '300px'}} className="appsv3-admin-bloc animated fadeIn sub-section">
                                                <div className="container-fluid appsv3-admin-bloc-title" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                                    <p data-pgc-field="appsv3-admin-bloc-title" style={{marginRight: 'auto'}}>Nom et prenom</p>
                                                    <ButtonProfil 
                                                    isEditable={isEditable.name}
                                                    onSave={async () => {
                                                        await onUpdate()
                                                        window.location.reload();
                                                    }} 
                                                    onEdit={() => setIsEditable(_old => ({..._old, name : true}))} 
                                                    onCancel={() => setIsEditable(_old => ({..._old, name : false}))}/>
                                                </div>
                                                <div className="container-fluid gc-optionslider-link thin-border-top sub-section" style={{ padding: 0 }}>
                                                    <div className="form-group">
                                                    <input type="text" className="form-control"
                                                        id="formInput11"
                                                        value={isEditable.name ? `${user?.nom ?? ''}` : `${user?.prenom ?? ''} ${user?.nom ?? ''}`}
                                                        placeholder={isEditable.name ? `${user?.nom ?? ''}` : `${user?.prenom ?? ''} ${user?.nom ?? ''}`}
                                                        disabled={!isEditable.name}                                                      
                                                        onChange={(event) => setUser(_data => ({..._data, nom : event.target.value}))}
                                                        style={{marginBottom : '10px'}}
                                                    />
                                                    {isEditable.name && <input type="text" className="form-control"
                                                        id="formInput11"
                                                        value={`${user?.prenom ?? ''}`}
                                                        placeholder={`${user?.prenom ?? ''}`}
                                                        disabled={!isEditable.name}                                                      
                                                        onChange={(event) => setUser(_data => ({..._data, prenom : event.target.value}))}
                                                    />}
                                                    </div>
                                                </div>
                                                </div>

                                                <div className="appsv3-admin-bloc  animated fadeIn sub-section" style={{minHeight: '300px'}}>
                                                <div className="container-fluid appsv3-admin-bloc-title" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                                    <p data-pgc-field="appsv3-admin-bloc-title" style={{marginRight: 'auto'}}>Email</p>
                                                    {/*<ButtonProfil 
                                                    isEditable={isEditable.email}
                                                    onSave={async () => {
                                                        await onUpdate()
                                                        setIsEditable(_old => ({..._old, email : false}))
                                                    }} 
                                                    onEdit={() => setIsEditable(_old => ({..._old, email : true}))} 
                                                onCancel={() => setIsEditable(_old => ({..._old, email : false}))}/> */}
                                                </div>
                                                <div className="container-fluid gc-optionslider-link thin-border-top sub-section"
                                                    style={{ padding: 0 }}>
                                                    <div className="form-group">
                                                    <input type="email"
                                                        className="form-control bold"
                                                        id="formInput11"
                                                        value={user?.email ?? ''}
                                                        placeholder={user?.email ?? ''}
                                                        disabled={!isEditable.email}
                                                        onChange={(event) => setUser(_data => ({..._data, email : event.target.value}))}
                                                    />
                                                    </div>
                                                </div>
                                                </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                <div className="appsv3-admin-bloc animated fadeIn sub-section" style={{minHeight: '300px'}}>
                                                <div className="container-fluid appsv3-admin-bloc-title" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                                    <p data-pgc-field="appsv3-admin-bloc-title" style={{marginRight: 'auto'}}>Mot de passe</p>
                                                    <ButtonProfil 
                                                    isEditable={isEditable.mdp}
                                                    onSave={async () => {
                                                        await onChangePassWord();
                                                    }} 
                                                    onEdit={() => setIsEditable(_old => ({..._old, mdp : true}))} 
                                                    onCancel={() =>{
                                                         setIsEditable(_old => ({..._old, mdp : false}))
                                                         setPassWord({})
                                                         }}/>
                                                </div>
                                                <div className="container-fluid gc-optionslider-link thin-border-top sub-section"
                                                    style={{ padding: 0 }}>
                                                    <div className="form-group">
                                                    <input
                                                        type="password"
                                                        className="form-control bold"
                                                        value={passWord.oldPassword ?? ''}
                                                        placeholder="Mot de passe actuel ..."
                                                        disabled={!isEditable.mdp}
                                                        style={{marginBottom : '10px'}}
                                                        onChange={(event) => setPassWord(_data => ({..._data, oldPassword : event.target.value}))}
                                                    />
                                                    {isEditable.mdp && (
                                                        <>
                                                        <input
                                                        type="password"
                                                        className="form-control bold"
                                                        value={passWord.newPassword ?? ''}
                                                        placeholder="Nouveau mot de passe ..."
                                                        disabled={!isEditable.mdp}
                                                        style={{marginBottom : '10px'}}
                                                        onChange={(event) => setPassWord(_data => ({..._data, newPassword : event.target.value}))}
                                                        />
                                                        <input
                                                            type="password"
                                                            className="form-control bold"
                                                            value={passWord.confirmNewPassword ?? ''}
                                                            placeholder="Confirmez nouveau mot de passe ..."
                                                            onChange={(event) => setPassWord(_data => ({..._data, confirmNewPassword : event.target.value}))}
                                                        />
                                                    </>)}
                                                    {passWord.newPassword  && passWord.confirmNewPassword && passWord.newPassword !== passWord.confirmNewPassword && (<center style={{marginTop : '10px'}} className="form-group login-page__errors">
                                                    Les mots de passe ne sont pas identiquent
                                                </center>)}
                                                    </div>
                                                </div>
                                            </div>
                                                <div className="appsv3-admin-bloc animated fadeIn sub-section" style={{minHeight: '300px'}}>
                                                <div className="container-fluid appsv3-admin-bloc-title" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                                    <p data-pgc-field="appsv3-admin-bloc-title" style={{marginRight: 'auto'}}>Mobile</p>
                                                   {/* <ButtonProfil 
                                                    isEditable={isEditable.mobile}
                                                    onSave={async () => {
                                                        await onUpdate()
                                                        setIsEditable(_old => ({..._old, mobile : false}))
                                                    }} 
                                                    onEdit={() => setIsEditable(_old => ({..._old, mobile : true}))} 
                                                onCancel={() => setIsEditable(_old => ({..._old, mobile : false}))}/>*/}
                                                </div>
                                                <div className="container-fluid gc-optionslider-link thin-border-top sub-section"
                                                    style={{ padding: 0 }}>
                                                    <div className="form-group">
                                                    <input type="text"
                                                        className="form-control bold"
                                                        id="formInput11"
                                                        onChange={(event) => setUser(_data => ({..._data, mobile : event.target.value}))}
                                                        placeholder={user?.mobile ?? ''}
                                                        value={user?.mobile ?? ''}
                                                        disabled={!isEditable.mobile}
                                                    />
                                                    </div>
                                                </div>
                                                {/* <button type="button" className="btn btn-default pull-right">
                                                Modifier
                                                </button> */}
                                            </div>
                                        </div>
                                    </div>
                                    </div>

                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>


            <div className="text-left section container-fluid thin-border-top bg-white theme-maincolorback-light">
                <div className="container">
                <div className="container-fluid bg-transparent" style={{backgroundColor: 'transparent !important'}}>
                    <div className="gc-block-header container-fluid animated text-center bg-transparent slower" data-pgc="gc-block-header" data-pgc-field="gc-block-header">
                    <h1 className="title theme-text-bold">TÉLÉCHARGER L'APPLICATION</h1>
                    </div>
                    <p className="gc-bloc-sub-header animated animatedParent_fadeIn">Téléchargez pour Android et Apple iOS </p>
                </div>
                </div>
                <div className="text-center container">
                <div className="col-md-6 col-lg-6 col-sm-6 col-xs-12 sub-section">
                    <a href>
                    <div className="sub-section theme-maincolorback-dark">
                        <img src={SoftAndroid} style={{marginTop: '10px', width: '60%'}} />
                    </div>
                    </a>                     
                </div>
                <div className="col-md-6 col-lg-6 col-sm-6 col-xs-12 sub-section">
                    <a href>
                    <div className="sub-section theme-maincolorback-dark">
                        <img src={SoftIphone} style={{marginTop: '10px', width: '60%'}} />
                    </div>
                    </a>                     
                </div>
                </div>
            </div>


            <div
                className="container-fluid theme-full-gradient-back5"
                style={{ padding: 0 }}>
                <footer id="footer" role="contentinfo">
                    <div className="container">
                    <div className="container-fluid sub-section">
                        <div className="footer--widgets" role="complementary">
                        <div
                            id="custom_html-1"
                            className="col-lg-3 col-md-3 sub-section col-xs-12 col-sm-12"
                        >
                            <div className="textwidget custom-html-widget">
                            <div style={{ textAlign: "justify !import" }}>
                                <img
                                src="https://www.lonaci.ci/wp-content/uploads/2021/10/logo.png"
                                width={171}
                                loading="lazy"
                                alt="LONACI"
                                />
                                <br />
                                <br />
                                <strong>Siège Social</strong>: Bvd de Marseille Zone 4, Rue du
                                Chevalier de Clieu
                                <br />
                                Abidjan, Marcory
                                <br />
                                <strong>Tel</strong>: +225 2721753300
                                <strong>Poste</strong>: 2223
                                <br />
                                <strong>Ligne Directe</strong>:
                                <a href="tel:+225 2721753323" className="">
                                +225 2721753323
                                </a>
                                <br />
                                01 BP 3878 Abidjan 01
                            </div>
                            </div>
                        </div>
                        <div
                            id="nav_menu-5"
                            className="col-lg-3 col-md-3 sub-section col-xs-12 col-sm-12 hidden-xs hidden-sm"
                        >
                            <h2 className="widgettitle">Nos Produits</h2>
                            <div className="menu-produits-container">
                            <ul id="menu-produits" className="menu">
                                <li
                                id="menu-item-1476"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1476"
                                >
                                <a href="https://www.lonaci.ci/grattage/">Grattage</a>
                                </li>
                                <li
                                id="menu-item-1477"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1477"
                                >
                                <a href="https://www.lonaci.ci/loto_bonheur/" className="">
                                    Loto Bonheur
                                </a>
                                </li>
                                <li
                                id="menu-item-1478"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1478"
                                >
                                <a href="https://www.lonaci.ci/sportcash/" className="">
                                    Sportcash
                                </a>
                                </li>
                                <li
                                id="menu-item-1479"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1479"
                                >
                                <a href="https://www.lonaci.ci/pmu/">PMU</a>
                                </li>
                                <li
                                id="menu-item-1480"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1480"
                                >
                                <a
                                    href="https://www.lonaci.ci/loterie-virtuelle/"
                                    className=""
                                >
                                    Loterie Virtuelle
                                </a>
                                </li>
                                <li
                                id="menu-item-2351"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2351"
                                >
                                <a href="https://www.lonaci.ci/casino-cash/" className="">
                                    Casino Cash
                                </a>
                                </li>
                            </ul>
                            </div>
                        </div>
                        <div
                            id="nav_menu-2"
                            className="col-lg-3 col-md-3 col-sm-3 col-xs-3 sub-section hidden-xs hidden-sm"
                        >
                            <h2 className="widgettitle">Liens Utiles</h2>
                            <div className="menu-liens-utiles-container">
                            <ul id="menu-liens-utiles" className="menu">
                                <li
                                id="menu-item-1645"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1645"
                                >
                                <a href="https://www.lonaci.ci/charte-lonaci/" className="">
                                    Notre Charte
                                </a>
                                </li>
                                <li
                                id="menu-item-1649"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1649"
                                >
                                <a
                                    href="https://www.lonaci.ci/mentions-legales/"
                                    className=""
                                >
                                    Mentions Légales
                                </a>
                                </li>
                                <li
                                id="menu-item-1646"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1646"
                                >
                                <a
                                    href="https://www.lonaci.ci/devenir-partenaire/"
                                    className=""
                                >
                                    Devenir Partenaire
                                </a>
                                </li>
                                <li
                                id="menu-item-1647"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1647"
                                >
                                <a href="#" className="">
                                    Où Jouer ?
                                </a>
                                </li>
                                <li
                                id="menu-item-1648"
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1648"
                                >
                                <a href="https://www.lonaci.ci/faq/">FAQ</a>
                                </li>
                            </ul>
                            </div>
                        </div>
                        <div
                            id="block-8"
                            className="col-lg-3 col-md-3 col-sm-3 col-xs-3 sub-section hidden-sm hidden-xs"
                        >
                            <h2 className="widgettitle">Suivez-nous !</h2>
                            <p />
                            <ul className="social-networks rounded colored-hover">
                            <li>
                                <a
                                href="https://www.facebook.com/loterienationaledecotedivoire/"
                                target="_blank"
                                className="facebook"
                                title="Facebook"
                                aria-label="Facebook"
                                rel="noopener"
                                >
                                <i className="fab fa-facebook" />
                                <span className="name">Facebook</span>
                                </a>
                            </li>
                            <li>
                                <a
                                href="https://www.youtube.com/channel/UC6B9-wW2GB8GYty7bhzQujQ/featured"
                                target="_blank"
                                className="youtube"
                                title="YouTube"
                                aria-label="YouTube"
                                rel="noopener"
                                >
                                <i className="fab fa-youtube" />
                                <span className="name">YouTube</span>
                                </a>
                            </li>
                            <li>
                                <a
                                href="https://twitter.com/LONACI_officiel"
                                target="_blank"
                                className="twitter"
                                title="Twitter"
                                aria-label="Twitter"
                                rel="noopener"
                                >
                                <i className="fab fa-twitter" />
                                <span className="name">Twitter</span>
                                </a>
                            </li>
                            <li>
                                <a
                                href="https://www.instagram.com/loterie_nationale_ci/"
                                target="_blank"
                                className="instagram"
                                title="Instagram"
                                aria-label="Instagram"
                                rel="noopener"
                                >
                                <i className="fab fa-instagram" />
                                <span className="name">Instagram</span>
                                </a>
                            </li>
                            </ul>
                            <p />
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="footer-bottom">
                    <div className="container">
                        <div className="footer-bottom-content">
                        <div className="footer-content-left">
                            <div className="copyrights site-info">
                            <p className="hosted-copy">
                                © Copyright 2021&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                                <a href="#" target="_blank" rel="noopener">
                                LONACI
                                </a>{" "}
                                Powered By{" "}
                                <a href="" rel="noopener">
                                Direction des Systèmes de l'Information - DSI
                                </a>
                                &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;All Rights Reserved
                            </p>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </footer>
            </div>
        </div>
     </Layout>
    )
}

export default Profil;