
import { useMemo, useState } from 'react';

import { LOCAL_STORAGE_KEYS } from './common/constants'; 
import './App.scss';

import {
  createBrowserRouter,
  RouterProvider,
  redirect
} from "react-router-dom";

import { UseAuthentification } from './common/hooks';
import { UserService } from './common/services';
import { AuthContext, AppContext } from './common/constants';

import {Profil,Home, MainLayout, Login, Operations, ChiffreAffaire, Commissions, Rechargements, Terminal, TerminalDetails, Consultation} from './pages';
import Operation from './pages/consultation/operation.component';
import { DateHelper } from './common/helper';
import CodeDistributeur from './pages/distributeur/code-distributeurs.page';



function App() {


const [appStore, setAppState]  = useState({month : DateHelper.getMonthAndYear()});

const auth = UseAuthentification();

const dispatcher = (newState) => setAppState(_state => ({..._state, ...newState}))



//const [timeout, setTimeoutId] = useState(null);

// useEffect(() => {
//   const logout = () => {
//     // Mettre ici le code de déconnexion de l'utilisateur
//     auth.onLogout();
//   };

//   const timer = setTimeout(logout, 5 * 60 * 1000);

//   setTimeoutId(timer);

//   // Nettoyer le délai d'attente lors du démontage du composant ou lorsque le délai doit être réinitialisé
//   return () => {
//     clearTimeout(timeout);
//   };
// }, []);


const routers = useMemo(() => createBrowserRouter([
  {
    path: "/dashboard",
    element: <MainLayout />,
    errorElement: <div>Not found</div>,
    children: [
      {
        path: "",
        element: <Terminal />,
      },
      {
        path: "terminal/:id",
        element: <TerminalDetails />,
      },
      {
        path: "operations",
        element: <Operations />,
      } ,
      {
        path: "chiffres-affaires",
        element: <ChiffreAffaire />,
      },
      {
        path: "commissions",
        element: <Commissions />,
      },
      {
        path: "rechargements",
        element: <Rechargements />,
      },
      {
        path: "consultations",
        element: <Consultation />,
      },
      {
        path: "consultations-operation",
        element: <Operation />,
      },

      {
        path: "codes-distributeurs",
        element: <CodeDistributeur />
      },
      
    ],
    loader : async () =>  {
      try{
      return await UserService.getUserInfo()
      }catch(error){
        auth.onLogout();
      }
    }
  },
  {
    path: "/",
    element: <Home />,
    errorElement: <div>Not found</div>,
    loader : async () => {
      try{
      const searchParams = new URLSearchParams(window.location.search);
      if(localStorage.getItem(LOCAL_STORAGE_KEYS.DEVICE_MODE) === "MOBILE" || searchParams.get("device") === "MOBILE"){ 
        if(searchParams.get("device") === "MOBILE") localStorage.setItem(LOCAL_STORAGE_KEYS.DEVICE_MODE, searchParams.get("device"));
        return redirect(auth?.isAuthenticated ? "/dashboard" : "/login")
       }
      }catch(error){
        auth.onLogout();
      }
     return auth;
    },
  },
  {
    path: "/profil",
    element: <Profil />,
    errorElement: <div>Not found</div>
  },
  
  {
  path: "/login",
  element: <Login />,
  errorElement: <div>Not found</div>,
  }
]), []);

return (
    <div className="App">
      <AuthContext.Provider value={auth}>
        <AppContext.Provider value={{store : appStore, dispatcher}}>
         <RouterProvider router={routers} />
         </AppContext.Provider>
      </AuthContext.Provider>
    </div>
  );
}

export default App;
