import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';

export default {
    export : async (data, fileName)  => {
       const ws = XLSX.utils.json_to_sheet(data);
       const wb = {Sheets : {'data' : ws}, SheetNames : ["data"]};
       const excelBuffer = XLSX.write(wb, {bookType : 'xlsx', type : 'array'});
       const blobData = new Blob([excelBuffer], {type : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8'})
       FileSaver.saveAs(blobData, fileName + ".xlsx")
     }
}
