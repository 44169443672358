
import { useCallback, useEffect, useMemo, useState } from 'react';

import Filter02Image from  '../../layout/themes/images/icon/filter-02.png';
import printImage from '../../layout/themes/images/icon/print-03.png'

import { NoResults, Modal } from '../';
import { ExcelHelper } from '../../common/helper';

import { usePagination } from '../../common/hooks';

import './table.component.scss'

const Table = ({templates, data, pageSize = 10, noResults = null}) => {

    const [searchTerm, setSearchTerm] = useState("");
    const [page, setPage] = useState(1);
    const [isFilterModalOpen, setFilterModalOpen] = useState(false)
    const [dataFiltered, setDataFiltered] = useState([])
    const totalPageCount = useMemo(() => Math.ceil(dataFiltered?.length / pageSize), [dataFiltered])

    const {paginationRange, DOTS} = usePagination({
      currentPage : page,
      totalCount : dataFiltered?.length,
      siblingCount : 0,
      pageSize
    }); 

    const [filter, setFilter] = useState(null);

    const onFilter = useCallback((filter=null)  =>{
        let _data = [...data]
      
        if(searchTerm) {
          _data = _data?.filter(item => templates.some(template => item[template.field]?.toString()?.toLowerCase()?.includes(searchTerm?.toLowerCase())))
        }
        if(filter) {
          _data = _data?.filter(item => (!filter.dateDebut || new Date(item.date) >= new Date(filter.dateDebut )  ) && (!filter.dateFin  || new Date(item.date) <= new Date(filter.dateFin)) &&
          (!filter.code || item.code === filter.code) &&
          (!filter.montantReelVerse || item.montant === filter.montantReelVerse) &&
          (!filter.guichet || item.guichet === filter.guichet)
          )
        }
        setDataFiltered(_data);
      } , [data, searchTerm]);

      useEffect(()=>{
        onFilter();
      }, [onFilter])

      const isFieldExist = (field) =>templates?.some(template => template.field === field)

    return (
        <div className='mylonaci-table'>
        <div className="container-fluid no-padding-section" data-pgc="search_filter" >
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ padding: 0 }}>
            <div className="bg-transparent margin-bottom0 container-fluid amen no-padding-section" data-pgc="search-block-amen"
            data-pgc-field="search-block-amen"
            style={{
                paddingLeft: "0px !important",
                backgroundColor: "transparent !important",
                borderRadius: 24
            }}
            >
            <div
                style={{ backgroundColor: "transparent !important", height: 60 }}
                className="searchform"
            >
                <div className="container-fluid full-height" style={{ padding: 0 }}>
                <div className="hero-area-form full-height">
                    <div className="col-md-10 col-lg-10 col-sm-10 col-xs-9 full-height no-padding-section">
                    <input
                        value={searchTerm}
                        autoFocus=""
                        type="text"
                        placeholder="Effectuer une recherche"
                        name="s"
                        pattern=".{3,}"
                        title="3 characters minium"
                        onChange={(event) => setSearchTerm(event.target.value)}
                        style={{
                        boxShadow: "none",
                        fontWeight: "bold",
                        marginRight: "-27px",
                        border: "solid 1px #eee",
                        width: "100%",
                        paddingLeft: 25,
                        }}
                        className="no-shadow form-control ellipsis theme-maincolorback-light thin-border full-height"
                    />
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-2 col-xs-3 full-height no-padding-section">
                     <button
                      type="button"
                      className="btn btn-primary margin-top0 theme-maincolorback no-shadow white full-height main-layout__search-box"
                      id="filterButton"
                      value=""
                      onClick={() =>!!dataFiltered?.length && setFilterModalOpen(true)}
                     >
                      <img src={Filter02Image} /> {/* Remplacez FilterImage par l'image de votre icône de filtrage */}
                      <span className="hidden-xs">Filtrer</span>
                    </button>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
        <div className="container-fluid no-padding-section">
        <div className="container-fluid" style={{padding: '0px'}}>
            <div>
            <div>
                <div className="no-border">
                <div>
                    <table className="table" border={0}>
                    <thead className="hidden">
                        <tr>
                        {templates?.map(template => <th key={template.field}>{template.title ?? ''}</th>)}
                        </tr>
                    </thead>
                    <tbody>
                    {dataFiltered?.length ? 
                        dataFiltered?.slice((page - 1) * pageSize, page * pageSize)?.map((item, key) => <tr key={`tr-${key}`}>
                            {templates?.map((template, key) => <td className={`${template.hiddenXs?'hidden-xs': ''}`} key={`tr-${key}-td-${key}`}>{template.render ? template.render(item) : item[template.field]}</td>)}
                        </tr>)
                        :
                        (<tr>
                          <td colSpan={templates.length}>
                            <NoResults message={noResults} className='myLonaci-ro-results'  />
                        </td>
                        </tr>)}
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>

        {totalPageCount > 1 && <div className="container-fluid text-center no-padding-section">
        <ul className="pagination">
            <li>
                <a className={`mylonaci-table__button${page === 1 ? '-disabled' : ''}`} onClick={() => setPage(_page =>_page - 1)}>«</a>
            </li>

         {paginationRange?.map(pageNumber => {
              // If the pageItem is a DOT, render the DOTS unicode character
              /*if (pageNumber === DOTS) {
                return <li className="pagination-item dots">&#8230;</li>;
              }*/
          
              // Render our Page Pills
              return (
                <li
                key={pageNumber}
                className={`${ pageNumber  === page ? 'active' : ''}`}
                >
                  <a  onClick={() => setPage(pageNumber)} className={`mylonaci-table__button${pageNumber === DOTS ? '-disabled' : ''} ${ pageNumber === page ? 'w' : ''}`}> {pageNumber} {pageNumber === page && <span className="sr-only">(current)</span>}</a>
                </li>
              );
            })}
            <li>
                <a className={`mylonaci-table__button${page === totalPageCount ? '-disabled' : ''}`}  onClick={() => setPage(_page =>_page + 1)} >»</a>
            </li>
        </ul>
        </div>}


        <Modal isOpen={isFilterModalOpen} onClose={() => setFilterModalOpen(false)} >
            <div className="appsv3-admin-bloc animated no-padding-section fadeIn full-height load_flter gc-form-datav3"
                  style={{ backgroundColor: "transparent" }} >
                    <div className="full-height" data-pgc-field="gc-filter-form_v1_content" style={{ overflow: "auto", paddingBottom: 30 }}>
                      <div className="container-fluid">
                          <div className="appsv3-admin-bloc-title transaction_list" style={{backgroundColor: 'transparent !important'}}>
                            <p data-pgc-field="appsv3-admin-bloc-title" className="pull-left therma">Période</p>
                            <div className="btn-group pull-right" style={{height: 'auto', marginBottom: '0px !important'}}>
                              <a href="#" style={{backgroundColor: 'white'}} type="button" className="btn btn-default pull-right no-border">
                                <img src={printImage} className="lazy invert pull-right icon" alt=""  />
                              </a>
                            </div>
                          </div>
                          <div style={{display: 'flex', marginTop: '40px'}}>
                            <div className="col-md-6 col-sm-6 col-xs-6 col-lg-6 no-padding-section">
                              <div className="form-group">
                                <label className="control-label" htmlFor="formInput36" style={{fontSize: '11px !important'}}>
                                  Date de début
                                </label>
                                  <input style={{borderRadius: '15px'}} type="date" className="form-control" id="formInput36"
                                    placeholder="10/12/34"
                                    value={filter?.dateDebut} onChange={(event)=>setFilter((filter)=>({...filter, dateDebut: event.target.value}))}
                                />
                               </div>
                               </div>
                               <div className="col-md-6 col-sm-6 col-xs-6 col-lg-6" style={{ marginRight: 0, paddingRight: 1 }}>
                                <div className="form-group">
                                  <label className="control-label" htmlFor="formInput36">
                                    Date de fin&nbsp;
                                  </label>
                                  <input style={{borderRadius: '15px'}} type="date" className="form-control"
                                    id="formInput36"
                                    placeholder="12/10/28"
                                    value={filter?.dateFin} onChange={(event)=>setFilter((filter)=>({...filter, dateFin: event.target.value}))}
                                  />
                                  </div>
                                  </div>
                          </div>
                                  </div>
                                      {isFieldExist("code") && <div className="container-fluid">
                                        <div className="container-fluid no-padding-section">
                                          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding-section">
                                            <div className="form-group">
                                              <label
                                                className="control-label"
                                                htmlFor="formInput36"
                                              >
                                                Code opération
                                              </label>
                                              <input 
                                              style={{borderRadius: '15px'}} 
                                              placeholder="Entrez le code opération"  
                                              className="form-control" 
                                              type="text"
                                              value={filter?.code} onChange={(event)=>setFilter((filter)=>({...filter, code: event.target.value}))}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div> }

                                      {isFieldExist("montantReelVerse") && <div className="container-fluid">
                                        <div className="container-fluid no-padding-section">
                                          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding-section">
                                            <div className="form-group">
                                              <label
                                                className="control-label"
                                                htmlFor="formInput36"
                                              >
                                                Montant
                                              </label>
                                              <input
                                              type="text" 
                                              style={{borderRadius: '15px'}} 
                                              placeholder="Entrez le montant"  
                                              className="form-control" 
                                              value={filter?.montantReelVerse} onChange={(event)=>setFilter((filter)=>({...filter, montant: event.target.value}))}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div> }

                                      {isFieldExist("guichet") &&  <div className="container-fluid">
                                        <div className="container-fluid no-padding-section">
                                          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding-section">
                                            <div className="form-group">
                                              <label
                                                className="control-label"
                                                htmlFor="formInput36"
                                              >
                                                Guichet
                                              </label>
                                              <input
                                              type="text" 
                                              style={{borderRadius: '15px'}} 
                                              placeholder="Entrez le guichet"  
                                              className="form-control" 
                                              value={filter?.guichet} onChange={(event)=>setFilter((filter)=>({...filter, guichet: event.target.value}))}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div> }
                                      <div
                                        className="container-fluid pad15 pad-bottom15 sub-section"
                                        style={{ marginTop: 15 }}
                                         >
                                          <button type="button"
                                                className="btn btn-default btn-lg form-control btn-primary"
                                                onClick={()=>{onFilter(filter);setFilterModalOpen(false)}}
                                          >
                                                Appliquer mes filtres
                                          </button>
                                          <button
                                                type="button"
                                                className="btn btn-default btn-lg form-control"
                                                style={{ width: "100%" }}
                                                onClick={()=>setFilterModalOpen(false)}
                                          >
                                                Fermer
                                          </button>
                                          <button type="button"
                                                className="btn btn-default btn-lg form-control btn-primary"
                                                onClick={()=> {
                                                  const dataToExport = dataFiltered.map(({recu, guichet, montantReelVerse, ...others}) => others)
                                                  ExcelHelper.export(dataToExport, "donnees-exportees")
                                                }}
                                          >
                                                Exporter XLS/PDF
                                          </button>                      
                                    </div>
                                    </div>
            </div>
                  
        </Modal>
        </div>

        
    )
}

export default Table