
import { useContext, useEffect, useState, useMemo } from 'react';

import {Table, Modal, Breadcrumbs} from '../../components'
import Vue02Image from  '../../layout/themes/images/icon/vue-02-02.png';
import { LoadableContent } from '../../components';
import { AppContext } from '../../common/constants';
import { CommissionsService } from '../../common/services';


import printImage from '../../layout/themes/images/icon/print-03.png'
import { NumberHelper, DateHelper } from '../../common/helper';

function Commissions () {

  const {store} = useContext(AppContext)

  const [commissions, setCommissions] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isDetailsModalOpen, setDetailsModalOpen] = useState(false)
  const [selectedCommission, setSelectedCommission] = useState({});

  const date = useMemo(() => DateHelper.getMonthFirstAndLastDate(`${store.month}-01`), [store.month])

  useEffect(() => {
     
      if(store?.concessionnaire?.id) { 
        setLoading(true)
        CommissionsService.get({debut : date.firstDay?.toISOString(), fin : date.lastDay?.toISOString()}, store.concessionnaire.id)
        .then(setCommissions)
        .finally(() => setLoading(false))
      }
    }, [store?.concessionnaire?.id, date])
    
    const dynamicText = "Commission";
    return (
  <LoadableContent {...{isLoading}}>
    <Breadcrumbs secondLinkText={dynamicText} />
  <div
  className="container-fluid theme-hover-bg-zoom-in animatedParent full-height sub-section"
  id="particuliers"
  style={{ backgroundSize: "contain", backgroundColor: "#fff" }}
  data-pgc="commissions2-list"
>
  <div className="appsv3-admin-bloc animated fadeIn col-xs-12 col-sm-12 col-md-12 col-lg-12 sub-section bg-white transactions_list">
    <div className="appsv3-admin-bloc-title">
      <p data-pgc-field="appsv3-admin-bloc-title"> {`Commissions pour ${store?.concessionnaire?.code}`} </p>
    </div>
    <div className="container-fluid no-padding-section">
      <p data-pgc-field="appsv3-admin-bloc-title"
        className="pull-left description">
        Consulter l'historique des commissions du concessionnaire sélectionné
      </p>
    </div>

    <Table 
      templates={
        [
          {title : 'Date', field : 'date', render : (item) =>   <div className="full-height theme-vertical-align">
          <div className="btn btn-default btn-data-highlited">
             {new Date(item.date).toLocaleString("fr-FR", { year: 'numeric', month: 'long'})}
          </div>
        </div>
        },
        {title : 'Vendeur', field : 'commission', render : (item) =>    <div
        className="product-name-desc pull-left text-left no-padding-section col-lg-12 col-md-12 col-sm-12 col-xs-12 full-height"
        style={{ paddingLeft: "0PX" }}
      >
        <div className="full-height theme-vertical-align">
          <div>
            <p className="bold margin-bottom0 margin-top0 label">
              Commissions
            </p>
            <p className="bold margin-bottom0 margin-top0 data">
              {NumberHelper.formatWithThousandsSeparator(item.commission)} FCFA
            </p>
            </div>
        </div>
      </div>
        },
        {title : 'Produit', field : 'produit', render : (item) =>  
        <div
        className="product-name-desc pull-left text-left no-padding-section col-lg-12 col-md-12 col-sm-12 col-xs-12 full-height theme-vertical-align"
        style={{ paddingLeft: "0PX" }}
      >
        <div
          className="product-name-desc pull-left text-left no-padding-section col-lg-12 col-md-12 col-sm-12 col-xs-12"
          style={{ paddingLeft: "0PX" }}
        >
          <p className="bold margin-bottom0 margin-top0 label">
            Produit
          </p>
          <p className="bold margin-bottom0 margin-top0 data">
            {item?.produit?.libelle}
          </p>
        </div>
      </div>},
        {title : '', field : 'id', render : (item) =>   <div className="product-name-desc pull-left text-left no-padding-section col-lg-12 col-md-12 col-sm-12 col-xs-12 full-height theme-vertical-align" style={{paddingLeft: '0px', textAlign: 'center'}}>
        <div style={{textAlign: 'center', display: 'inline-block'}}>
            <div className="btn-group full-height bothed">
            <button onClick={() => {
                      setSelectedCommission(item)
                      setDetailsModalOpen(true)
                    }} type="button" className="btn btn-default">
                <img src={Vue02Image} className="lazy invert" alt="" />
            </button>
            </div>
        </div>
        </div>}
        ]
        } 
      data={commissions} />
  </div>
</div>
<Modal isOpen={isDetailsModalOpen} onClose={() => setDetailsModalOpen(false)} >
            <div className="appsv3-admin-bloc animated fadeIn no-padding-section container-fluid bg-transparent" style={{backgroundColor: 'transparent !important'}}>
              <div className="appsv3-admin-bloc-title transactions_list" style={{backgroundColor: 'transparent !important'}}>
                <p data-pgc-field="appsv3-admin-bloc-title" className="pull-left therma">Details de la commission</p>
                <div className="btn-group pull-right" style={{height: 'auto', marginBottom: '0px !important'}}>
                  <button style={{backgroundColor: 'white'}} type="button" className="btn btn-default pull-right no-border">
                    <img src={printImage} className="lazy invert pull-right icon" alt=""  />
                  </button>
                </div>
              </div>
              <div className="no-border container-fluid transactions_list">
                <div className="row apps-line-data">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <div className="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                      <p>Mois</p>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6 col-sm-6 col-xs-6">
                  <p className="details">{new Date(selectedCommission.date).toLocaleString("fr-FR", {month: 'long' })}</p>
                  </div>
                </div>
                
                <div className="row apps-line-data">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <div className="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                      <p>Chiffre affaire</p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <p className="details">
                      {NumberHelper.formatWithThousandsSeparator(selectedCommission?.chiffreAffaire ?? '')} 
                    </p>
                  </div>
                </div>

                <div className="row apps-line-data">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <div className="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                      <p>Montant</p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <p className="details">
                      {NumberHelper.formatWithThousandsSeparator(selectedCommission?.commission ?? '')} 
                    </p>
                  </div>
                </div>
              
                <div className="row apps-line-data">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <div className="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                      <p>Nombre terminaux</p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <p className="details">
                      {NumberHelper.formatWithThousandsSeparator(selectedCommission?.nbreTerminaux ?? '')} 
                    </p>
                  </div>
                </div>
              </div>
              <div className="text-center section" data-pgc="nostyle_button" data-pgc-field="nostyle_buttonr">
                <a onClick={() => setDetailsModalOpen(false)} className="btn white thin-border btn-default" >Fermer</a>
              </div>
            </div>
            </Modal>
</LoadableContent>)
}

export default Commissions;