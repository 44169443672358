import { Form, Input } from 'antd';
import React from 'react';

const OtpField=({label, name, type, required=false, requireMsg, disable=false, size='large', length, onChange})=> {

    return (
        <React.Fragment>
                <Form.Item
                    style={{width: "100%"}}
                    label={label ?? ''} 
                    name={name}
                    rules={[{ required: required, type: type, message: requireMsg ?? "Champ obligatoire" }]}
                >
                    <Input.OTP 
                        formatter={(str) => str.toUpperCase()} 
                        disabled={disable} 
                        size={size} 
                        length={length}
                        onChange={onChange}
                        />
                    
                </Form.Item>
        </React.Fragment>
    )
}

export default OtpField;