import React from 'react';
import './fil-dariane.component .scss';
import { Link } from 'react-router-dom';

const Breadcrumbs = ({ secondLinkText }) => {
    return (
      <div className="breadcrumb">
        <ul>
          <li>
            <Link style={{fontSize: '12px'}} to="/dashboard">Accueil /</Link>
        </li>
          <li>
            <a style={{fontSize: '12px'}} href="#">{secondLinkText}</a>
          </li>
        </ul>
      </div>
    );
}
  
export default Breadcrumbs;
