import money02 from '../../layout/themes/images/icon/money-02.png'
import { Link } from 'react-router-dom';

function Operation () {

  return(
    <>
      <div className="container-fluid thedashy animated fadeInUp sub-section theme-maincolorback-light menu-module">
        <div className="col-md-3 col-lg-3 col-sm-4 col-xs-6 sub-section">
          <div className="bloc description" style={{ height: "auto" }}>
            <img
              src={money02}
              className="img-responsive icon invert"
              data-pgc-field="dashyimg"
            />
            <h3 data-pgc-field="theme">Rechargement</h3>
            <p className='description_1'>
              Recharger votre compte partenaire
            </p>
            <Link className="btn" to="/dashboard/rechargements" target="_self">
              RECHARGER
            </Link>
          </div>
        </div>
      </div>

    </>

  )
 
}


export default Operation;