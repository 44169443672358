import {createContext} from 'react';

//export const API_BASE_URL = 'http://192.168.1.9:8081';
export const API_BASE_URL = 'https://api.mylonaci.com';
//export const API_BASE_URL = 'https://preprod-api.mylonaci.com';

export const APP_NAME = "FRONT_APP";

export const LOCAL_STORAGE_KEYS = {
   ACCESS_TOKEN : 'accessToken',
   CURRENT_USER : 'currentUser',
   DEVICE_MODE : 'deviceMode'
}

export const ACCESS_TOKEN = 'accessToken';

export const CURRENT_USER = 'currentUser';

export const AuthContext = createContext('AUTH_CONTEXT');

export const AppContext = createContext('APP_CONTEXT');





