import { Outlet, Link } from 'react-router-dom';

import { LOCAL_STORAGE_KEYS } from '../../common/constants'; 
import { Layout } from '..';


import Header from './header/Header'


import IconLeftImage  from '../../layout/themes/images/icon/left-03-02.png';
import IconRightImage from  '../../layout/themes/images/icon/righted-02.png';
import Home02Image from  '../../layout/themes/images/icon/home-02.png';
import Dashboard01Image from  '../../layout/themes/images/icon/dashboard-01.png';
import Money03Image from  '../../layout/themes/images/icon/money-03.png';
import Vente02Image from  '../../layout/themes/images/icon/vente-02.png';
import RechargementTotal02Image from  '../../layout/themes/images/icon/rechargement-total-02.png';
import Annulation02Image from  '../../layout/themes/images/icon/annulations-02.png';
import Phone03Image from  '../../layout/themes/images/icon/phone-03.png';
import Password03Image from  '../../layout/themes/images/icon/password-03.png';
import MobileMenu from './mobile-menu.page';


function  MainLayout () {
  

return (

<Layout>
<div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
<div className="row min-height-600px windows full-height" style={{height: '100%'}}>
    <div className="windows-left-appv2 windows-left-appv2-open windows-left-opacity full-height hidden-xs hidden-sm bg-offwhite transition-soft col-lg-2 col-md-2 col-sm-2 col-xs-2 shadow-6 shadow-3 main-layout__sidebar">
      <div className="left-content-aapvs full-height" style={{overflow: 'auto'}}>
        <div className="full-height no-padding-section col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div className="container-fluid no-padding-section bg-transparent" data-pgc-define="mainmenu" data-pgc-define-name="mainmenu" data-pgc-edit="mainmenu_content">
            <div className="appsv3-admin-bloc animated fadeIn col-xs-12 col-sm-12 col-md-12 col-lg-12 sub-section" data-pgc="appsv3-admin-bloc" >
              <div className="container-fluid appsv3-admin-bloc-title">
                <p data-pgc-field="appsv3-admin-bloc-title">Mylonaci</p> 
              </div>
              <div className="container-fluid gc-optionslider-link thin-border-top" style={{padding: '0px'}}>
                <nav>
                  <ul> 
                    <Link target="_self" to={localStorage.getItem(LOCAL_STORAGE_KEYS.DEVICE_MODE) === "MOBILE" ? "/dashboard" : "/"}>
                      <li  className="optionpage1_nav">
                        <img src={Home02Image} className="invert" />
                        <div className='optionpage1_nav_title'>Accueil</div> 
                      </li>
                    </Link>                                                 
                  </ul>
                </nav>
              </div>
            </div>
            <div className="appsv3-admin-bloc animated fadeIn col-xs-12 col-sm-12 col-md-12 col-lg-12 sub-section" data-pgc="appsv3-admin-bloc" >
              <div className="container-fluid appsv3-admin-bloc-title">
                <Link to="/dashboard/consultations" target="_self"><p data-pgc-field="appsv3-admin-bloc-title">Consultation</p></Link> 
              </div>
              <div className="container-fluid gc-optionslider-link thin-border-top" style={{padding: '0px'}}>
                <nav>
                  <ul> 
                    <Link target="_self" to="">
                      <li  className="optionpage1_nav">
                        <img src={Dashboard01Image} className="invert" />
                        <div className='optionpage1_nav_title'>Terminaux</div> 
                      </li>
                    </Link>                                                 
                    <Link to="operations" target="_self">
                      <li className="optionpage1_nav">
                        <img src={Money03Image} className="invert" />
                        <div className='optionpage1_nav_title'>Rechargements</div> 
                      </li>
                    </Link>
                    <Link to="chiffres-affaires" target="_self">
                      <li className="optionpage1_nav">
                        <img src={Vente02Image} className="invert" />
                        <div className='optionpage1_nav_title'>Chiffre d'affaire</div>
                        
                      </li>
                    </Link>
                    <Link to="commissions" target="_self">
                      <li className="optionpage1_nav">
                        <img src={RechargementTotal02Image} className="invert" />
                        <div className='optionpage1_nav_title'>Commissions</div> 
                      </li>
                    </Link>
                    {/* <Link to="commissions" target="_self">
                      <li className="optionpage1_nav">
                        <img src={RechargementTotal02Image} className="invert" />
                        <div className='optionpage1_nav_title'>Paiements</div> 
                      </li>
                    </Link> */}
                  </ul>
                </nav>
              </div>
            </div>
            <div className="appsv3-admin-bloc animated fadeIn col-xs-12 col-sm-12 col-md-12 col-lg-12 sub-section" data-pgc="appsv3-admin-bloc" >
              <div className="container-fluid appsv3-admin-bloc-title">
                <Link to="/dashboard/consultations-operation" target="_self"><p data-pgc-field="appsv3-admin-bloc-title">Opérations</p></Link> 
              </div>
              <div className="container-fluid gc-optionslider-link thin-border-top" style={{padding: '0px'}}>
                <nav>
                  <ul> 
                    <Link target="_self" to="rechargements">
                      <li  className="optionpage1_nav">
                        <img src={Annulation02Image} className="invert" />
                        <div className='optionpage1_nav_title'>Mobile money (MOMO)</div> 
                      </li>
                    </Link>                                                 
                  </ul>
                </nav>
              </div>
            </div>
            <div className="appsv3-admin-bloc animated fadeIn col-xs-12 col-sm-12 col-md-12 col-lg-12 sub-section" data-pgc="appsv3-admin-bloc" >
              <div className="container-fluid appsv3-admin-bloc-title">
                <a href="#" target="_self">
                  <p data-pgc-field="appsv3-admin-bloc-title">
                    Paramètres
                  </p>
                </a> 
              </div>
              <div className="container-fluid gc-optionslider-link thin-border-top" style={{padding: '0px'}}>
                <nav>
                  <ul> 
                    <a target="_self" href="/profil">
                      <li  className="optionpage1_nav">
                        <img src={Phone03Image} className="invert" />
                        <div className='optionpage1_nav_title'>Profil</div> 
                      </li>
                    </a>
                    <a target="_self" href="/dashboard/codes-distributeurs">
                      <li  className="optionpage1_nav">
                        <img src={Phone03Image} className="invert" />
                        <div className='optionpage1_nav_title'>Mes codes distributeurs</div> 
                      </li>
                    </a>
                    <a href="#" target="_self" /> 
                    <a href="#" target="_self">
                      <li className="optionpage1_nav">
                        <img src={Password03Image} className="invert" />
                        <div className='optionpage1_nav_title'> Faq</div> 
                      </li>
                    </a>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div className="no-padding-section full-height col-lg-1 col-md-1 col-sm-1 col-xs-1 thin-border-right" style={{overflow: 'clip', position: 'absolute', zIndex: 900, right: '0px'}}>
          <a className="btn margin-top0 no-border shadow-6 panel_slide_close scalable" href="#form_modal1" data-toggle="modal" style={{position: 'absolute', top: '45%', zIndex: 90000, width: '50px', height: '50px', borderRadius: '50pximportant', backgroundColor: '#f1f1f1', left: '-45%', padding: '0px', textAlign: 'center', lineHeight: '50px'}}>
            <img src={IconLeftImage} style={{marginRight: '0px', width: '100%'}} className="text-center invert" />
          </a>
          <a className="btn margin-top0 no-border shadow-6 panel_slide_expend hidden theme-maincolorback" href="#form_modal1" data-toggle="modal" style={{position: 'absolute', top: '45%', zIndex: 90000, width: '50px', height: '50px', borderRadius: '50pximportant', backgroundColor: '#ffffff', left: '-175%', padding: '0px', textAlign: 'center', lineHeight: '50px'}}>
            <img src={IconRightImage} style={{width: '100%'}} />
          </a>
        </div>
      </div>                     
    </div>
    <div className="content-appv2 full-height visible-md visible-lg visible-sm visible-xs col-xs-12 col-sm-12 no-padding-section dashboard preview_icon transition-soft bg-transparent col-lg-10 col-md-10 theme-font-Ubuntu theme-maincolorback-light" style={{overflowX: 'hidden', paddingBottom: '30px'}} data-pgc-edit="new_dash">
      <div className="thefillcontent shadow-6">
        <Header />
       <Outlet />
      </div>                  
    </div>
  </div> 
  
</div>

<MobileMenu />
<footer id="footer-bottom" style={{   
    bottom: '0',
    height: '30px',
    width: '100%',
    backgroundColor: 'black',
    zIndex: '999', 
    color : 'white',
    // marginLeft: '180px'
    }}><center style={{marginTop : '15px'}}>@Copyright LONACI 2023</center></footer>
</Layout>
    )
}

export default MainLayout;