import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import './my-lonaci-tabs.component.scss' 


const MyLonaciTabs = ({items}) => (
    <Tabs className="mylonaci-tabs">
        <TabList>
            {items?.map((item, key) => <Tab key={`tabs-keys-${key}`}>{item.title}</Tab>)}
        </TabList>
        {items?.map((item, key) => <TabPanel key={`tabs-panel-${key}`}>{item.element}</TabPanel>)}
    </Tabs>
)

export default MyLonaciTabs;