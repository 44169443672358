
import { Link } from 'react-router-dom';

import { LOCAL_STORAGE_KEYS } from '../../common/constants'; 
import { UseAuthentification } from '../../common/hooks';

import './layout.page.scss'

import Logo from '../../layout/themes/images/my_lonaci_logo.png';
import ProfileImage  from '../../layout/themes/images/hero/work.jpeg';

function  Layout ({children}) {   
    const auth  = UseAuthentification();  
    return (
     <main className="main main-layout">
       <nav className="navbar navbar-default appsv3-top-menu navbar-inverse navbar-fixed-top theme-maincolorback-dark dash_topbar animated animated-very-fast" role="navigation" style={{margin: '0px'}}> 
        <div className="container-fluid"> 
    
        <div className="navbar-header">
          <Link className="navbar-brand appv3-appname main-layout__appname" to="/dashboard" style={{marginLeft: '10px', verticalAlign: 'middle', color: '#ffffff !important', paddingLeft: '1px', paddingTop: '7px'}} >
            <img src={Logo} style={{width: '100px'}} />
          </Link>
         <Link className="navbar-brand appv3-appname main-layout__appname" to="" style={{marginLeft: '10px', verticalAlign: 'middle', color: '#ffffff !important', paddingLeft: '1px', paddingTop: '7px', width: '100px'}} >
             {/*<img src={Logo} style={{width: '100px'}} />*/}
          </Link>
        </div>
    
          <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1" style={{padding: '0px', paddingRight: '20px'}}> 
            <ul className="nav navbar-nav">
              <li className="dropdown appv3-left-popup hidden-md hidden-lg"  data-animation-pro-auto-update-1="true" data-animation-pro-trigger-1 data-animation-pro-target-1 data-animation-pro-scroll-direction-1="down" data-animation-pro-offset-1="250px" data-animation-pro-init-state-1 data-pgc="appsv3-popupleft"> 
                <div className="modal-container" data-pg-name="From right to left" data-pgc-define data-pgc-define-auto-update="false">
                  <button type="button" className="btn appv3-topmenu-popup-btn" data-animation-pro-1 style={{marginTop: '0px'}} data-animation-pro-trigger-1="click" data-animation-pro-target-1="| .modal-bg" data-animation-pro-add-classes-1="open" data-animation-pro-sd-click-add-classes-1="open" data-animation-pro-2 data-animation-pro-trigger-2="click" data-animation-pro-target-2="| .ap-modal" data-animation-pro-add-classes-2="open animate" data-animation-pro-sd-click-add-classes-2="open animate" data-animation-pro-duration-1={0} data-animation-pro-duration-2={0} data-pgc-define data-pgc-define-auto-update="false" data-animation-pro-3> 
                    <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                      <img src="images/icon/hammer-02.png" /> 
                    </a>                                         
                  </button>
                  <div className="modal-bg"  />
                  <div className="ap-modal 3D-sign"  data-animation-pro-1 data-animation-pro-trigger-1="class" data-animation-pro-class-name-1="animate" data-animation-pro-target-1="> .modal-content" data-animation-pro-init-state-1="opacity:0;transform:translateX(-100%);" data-animation-pro-class-removed-step-1-1="opacity:0;transform:translateX(100%);" data-animation-pro-2 data-animation-pro-trigger-2="class" data-animation-pro-class-name-2="animate" data-animation-pro-class-removed-rm-classes-2="open" data-animation-pro-delay-2={500} data-animation-pro-easing-1="ease-out-quad" data-animation-pro-step-1-1="opacity:1;transform:translateX(0);" data-animation-pro-duration-1={300} data-animation-pro-class-removed-step-2-1="transform:translateX(-100%);" data-pgc-field="modal-lg">
                    <div className="modal-content" style={{opacity: 0, transform: 'translateX(-100%)', paddingRight: '0px', height: '100%'}}>
                      <div className="thin-border" data-pgc-field="modal-title">
                        <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9" style={{verticalAlign: 'middle', display: 'inline-block'}}> 
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3" style={{margin: '0px', padding: '0px'}}> 
                          <button type="button" data-animation-pro-1 style={{border: 'none', margin: '0px', width: '100%', padding: '0px', position: 'absolute', zIndex: 1000, backgroundColor: 'transparent !important'}} data-animation-pro-trigger-1="click" data-animation-pro-target-1="^ .ap-modal | .modal-bg" data-animation-pro-rm-classes-1="open" data-animation-pro-sd-click-rm-classes-1="open" data-animation-pro-2 data-animation-pro-trigger-2="click" data-animation-pro-target-2="^ .ap-modal" data-animation-pro-rm-classes-2="animate" data-animation-pro-sd-click-rm-classes-2="animate" data-pgc-field="btn-close-title">
                            <img src="images/icon/map-02.png" className="invert" />
                          </button>                                                     
                        </div>                                                 
                      </div>
                      <div className="appsv3-tabpanes" data-pgc="appsv3-admin-tabpanes">
                        <ul className="nav nav-tabs"> 
                          <li className="active">
                            <a href="#tab1" data-toggle="tab">Menu principal</a>
                          </li>                                                     
                          <li>
                            <a href="#tab2" data-toggle="tab">Recherche</a>
                          </li>                                                     
                        </ul>
                        <div className="tab-content appsv3-tabpanes responsive-menu">
                          <div className="tab-pane active thin-border" id="tab1">
                            <div className="col-lg-11 col-md-11 col-sm-11 col-xs-11 full-height no-padding-section">
                              <div className="appsv3-admin-bloc thin-border animated fadeIn col-xs-12 col-sm-12 col-md-12 col-lg-12 sub-section" data-pgc="appsv3-admin-bloc" >
                                <div className="container-fluid gc-optionslider-link thin-border-top" style={{padding: '0px'}}>
                                  <nav>
                                    <ul> 
                                      <a target="_self">
                                        <li>
                                          <img src="images/icon/dashboard-02.png" className="invert" />Tableau de bord
                                        </li>
                                      </a>
                                      <a href="new_map.html" target="_self">
                                        <li>
                                          <img src="images/icon/map-02.png" className="invert" />Cartes
                                        </li>
                                      </a>                                                                             
                                      <li className="optionpage1_nav">
                                        <img src="images/icon/map-02.png" className="invert" />
                                        Statistiques
                                      </li>
                                    </ul>
                                  </nav>
                                </div>
                              </div>
                              <div className="appsv3-admin-bloc thin-border animated fadeIn col-xs-12 col-sm-12 col-md-12 col-lg-12 sub-section" data-pgc="appsv3-admin-bloc" >
                                <div className="container-fluid gc-optionslider-link thin-border-top" style={{padding: '0px'}}>
                                  <nav>
                                    <ul> 
                                      <a>
                                        <li>
                                          <img src="images/icon/map-02.png" className="invert" />Carte satelitte
                                        </li>
                                      </a>
                                      <a href="about.html">
                                        <li>
                                          <img src="images/icon/map-02.png" className="invert" />Carte routiere 
                                        </li>
                                      </a>                                                                             
                                      <li className="optionpage1_nav">
                                        <span className="glyphicon glyphicon-list-alt" />
                                        Occupation sol
                                      </li>
                                    </ul>
                                  </nav>
                                </div>
                              </div>
                              <div className="appsv3-admin-bloc thin-border animated fadeIn col-xs-12 col-sm-12 col-md-12 col-lg-12 sub-section" data-pgc="appsv3-admin-bloc" >
                                <div className="container-fluid gc-optionslider-link thin-border-top" style={{padding: '0px'}}>
                                  <nav>
                                    <ul> 
                                      <a href="new_map.html" target="_self">
                                        <li>
                                          <img src="images/icon/map-02.png" className="invert" />Liste des Usines
                                        </li>
                                      </a>
                                      <a href="about.html">
                                        <li>
                                          <img src="images/icon/map-02.png" className="invert" />Enregistrer une usine
                                        </li>
                                      </a>                                                                             
                                    </ul>
                                  </nav>
                                </div>
                              </div>
                              <div className="appsv3-admin-bloc thin-border animated fadeIn col-xs-12 col-sm-12 col-md-12 col-lg-12 sub-section" data-pgc="appsv3-admin-bloc" >
                                <div className="container-fluid gc-optionslider-link thin-border-top" style={{padding: '0px'}}>
                                  <nav>
                                    <ul> 
                                      <a>
                                        <li>
                                          <img src="images/icon/map-02.png" className="invert" />Liste des régions 
                                        </li>
                                      </a>
                                      <a href="about.html">
                                        <li>
                                          <img src="images/icon/map-02.png" className="invert" />A Définir une région
                                        </li>
                                      </a>                                                                             
                                    </ul>
                                  </nav>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="tab-pane" id="tab2">
                            <div className="form-group">
                              <div className="appsv3-search">
                                <input type="text" className="form-control" id="formInput20" placeholder="Recherche" style={{paddingLeft: '60px'}} />
                                <button type="button" className="btn">
                                  <span className="glyphicon glyphicon-search" />
                                </button>
                              </div>
                              <div className="appsv3-search-result">
                                <p>ragraphLorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus pulvinar faucibus neque, nec rhoncus nunc ultrices sit amet. Curabitur ac sagittis neque, vel egestas est. Aenean elementum, erat at aliquet hendrerit, elit nisl posuere tortor, id suscipit diam dui sed nisi</p>
                              </div>                                                             
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>                                 
                <ul className="dropdown-menu"> 
                  <li>
                    <a href="#">Saisine</a>
                  </li>
                  <li>
                    <a href="#">Gestion des procédures pénales</a>
                  </li>                                     
                  <li>
                    <a href="#">Citation directe par exploit d'huissier</a>
                  </li>                                     
                  <li>
                    <a href="#">Plainte avec constitution de parties civiles</a>
                  </li>                                     
                  <li>
                    <a href="#">Plaintes et PV</a>
                  </li>
                  <li className="divider" />
                  <li>
                    <a href="#">Requêtes</a>
                  </li>
                  <li>
                    <a href="#">Saisie d' une requête</a>
                  </li>
                  <li className="divider" />
                  <li>
                    <a href="#">Gestion des détenus</a>
                  </li>                                     
                  <li>
                    <a href="#">Saisie de détenus</a>
                  </li>
                  <li>
                    <a href="#">Saisie de détenus</a>
                  </li>
                  <li>
                    <a href="#">Saisie de détenus</a>
                  </li>
                  <li>
                    <a href="#">Saisie de détenus</a>
                  </li>
                  <li>
                    <a href="#">Saisie de détenus</a>
                  </li>                                     
                  <li className="divider" />
                  <li data-animation-pro-1  data-animation-pro-auto-update-1="true" data-animation-pro-trigger-1="click" data-animation-pro-target-1=".appsv3-left-close" data-animation-pro-add-classes-1="appsv3-left  col-lg-2" data-animation-pro-rm-classes-1="col-lg-1 appsv3-left-close" data-animation-pro-easing-1="ease-in" data-animation-pro-2 data-animation-pro-auto-update-2="true" data-animation-pro-trigger-2="click" data-animation-pro-target-2=".appsv3-right" data-animation-pro-easing-2="ease-in" data-animation-pro-add-classes-2="col-lg-10" data-animation-pro-rm-classes-2="col-lg-12">
                    <a href="#">Afficher le menu</a>
                  </li>                                     
                </ul>                                 
              </li>
              <li className="dropdown appv3-left-popup hidden-lg hidden-md" data-animation-pro-1  data-animation-pro-auto-update-1="true" data-animation-pro-trigger-1 data-animation-pro-target-1 data-animation-pro-scroll-direction-1="down" data-animation-pro-offset-1="250px" data-animation-pro-init-state-1 data-pgc="appsv3-popupleft"> 
                <div className="modal-container" data-pg-name="From right to left" data-pgc-define data-pgc-define-auto-update="false">
                  <button type="button" className="btn appv3-topmenu-popup-btn" data-animation-pro-1 style={{marginTop: '0px'}} data-animation-pro-trigger-1="click" data-animation-pro-target-1="| .modal-bg" data-animation-pro-add-classes-1="open" data-animation-pro-sd-click-add-classes-1="open" data-animation-pro-2 data-animation-pro-trigger-2="click" data-animation-pro-target-2="| .ap-modal" data-animation-pro-add-classes-2="open animate" data-animation-pro-sd-click-add-classes-2="open animate" data-animation-pro-duration-1={0} data-animation-pro-duration-2={0} data-pgc-define data-pgc-define-auto-update="false" data-animation-pro-3> 
                    <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                      <img src="images/icon/hammer-02.png" /> 
                    </a>                                         
                  </button>
                  <div className="modal-bg"  />
                  <div className="ap-modal 3D-sign"  data-animation-pro-1 data-animation-pro-trigger-1="class" data-animation-pro-class-name-1="animate" data-animation-pro-target-1="> .modal-content" data-animation-pro-init-state-1="opacity:0;transform:translateX(-100%);" data-animation-pro-class-removed-step-1-1="opacity:0;transform:translateX(100%);" data-animation-pro-2 data-animation-pro-trigger-2="class" data-animation-pro-class-name-2="animate" data-animation-pro-class-removed-rm-classes-2="open" data-animation-pro-delay-2={500} data-animation-pro-easing-1="ease-out-quad" data-animation-pro-step-1-1="opacity:1;transform:translateX(0);" data-animation-pro-duration-1={300} data-animation-pro-class-removed-step-2-1="transform:translateX(-100%);" data-pgc-field="modal-lg">
                    <div className="modal-content" style={{opacity: 0, transform: 'translateX(-100%)', paddingRight: '0px', height: '100%'}}>
                      <div className="thin-border" data-pgc-field="modal-title">
                        <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9" style={{verticalAlign: 'middle', display: 'inline-block'}}> 
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3" style={{margin: '0px', padding: '0px'}}> 
                          <button type="button" data-animation-pro-1 style={{border: 'none', margin: '0px', width: '100%', padding: '0px', position: 'absolute', zIndex: 1000, backgroundColor: 'transparent !important'}} data-animation-pro-trigger-1="click" data-animation-pro-target-1="^ .ap-modal | .modal-bg" data-animation-pro-rm-classes-1="open" data-animation-pro-sd-click-rm-classes-1="open" data-animation-pro-2 data-animation-pro-trigger-2="click" data-animation-pro-target-2="^ .ap-modal" data-animation-pro-rm-classes-2="animate" data-animation-pro-sd-click-rm-classes-2="animate" data-pgc-field="btn-close-title">
                            <img src="images/icon/map-02.png" className="invert" />
                          </button>                                                     
                        </div>                                                 
                      </div>
                      <div className="appsv3-tabpanes" data-pgc="appsv3-admin-tabpanes">
                        <div className="container-fluid profileslide no-padding-section shadow-6" data-pgc-define="profileslide" data-pgc-define-name="profile-slide" data-pgc-define-auto-update="false" data-pgc-edit="profileslide[class, style]" data-pgc-edit-bckimage data-pgc-section="profiles">
                          <div className="container-fluid sub-section thin-border-bottom">
                            <h1 className="theme-text-medium theme-text-bold">Mon compte</h1>
                          </div>
                          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 sub-section">
                            <div className="profileimg grayscale">
                              <img src="components/apps/images/1619287_829025000448211_764867811_n-2.jpg" className="img-responsive img-rounded" width="100%" />
                            </div>                                                         
                          </div>
                          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center theme-smallblock-space" style={{padding: '0px'}}>
                            <h2 className="text-center theme-text-bold">Ahmed Ben Adams BAMBA</h2>
                            <h3 className="text-center">@ivorycoast Best-designer</h3> 
                            <p><span>Connecté</span> <span>Heure de connexion:12h30</span></p>
                            <div className="bg-transparent sub-section" data-pgc="gcs-filter-link">
                              <ul> 
                                <a href>
                                  <li>A propos de moi</li>
                                </a>                                                                 
                                <a href>
                                  <li>Afficher le compte</li>
                                </a>                                                                 
                                <a href>
                                  <li>Se deconnecter</li>
                                </a>                                                                 
                              </ul>
                            </div>                                                         
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>                                 
                <ul className="dropdown-menu"> 
                  <li>
                    <a href="#">Saisine</a>
                  </li>
                  <li>
                    <a href="#">Gestion des procédures pénales</a>
                  </li>                                     
                  <li>
                    <a href="#">Citation directe par exploit d'huissier</a>
                  </li>                                     
                  <li>
                    <a href="#">Plainte avec constitution de parties civiles</a>
                  </li>                                     
                  <li>
                    <a href="#">Plaintes et PV</a>
                  </li>
                  <li className="divider" />
                  <li>
                    <a href="#">Requêtes</a>
                  </li>
                  <li>
                    <a href="#">Saisie d' une requête</a>
                  </li>
                  <li className="divider" />
                  <li>
                    <a href="#">Gestion des détenus</a>
                  </li>                                     
                  <li>
                    <a href="#">Saisie de détenus</a>
                  </li>
                  <li>
                    <a href="#">Saisie de détenus</a>
                  </li>
                  <li>
                    <a href="#">Saisie de détenus</a>
                  </li>
                  <li>
                    <a href="#">Saisie de détenus</a>
                  </li>
                  <li>
                    <a href="#">Saisie de détenus</a>
                  </li>                                     
                  <li className="divider" />
                  <li data-animation-pro-1  data-animation-pro-auto-update-1="true" data-animation-pro-trigger-1="click" data-animation-pro-target-1=".appsv3-left-close" data-animation-pro-add-classes-1="appsv3-left  col-lg-2" data-animation-pro-rm-classes-1="col-lg-1 appsv3-left-close" data-animation-pro-easing-1="ease-in" data-animation-pro-2 data-animation-pro-auto-update-2="true" data-animation-pro-trigger-2="click" data-animation-pro-target-2=".appsv3-right" data-animation-pro-easing-2="ease-in" data-animation-pro-add-classes-2="col-lg-10" data-animation-pro-rm-classes-2="col-lg-12">
                    <a href="#">Afficher le menu</a>
                  </li>                                     
                </ul>                                 
              </li>                             
            </ul>
            <div style={{display: 'flex', alignItems: 'center'}} className="gc-appone-profile-menu" data-pgc="appone-profile">
              <div data-pgc-field="appone-username" style={{marginRight: 10}}>Bienvenue  {`${auth?.currentUser?.prenom ?? ''} ${auth?.currentUser?.nom ?? ''} `} <a href="#26"><span className="entypo-down-open scnd-font-color" /></a> <br/> ({auth?.currentUser?.lcaisseId ?? ''}) </div>
              <a href="/profil" target="_self">
                <div className="profile-picture small-profile-picture" data-pgc-field="appone-user-img">
                  <img alt="Anne Hathaway picture" src={ProfileImage} width="40px" style={{width: '100%'}} />
                </div>
              </a>
            </div>
            <div className="gc-appone-profile-menu pull-left" data-pgc="appone-profile" style={{marginRight: '15px'}}>
              <div className="btn-group"> 
                <Link className="btn" target="_self" to={localStorage.getItem(LOCAL_STORAGE_KEYS.DEVICE_MODE) === "MOBILE" ? "/dashboard" : "/"}> Accueil</Link> 
                <Link className="btn  soft-scroll" to="/dashboard/consultations" target="_self">Consultation</Link>
                <Link className="btn" load="load_details_vue" to="/dashboard/consultations-operation" target="_self">Rechargement</Link>
                <Link className="btn" load="load_details_vue" onClick={auth.onLogout} target="_self">Déconnexion</Link>
              </div>
            </div>                         
            <a className="navbar-brand appv3-appname hidden-md hidden-lg" href="/" style={{marginLeft: '5px', verticalAlign: 'middle', color: '#ffffff !important'}} target="_self">My-LONACI</a> 
          </div>                     
        </div>                 
       </nav>
      {children}
    </main>
    )
}

export default Layout;
   