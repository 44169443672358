import { useContext, useEffect, useState, useMemo, useCallback } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { AppContext } from '../../../common/constants';
import { TerminalService } from '../../../common/services';

import {Table, LoadableContent, Modal, MyLonaciTabs, Loader} from '../../../components'

// import Filter02Image from  '../../../layout/themes/images/icon/filter-02.png';
import Vue02Image from  '../../../layout/themes/images/icon/vue-02-02.png';

import RefreshImage from  '../../../layout/themes/images/icon/refresh.png';
import MoreToSee02 from  '../../../layout/themes/images/icon/more_to_see-02.png';
//import Righted02Image from  '../../../layout/themes/images/icon/righted-02.png';
import Terminal01Image from  '../../../layout/themes/images/icon/terminal-01.png';
import MenuH03 from  '../../../layout/themes/images/icon/menu-h-03.png';


import printImage from '../../../layout/themes/images/icon/print-03.png'
import { NumberHelper, DateHelper } from '../../../common/helper';

const TerminalDetails = () => {

  const { id : terminalId } = useParams();
  const location = useLocation();
  const {store} = useContext(AppContext);
  const [terminaux, setTerminaux] = useState([]); 
  const [soldes, setSoldes] = useState([]);
  const [soldesLoading, setSoldesLoading] = useState(false)
  const [selectedTerminal, setSelectedTerminal] = useState(location.state); 
  const [forceUpdateSolde, setForceUpdateSolde] = useState(0)

  const date = useMemo(() => DateHelper.getMonthFirstAndLastDate(`${store.month}-01`), [store.month])

  const navigate = useNavigate();
  
      useEffect(() => {
        if(store?.concessionnaire?.id) {
          TerminalService.get(store?.concessionnaire?.id, {debut : date.firstDay?.toISOString(), fin : date.lastDay?.toISOString()})
          .then(setTerminaux)
        }
      }, [store?.concessionnaire?.id])

      useEffect(() => {
        if(store?.concessionnaire?.id) {
          setSoldesLoading(true)
          TerminalService.getSoldes(store.concessionnaire.id)
          .then(setSoldes)
          .finally(() => setSoldesLoading(false))
        }
      }, [store?.concessionnaire?.id, forceUpdateSolde])

      useEffect(() => { 
        if(terminaux?.length) {
          const terminal = terminaux.find(terminal => terminal.id?.toString() === terminalId)
          if(terminal){
            setSelectedTerminal(terminal)
          }else{
            setSelectedTerminal(terminaux[0])
          }
        }
    }, [terminalId, terminaux])


    /*const displaySolde = useCallback((terminal) => {
      const updatedSolde = soldes?.find(solde => solde.numero === terminal.numero)
     if(updatedSolde?.montant) return NumberHelper.formatWithThousandsSeparator(updatedSolde.montant);
     const montantSolde =  isNaN(selectedTerminal?.solde) ? selectedTerminal?.solde?.montant :  selectedTerminal?.solde;
     return (<div><div>{NumberHelper.formatWithThousandsSeparator(montantSolde ?? 0)}</div> <div style={{fontSize : "12px"}}> {`${selectedTerminal?.solde?.date ? '(au ' + DateHelper.format(selectedTerminal?.solde?.date)  + ')': ''}`}</div></div>)
    }, [soldes]) */

    const updatedSolde = useCallback((terminal) => {
      const updatedSolde = soldes?.find(solde => solde.numero === terminal.numero)
     if(updatedSolde?.montant) return NumberHelper.formatWithThousandsSeparator(updatedSolde.montant);
     return <div style={{fontSize : "12px"}}>0</div>
    }, [soldes]) 


    return (
      <>
        <div className="container-fluid terminal-list" data-pgc="terminal_list">
          <div className="product-name-desc pull-left text-left no-padding-section full-height theme-vertical-align col-lg-1 col-md-1 col-xs-4 col-sm-4" style={{paddingLeft: '0px', backgroundColor: '#efce70'}}>
            <div className="full-height theme-vertical-align bloc" style={{flexDirection : 'column'}} >
              <img src={Terminal01Image} className="preview_icon invert visible-md visible-lg" alt="Member Image" />
              <img src={Terminal01Image} className="preview_icon invert visible-xs visible-sm" style={{width : '30px', height : '30px'}} alt="Member Image" />
              <div className="bloc" style={{textAlign : 'center'}}>
                <p className="bold margin-bottom0 margin-top0 label visible-xs visible-sm">TERMINAL</p>
                <p className="bold margin-bottom0 margin-top0 data theme-text-bold name visible-xs visible-sm">{selectedTerminal?.numero ?? 0}</p>
              </div>
            </div>
          </div>
          <div className="product-name-desc pull-left text-left no-padding-section full-height theme-vertical-align col-lg-2 col-md-2 col-sm-4 col-xs-4 hidden-xs hidden-sm" style={{paddingLeft: '0px', backgroundColor: '#efce70', textAlign : 'center'}} data-pgc-edit="aaaaa[class, style, no_content]">
            <div className="bloc">
              <p className="bold margin-bottom0 margin-top0 label">TERMINAL</p>
              <p className="bold margin-bottom0 margin-top0 data theme-text-bold name">{selectedTerminal?.numero ?? 0}</p>
              <span className="description hidden" />
            </div>
          </div>
          <div className="product-name-desc pull-left text-left no-padding-section full-height theme-vertical-align col-lg-3 col-md-3 bloc thin-border-right col-sm-4 col-xs-4" style={{paddingLeft: '0px', backgroundColor: '#fbf1d2', textAlign : 'center'}} >
            <div className="bloc">
              <p className="bold margin-bottom0 label">SOLDE <span style={{fontSize:8}}>(FCFA)</span></p>
              <div className="bold margin-bottom0 margin-top0 data-big" style={{display : 'flex', justifyContent:'center', alignItems: 'center'}}>
                {updatedSolde(selectedTerminal)} {soldesLoading && (<Loader style={{width : '15px', height : '15px', marginLeft : '10px'}} />) }
              </div>
              <a onClick={(event) =>{ 
                  event.stopPropagation();
                  setForceUpdateSolde(forceUpdateSolde + 1)
                  }} style={{color : '#337ab7', fontSize : "12px", textDecoration : 'underline',  cursor: 'pointer'}}>Actualiser</a>
              <span className="description hidden" />
            </div>
          </div>
          <div className="product-name-desc pull-left text-left no-padding-section full-height theme-vertical-align col-lg-3 col-md-3 thin-border-right hidden-sm col-sm-4 col-xs-4" style={{paddingLeft: '0px', backgroundColor: '#fff'}} >
            <div className="bloc" style={{textAlign : 'center'}}>
              <p className="bold margin-bottom0 margin-top0 label">SOLDE VEILLE <span style={{fontSize:8}}>(FCFA)</span></p>
              <div className="bold margin-bottom0 margin-top0 data-big">
                <div>
                  <div>{NumberHelper.formatWithThousandsSeparator(selectedTerminal?.solde?.montant ?? 0)}</div> 
                  <div style={{fontSize : "8px"}}> {`${selectedTerminal?.solde?.date ? '(au ' + DateHelper.format(selectedTerminal?.solde?.date, true)  + ')': ''}`}</div>
                </div>
              </div>
              <span className="description hidden" />
            </div>
          </div>
          <div className="product-name-desc pull-left text-left no-padding-section full-height theme-vertical-align col-lg-2 col-md-2 col-sm-2 col-xs-2 hidden" style={{paddingLeft: '0px'}} data-pgc-field="werwe">
              <div className="bloc text-center container-fluid">
              <a className="btn btn-default" href="details_terminal.html" target="_parent">
                  <img src={MenuH03} load className="lazy invert  icon"  />
              </a>
              </div>
          </div>
          <div className="product-name-desc pull-left text-left no-padding-section full-height theme-vertical-align thin-border-right col-lg-3 col-md-3 col-sm-12 col-xs-12" style={{paddingLeft: '0px', backgroundColor: '#fff'}} data-pgc-field="dsdad">
              <div className="bloc container-fluid full-height" style={{width: '100%'}}>
              <div className="form-group full-height container-fluid no-padding-section">
                  <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12 full-height theme-vertical-align no-padding-section">
                  <select id="formInput1001" className="form-control" style={{backgroundColor: 'transparent', fontSize: '13px'}} value={selectedTerminal?.id} onChange={(event)=> navigate(`/dashboard/terminal/${event.target.value}`, {state : terminaux.find(terminal => terminal.id.toString() === event.target.value)}) }>
                  {
                    terminaux.map(terminal => <option key={terminal.id} value={terminal.id}>{terminal.numero}</option>)
                  }   
                  </select>
                  </div>
                  
              </div>
              <span className="description hidden" />
              </div>
          </div>
        </div>

        <MyLonaciTabs items= {[{title : 'Operations', element : <Operations terminal={selectedTerminal} /> }, {title : 'Chiffre d\'Affaires', element : <ChiffresAffaires terminal={selectedTerminal} /> }]} />

      </>
    )
}

const ChiffresAffaires = ({terminal}) => {
     
  const [isLoading, setLoading] = useState(false); 
  const [chiffreAffaires, setChiffreAffaires] = useState([]); 
  const [isDetailsModalOpen, setDetailsModalOpen] = useState(false)
  const [selectedChiffreAffaire, setSelectedChiffreAffaire] = useState({});
  const {store} = useContext(AppContext);
  
  const date = useMemo(() => DateHelper.getMonthFirstAndLastDate(`${store.month}-01`), [store.month])

  useEffect(() => {
    if(terminal?.id) {
        setLoading(true)
      TerminalService.getChiffreAffaires(terminal.id, {debut : date.firstDay?.toISOString(), fin : date.lastDay?.toISOString()})
      .then(setChiffreAffaires)
      .finally(() => setLoading(false))
    }
  }, [terminal?.id, date])
  

  return (
  <LoadableContent {...{isLoading}}>
  <div className="container-fluid theme-hover-bg-zoom-in animatedParent full-height theme-maincolorback-light thin-border-top sub-section" id="particuliers" style={{backgroundSize: 'contain'}}>
      <div className="appsv3-admin-bloc animated fadeIn col-xs-12 col-sm-12 col-md-12 col-lg-12 sub-section transactions_list">
       
    <Table 
      templates={
        [
          {title : 'Date', field : 'date', render : (item) =>   <div className="full-height theme-vertical-align">
          <div className="btn btn-default btn-data-highlited">
             {DateHelper.format(item.date)}
          </div>
        </div>
        },     
        {title : 'Chiffre d\'affaire brut', hiddenXs : true, field : 'chiffreAffaire', render : (item) =>  
        <div
        className="product-name-desc pull-left text-left no-padding-section col-lg-12 col-md-12 col-sm-12 col-xs-12 full-height theme-vertical-align"
        style={{ paddingLeft: "0PX" }}
      >
        <div>
          <p className="bold margin-bottom0 margin-top0 label">
            Chiffre d'affaires brut
          </p>
          <p className="bold margin-bottom0 margin-top0 data">
          {NumberHelper.formatWithThousandsSeparator(item?.chiffreAffaire ?? 0 )} FCFA
          </p>
          <span className="description hidden" />
        </div>
      </div>},   
      {title : 'Remboursement', hiddenXs : true, field : 'remboursement', render : (item) =>  
      <div
      className="product-name-desc pull-left text-left no-padding-section col-lg-12 col-md-12 col-sm-12 col-xs-12 full-height theme-vertical-align"
      style={{ paddingLeft: "0PX" }}
    >
      <div>
        <p className="bold margin-bottom0 margin-top0 label">
          Remboursement
        </p>
        <p className="bold margin-bottom0 margin-top0 data">
        {NumberHelper.formatWithThousandsSeparator(item?.remboursement ?? 0 )} FCFA
        </p>
        <span className="description hidden" />
      </div>
    </div>},
      {title : 'Annulation', hiddenXs : true, field : 'annulation', render : (item) =>  
      <div
      className="product-name-desc pull-left text-left no-padding-section col-lg-12 col-md-12 col-sm-12 col-xs-12 full-height theme-vertical-align"
      style={{ paddingLeft: "0PX" }}
    >
      <div>
        <p className="bold margin-bottom0 margin-top0 label">
          Annulation
        </p>
        <p className="bold margin-bottom0 margin-top0 data">
        {NumberHelper.formatWithThousandsSeparator(item?.annulation ?? 0 )} FCFA
        </p>
        <span className="description hidden" />
      </div>
    </div>},
        {title : 'Chiffre d\'affaire net', field : 'chiffreAffaire', render : (item) =>  
        <div
        className="product-name-desc pull-left text-left no-padding-section col-lg-12 col-md-12 col-sm-12 col-xs-12 full-height theme-vertical-align"
        style={{ paddingLeft: "0PX" }}
      >
        <div>
          <p className="bold margin-bottom0 margin-top0 label">
            Chiffre d'affaires net
          </p>
          <p className="bold margin-bottom0 margin-top0 data">
          {NumberHelper.formatWithThousandsSeparator((item?.chiffreAffaire ?? 0 )  -  (item?.annulation ?? 0 ) - (item?.remboursement ?? 0 ))} FCFA
          </p>
          <span className="description hidden" />
        </div>
      </div>},
      {title : 'Chiffre d\'affaire brut', field : 'paiement', render : (item) =>  
          <div
          className="product-name-desc pull-left text-left no-padding-section col-lg-12 col-md-12 col-sm-12 col-xs-12 full-height theme-vertical-align"
          style={{ paddingLeft: "0px" }}
          >
          <div>
            <p className="bold margin-bottom0 margin-top0 label">
              Paiement
            </p>
            <p className="bold margin-bottom0 margin-top0 data">
              {NumberHelper.formatWithThousandsSeparator(item.paiement)} FCFA
            </p>
            <span className="description hidden" />
          </div>
        </div>},
        {title : '', field : 'id', render : (item) =>   <div className="product-name-desc pull-left text-left no-padding-section col-lg-12 col-md-12 col-sm-12 col-xs-12 full-height theme-vertical-align" style={{paddingLeft: '0px', textAlign: 'center'}}>
        <div style={{textAlign: 'center', display: 'inline-block'}}>
            <div className="btn-group full-height">
            <button type="button" className="btn btn-default" onClick={() => {
                      setSelectedChiffreAffaire(item)
                      setDetailsModalOpen(true)
                    }}>
                <img src={Vue02Image} className="lazy invert" alt="" />
            </button>
            {/* <div className="btn-group pull-right"> 
              <button type="button" className="btn btn-default dropdown-toggle pull-right" data-toggle="dropdown"> 
                <img src={MoreToSee02} class="invert" style={{marginRight: '0px'}} />
              </button>                                                                                         
              <ul class="dropdown-menu" role="menu"> 
                <li>
                  <a href="#">Impression</a>
                </li>                                                                                             
                <li>
                  <a href="#">Autre action</a>
                </li>                                                                                             
                                                                                            
              </ul>                                                                                         
            </div> */}
            </div>
        </div>
        </div>}
        ]
        } 
      data={chiffreAffaires} />

  </div>
</div>
<Modal isOpen={isDetailsModalOpen} onClose={() => setDetailsModalOpen(false)} >
            <div className="appsv3-admin-bloc animated fadeIn no-padding-section container-fluid bg-transparent" style={{backgroundColor: 'transparent !important'}}>
              <div className="appsv3-admin-bloc-title container-fluid sub-section transactions_list" style={{backgroundColor: 'transparent !important'}}>
                <p data-pgc-field="appsv3-admin-bloc-title" className="pull-left therma">Details du chiffre affaire</p>
                <div className="btn-group pull-right" style={{height: 'auto', marginBottom: '0px !important'}}>
                  <a href={selectedChiffreAffaire?.recu ?? ''} style={{backgroundColor: 'white'}} type="button" className="btn btn-default pull-right no-border">
                    <img src={printImage} className="lazy invert pull-right icon" alt=""  />
                  </a>
                </div>
              </div>
              <div className="no-border container-fluid transactions_list">
                <div className="row apps-line-data">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <div className="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                      <p>Journée</p>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6 col-sm-6 col-xs-6">
                    <p className="details">{new Date(selectedChiffreAffaire.date).toLocaleString("fr-FR", { year: 'numeric', month: 'long', day: 'numeric' })} </p>
                  </div>
                </div>
                <div className="row apps-line-data">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <div className="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                      <p>Chiffre d'affaire brut</p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <p className="details">
                    {NumberHelper.formatWithThousandsSeparator(selectedChiffreAffaire?.chiffreAffaire ?? '')}
                    </p>
                  </div>
                </div>
                
                <div className="row apps-line-data">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <div className="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                      <p>Remboursement</p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <p className="details">
                      {NumberHelper.formatWithThousandsSeparator(selectedChiffreAffaire?.remboursement ?? '')}
                    </p>
                  </div>
                </div>
                <div className="row apps-line-data">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <div className="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                      <p>Annulation</p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <p className="details">
                      {NumberHelper.formatWithThousandsSeparator(selectedChiffreAffaire?.annulation ?? '')}
                    </p>
                  </div>
                </div>
                <div className="row apps-line-data">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <div className="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                      <p>Paiement</p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <p className="details">
                      {NumberHelper.formatWithThousandsSeparator(selectedChiffreAffaire?.paiement ?? '')}
                    </p>
                  </div>
                </div>
                <div className="row apps-line-data">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                      <p>Chiffre d'affaire</p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <p className="details">
                        {NumberHelper.formatWithThousandsSeparator((selectedChiffreAffaire?.chiffreAffaire ?? 0 )  -  (selectedChiffreAffaire?.annulation ?? 0 )  -  (selectedChiffreAffaire?.remboursement ?? 0 )  ?? '')}
                    </p>
                  </div>
                </div>
              </div>
              <div className="text-center section" data-pgc="nostyle_button" data-pgc-field="nostyle_buttonr">
                <a onClick={() => setDetailsModalOpen(false)} className="btn white thin-border btn-default" >Fermer</a>
              </div>
            </div>
            </Modal>
</LoadableContent>)
}

const Operations = ({terminal}) => {
     
  const [isLoading, setLoading] = useState(false); 
  const [operations, setOperations] = useState([]); 
  const [isDetailsModalOpen, setDetailsModalOpen] = useState(false)
  const [selectedOperation, setSelectedOperation] = useState({});
  const {store} = useContext(AppContext);
  
  const date = useMemo(() => DateHelper.getMonthFirstAndLastDate(`${store.month}-01`), [store.month])

  useEffect(() => {
    if(terminal?.id) {
        setLoading(true)
      TerminalService.getOperations(terminal.id, {debut : date.firstDay?.toISOString(), fin : date.lastDay?.toISOString()})
      .then(setOperations)
      .finally(() => setLoading(false))
    }
  }, [terminal?.id, date])

  return (
  <LoadableContent {...{isLoading}}>
  <div className="container-fluid theme-hover-bg-zoom-in animatedParent full-height theme-maincolorback-light thin-border-top sub-section" id="particuliers" style={{backgroundSize: 'contain'}}>
      <div className="appsv3-admin-bloc animated fadeIn col-xs-12 col-sm-12 col-md-12 col-lg-12 sub-section transactions_list">
      <Table 
              templates={
                [
                  {title : 'Code', field : 'code', render : (item) => <div className="full-height theme-vertical-align">
                <div className="profileimg" style={{backgroundColor: '#5c1818'}} >
                    <p style={{lineHeight: '60px', textAlign: 'center'}}>{item.code}</p>
                </div>
                </div>
                },
                {title : 'Libellé', field : 'libelle', render : (item) =>  <div className="product-name-desc product-name-desc-title pull-left text-left no-padding-section col-lg-12 col-md-12 col-sm-12 col-xs-12 full-height" style={{paddingLeft: '0px'}}>
                <div>
                    <p className="bold margin-bottom0 margin-top0 label App-text-nowrap">{new Date(item.date).toLocaleString("fr-FR", { year: 'numeric', month: 'long', day: 'numeric' })} - {item.heure}</p>
                    <p className="bold margin-bottom0 margin-top0 data App-text-nowrap">{item.libelle}</p>
                    <span className="description hidden" />
                </div>
                </div>
                },
                {title : 'Site', field : 'site', hiddenXs: true, render : (item) =>  <div className="product-name-desc pull-left text-left no-padding-section col-lg-12 col-md-12 col-sm-12 col-xs-12 full-height  hidden-xs hidden-sm" style={{paddingLeft: '0px', display: 'flex', alignItems: 'center'}}>
                <div>
                    <p className="bold margin-bottom0 margin-top0 data">{item.site}</p>
                    <span className="description hidden" />
                </div>
                </div>
                },
                {title : 'Montant', field : 'montantReelVerse', render : (item) =>  
                  <div className="product-name-desc pull-left text-left no-padding-section col-lg-12 col-md-12 col-sm-12 col-xs-12 full-height theme-vertical-align" style={{paddingLeft: '0px', textAlign: 'center!important'}}>
                <p className="bold margin-bottom0 margin-top0 data prize-extented">{NumberHelper.formatWithThousandsSeparator(item.montantReelVerse)} FCFA</p>
                </div>},
                {title : '', field : 'id', render : (item) =>   <div className="product-name-desc pull-left text-left no-padding-section col-lg-12 col-md-12 col-sm-12 col-xs-12 full-height theme-vertical-align" style={{paddingLeft: '0px', textAlign: 'center'}}>
                <div style={{textAlign: 'center', display: 'inline-block'}}>
                    <div className="btn-group full-height">
                    <button onClick={() => {
                      setSelectedOperation(item)
                      setDetailsModalOpen(true)
                    }} type="button" className="btn btn-default">
                        <img src={Vue02Image}  className="lazy invert" alt="" />
                    </button>
                    </div>
                </div>
                </div>}
                ]
                } 
              data={operations} />
      </div>
  </div> 
  
  <Modal isOpen={isDetailsModalOpen} onClose={() => setDetailsModalOpen(false)} >
      <div className="appsv3-admin-bloc animated fadeIn no-padding-section container-fluid bg-transparent" style={{backgroundColor: 'transparent !important'}}>
        <div className="appsv3-admin-bloc-title container-fluid sub-section transactions_list" style={{backgroundColor: 'transparent !important'}}>
          <p data-pgc-field="appsv3-admin-bloc-title" className="pull-left therma">Details de la transaction</p>
          <div className="btn-group pull-right" style={{height: 'auto',borderRadius: '0px', border: 'solid 1px #333 !important', fontWeight: 'bold'}}>
            <a href={selectedOperation?.recu }style={{backgroundColor: 'white'}}  className="btn white thin-border btn-default pull-left no-border mylonaci-modal__link-close">
              <img src={printImage} className="invert mylonaci-modal__link-close__icon" alt=""  />
            </a>
          </div>
        </div>
        <div className="no-border container-fluid transactions_list">
          <div className="row apps-line-data">
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
              <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                <p>Code Opération</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 col-sm-6 col-xs-6">
              <p className="details">{selectedOperation?.code ?? ''}</p>
            </div>
          </div>
          <div className="row apps-line-data">
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
              <div className="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                <p>Réference</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
              <p className="details">{selectedOperation?.refOperation ?? ''}</p>
            </div>
          </div>
          <div className="row apps-line-data">
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
              <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                <p>Date et heure</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
              <p className="details">{new Date(selectedOperation.date).toLocaleString("fr-FR", { year: 'numeric', month: 'long', day: 'numeric' })} - {selectedOperation.heure}</p>
            </div>
          </div>
          <div className="row apps-line-data">
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
              <div className="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                <p>Libellé</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
              <p className="details">{selectedOperation?.libelle ?? ''}</p>
            </div>
          </div>
          <div className="row apps-line-data">
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
              <div className="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                <p>Montant</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
              <p className="details">{NumberHelper.formatWithThousandsSeparator(selectedOperation?.montantReelVerse)}</p>
            </div>
          </div>
          {/*<div className="row apps-line-data">
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
              <div className="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                <p>Site</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
              <p className="details">36 Hectares</p>
            </div>
          </div>*/}
          <div className="row apps-line-data">
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
              <div className="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                <p>Site</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
              <p className="details">{selectedOperation?.site ?? ''}</p> 
            </div>
        </div>
        </div>
        <div className="text-center section" data-pgc="nostyle_button" data-pgc-field="nostyle_buttonr">
          <a onClick={() => setDetailsModalOpen(false)} className="btn white thin-border btn-default" >Fermer</a>
        </div>
      </div>
      </Modal>
</LoadableContent>)
}

export default TerminalDetails;