import React, { useState } from 'react';
import Logo from '../../layout/themes/images/my_lonaci_logo.png';
import { Form } from 'antd';
import { LoadableContent } from '../../components';
import LeftArrow from '../../layout/themes/images/icon/left-long-arrow-03.png';
import './style.css'

import Step1 from './signin/Step1';
import Step2 from './signin/Step2';
import Step3 from './signin/Step3';

// =====================================
const SignIn=({isLoading})=> {
    const [form] = Form.useForm();
    const [currentTab, setCurrentTab] = useState(0)
    const [inputValues, setInputValues] = useState({});

    const goToNext=(values)=> {
        setInputValues({...inputValues, ...values})
        setCurrentTab(currentTab + 1)
    }

    const goToPrevious=()=> {
        setCurrentTab(currentTab - 1)
    }

    return (
        <React.Fragment>
            <div className={ `${currentTab === 0 ? 'signin-page' : 'login-page'} row  fullheight full-height`} data-pgc-field="app-content" style={{backgroundImage: 'url("images/backlogin.jpg")', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
                <div className="row gc-login-1 full-height" style={{padding: '0px', margin: '0px'}} data-pgc="chimplogin" data-pgc-field="chimplogin">
                    {/* login-connectpart  section*/}
                    <div className=" arrow-section gc-login-part col-sm-6 animated col-xs-12 col-md-5 col-lg-5  bg-offwhite zoomIn theme-maincolorback-light theme-font-Ubuntu edit-padding" id="logthree" style={{ backgroundColor: '#fff', animationDuration: '0.3s!important'}}>
                        
                        <LoadableContent {...{isLoading}}>
                            {/* log_session */}
                            <div className="container-fluid pad-bottom0 sub-section  transition-soft theme-vertical-align">
                                <div className="container-fluid" style={{width: '100%'}}>
                                    <div>
                                        <div className="container-fluid hidden-xs hidden-sm"  style={{lineHeight: '100%',  width: '10%', position: 'absolute', textAlign:'left', zIndex: 999999}}>
                                            <a href="/" target="_self" className="invert">
                                                <img src={LeftArrow} style={{width: '100%'}}  />
                                            </a>
                                        </div>
                                        <div className="container-fluid hidden-md hidden-lg"  style={{lineHeight: '100%', position: 'fixed', top: 10, left: 20, textAlign:'left', zIndex: 999999}}>
                                            <a href="/" target="_self" className="invert">
                                                <img src={LeftArrow} style={{width: '30%'}} />
                                            </a>
                                        </div>
                                    </div>
                                    <div className="container-fluid section sub-section animated fadeInUp animated-very-fast" data-pgc="outlook_block_title" data-pgc-field="outlook_block_title_bloc" style={{paddingLeft: '0px !important', paddingBottom: '0px !important'}}>
                                        <img src={Logo} width="130px !important"  />
                                    </div>
                                    <div className="container-fluid section sub-section animated fadeInUp animated-very-fast" data-pgc="outlook_block_title" data-pgc-field="outlook_block_title_bloc" style={{padding: '15px 15px 0 15px !important'}}>
                                        <h2 className="margin-top0" style={{paddingBottom: '0px', marginBottom: '0px'}}>Inscription</h2>
                                    </div>

                                    <div className="row animated fadeInUp animated-fast" data-pgc="gc-form-data" style={{marginTop: '1%', opacity: '0.8'}}>
                                        <div className="no-padding-section col-md-12">
                                            {/* <div className="container-fluid theme-maincolorback-light sub-section"> */}
                                            <div className="container-fluid  sub-section">
                                                <div id="regForm" style={{marginTop: '0px', width: '100%', marginBottom: '0px', backgroundColor: 'transparent', paddingTop: '0px', paddingBottom: '0px'}}  className="no-padding-section sign-in">
                                                {/* <form id="regForm" action style={{marginTop: '0px', width: '100%', marginBottom: '0px', backgroundColor: 'transparent', paddingTop: '0px', paddingBottom: '0px'}} className="no-padding-section sign-in"> */}
                                                    {/* One "tab" for each step in the form: */}
                                                    <div className="tab" style={{backgroundColor: 'transparent', display : currentTab === 0 ? 'block' : 'none'}}>
                                                        <Step1 goToNext={goToNext}/>
                                                        <div className="hidden-md hidden-lg" style={{height: '20px'}}></div>
                                                    </div>

                                                    {/* Step2 */}
                                                    <div className="tab" style={{backgroundColor: 'transparent', display : currentTab === 1 ? 'block' : 'none'}}>
                                                        <Step2 goToNext={goToNext} goToPrevious={goToPrevious}/>
                                                    </div>

                                                    {/* Step3 */}
                                                    <div className="tab" style={{backgroundColor: 'transparent', display : currentTab === 2 ? 'block' : 'none'}}>
                                                        <Step3 goToPrevious={goToPrevious} step={currentTab} inputValues={inputValues}/>
                                                    </div>
                                                {/* </form> */}
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                </div>                         
                            </div>
                            
                            {/* <div style={{position: 'absolute', bottom: 5, left: '50%'}} className="hidden-xs hidden-sm">
                                <div id='stepForm' style={{textAlign: 'center', marginTop: '40px'}}>
                                    <span className={`step ${currentTab === 0 ? 'active' : ''}`} />
                                    <span className={`step ${currentTab === 1 ? 'active' : ''}`} />
                                </div>
                            </div> */}
                        </LoadableContent>
                    </div>

                    <div className="col-sm-6 col-xs-12 col-md-7 col-lg-7 hidden-xs" style={{height: '100%', padding: '0px', overflow: 'hidden', backgroundSize: 'cover', backgroundPosition: 'bottom'}} data-animation-pro-1 data-animation-pro-trigger-1="hover" data-animation-pro-target-1=".pretty-effect" data-animation-pro-easing-1="ease-in-out" data-animation-pro-init-state-1="opacity:1;transform:scale(1);" data-animation-pro-step-1-1="transform:scale(2);opacity:0.5;" data-animation-pro-auto-update-1="true" data-animation-pro-hover-out-step-1-1="opacity:1;transform:scale(1);">
                        <div style={{height: '100%', opacity: '0.3'}} className="bg-black">
                        </div>
                        <div className="full-height" style={{position: 'absolute', zIndex: 1000, top: 0}}>
                            <div className="animated fadeInRight vertical-align:center full-height align-vertical-middle text-left col-lg-8 col-md-8 col-sm-8 col-xs-8" style={{padding: '0px', height: '100%'}} data-animation-pro-trigger-1 data-animation-pro-target-1 data-animation-pro-trigger-2="load" data-animation-pro-target-2 data-animation-pro-trigger-3 data-animation-pro-target-3 data-animation-pro-3 data-animation-pro-auto-update-3="false" data-animation-pro-init-state-1>
                            <div className="coming-soon text-left animated full-height" style={{width: '100%', ineHeight: '100%', padding: '30px', paddingRight: '30%', paddingTop: '30%'}}>
                                <div className="col-md-12" style={{height: '25%'}}> </div>
                                <div className="col-md-12" style={{height: '40%'}}>
                                <h1 style={{color: '#fff', fontSize: '6em'}} className="hidd hidden">Locator</h1>
                                <h2 style={{color: 'white', fontWeight: 100}} className="theme-text-medium theme-text-thin">Gérer, Consulter, Recharger..</h2>
                                <a href="#logthree" style={{fontSize: '45px'}} className="visible-xs hidden-lg hidden-md hidden-sm">
                                    <img src="#" width="120px" />
                                    <span className="glyphicon glyphicon-chevron-down hidden" />
                                </a>                                     
                                </div>
                                <div className="col-md-12" style={{height: '30%'}}> </div>
                                {/* /.row / counter */}
                            </div>                             
                            </div>
                        </div>                     
                    </div>
                </div>
            </div>
            
        </React.Fragment>
    )
}

export default SignIn;