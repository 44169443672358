import './no-results.component.scss'

import search02 from '../../layout/themes/images/icon/search-02.png'


function NoResults ({className, message}) {
return (
<div className={`${className} mylonaci-no-results`}  >
    <img src={search02}  className="invert mylonaci-no-results__search-image" />
    <p>
    {message ?? "Aucune donnée disponible ..."}
    </p>
</div>
 )
}

export default NoResults;