import { Alert, Col, Form, Row, Space } from "antd";
import React, { useEffect, useState } from "react";
import SimpleInputField from "../../../components/forms/fields/SimpleInputField";
import { Button } from "../../../components";
import PatternFormatNumberField from "../../../components/forms/fields/PatternFormatNumberField";
import { ConcessionnaireService, OtpService } from "../../../common/services";
import _ from "lodash";

const Step2=({goToNext, goToPrevious})=> {
    const [form] = Form.useForm();
    const [alertErrors, setAlertErrors] = useState(null)
    const [isSubmiting, setIsSubmiting] = useState(false);

    const onSubmit=()=> {
        form.validateFields().then((values)=> {
            console.log("Step 2 values : ", values)
            // Get OTP STATUS
            setIsSubmiting(true)
            const phoneNumber = _.replace(values?.phoneNumber, new RegExp("[\\s-]","g"), "");
            OtpService.generate(phoneNumber)
            .then((response)=> {
                if(response?.status) {
                    goToNext({step2: {...values, phoneNumber: phoneNumber}})
                }
                else {
                    setAlertErrors(response?.message)
                }
            })
            .catch(error=> setAlertErrors("Une erreur est survenue, veillez réessayer"))
            .finally(setIsSubmiting(false))
        })
    }

    return(
        <React.Fragment>
            <Form form={form}>
                <div className="container-fluid no-padding-section">
                    <h3>Étape 2 / 2 : Informations sur le distributeur</h3>
                    <p>Renseignez les informations relatives au distributeur</p>
                </div>
                <div className="appsv3-admin-bloc animated fadeIn no-padding-section container-fluid bg-transparent " style={{backgroundColor: 'transparent !important'}}>
                    <div id="loaded" className="container-fluid" />
                    <div className="container-fluid no-padding-section">
                        <Space direction='vertical' style={{width: "100%"}}>
                            <Row gutter={[8,8]} >
                                <Col md={24} xs={24}>
                                    <SimpleInputField name={"nom"} placeholder={"Nom *"} className="form-control" required={true} />
                                </Col>
                                <Col md={24} xs={24}>
                                    <SimpleInputField name={"prenom"} placeholder={"Prénoms *"} className="form-control" required={true} />
                                </Col>
                                <Col md={24} xs={24}>
                                    <PatternFormatNumberField  name={"phoneNumber"} placeholder="Numéro de téléphone *" format="##-##-##-##-##" className="form-control" required={true}/>
                                </Col>
                                <Col md={24} xs={24}>
                                    <SimpleInputField name={"email"} type={"email"} placeholder={"Email"} className="form-control" />
                                </Col>
                            {alertErrors && (<div className="animated"><Alert message={`${alertErrors}`} type="error" showIcon/></div>)}
                            </Row> 
                            <div style={{display : 'flex', justifyContent : 'flex-end'}} className="virgin-button text-right sub-section" data-pgc="nostyle_button" data-pgc-field="nostyle_buttonr">
                                <button type="button" onClick={() => goToPrevious()}  className="btn btn-override white thin-border btn-default virgin-button" style={{marginRight : '5px'}} >Precedent</button>
                                <Button type="button" 
                                isLoading={isSubmiting}
                                onClick={onSubmit}
                                className="btn btn-override white thin-border btn-default btn-primary virgin-button launch_transaction" >
                                Suivant
                                </Button>
                            </div>
                        </Space>  
                    </div>
                </div>
            </Form>
        </React.Fragment>
    )
}

export default Step2;