import { useContext, useEffect, useState, useMemo } from 'react';

import {Table, Modal, Breadcrumbs} from '../../components'
import Vue02Image from  '../../layout/themes/images/icon/vue-02-02.png';
import { LoadableContent } from '../../components';
import { AppContext } from '../../common/constants';
import { ChiffreAffaireService } from '../../common/services';

import printImage from '../../layout/themes/images/icon/print-03.png'
import { NumberHelper, DateHelper } from '../../common/helper';

function ChiffreAffaire () {

  const {store} = useContext(AppContext)

  const [chiffreAffaires, setChiffreAffaires] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isDetailsModalOpen, setDetailsModalOpen] = useState(false)
  const [selectedChiffreAffaire, setSelectedChiffreAffaire] = useState({}); 

  const date = useMemo(() => DateHelper.getMonthFirstAndLastDate(`${store.month}-01`), [store.month])

  useEffect(() => {
      if(store?.concessionnaire?.id) {
        setLoading(true)
        ChiffreAffaireService.get({debut : date.firstDay?.toISOString(), fin : date.lastDay?.toISOString()}, store.concessionnaire.id).then(setChiffreAffaires).finally(() => setLoading(false))
      }
    }, [store?.concessionnaire?.id, date])


    const dynamicText = "Chiffre d'affaire";

    return (
      <LoadableContent {...{isLoading}}>
        <Breadcrumbs secondLinkText={dynamicText} />
        <div
  className="container-fluid theme-hover-bg-zoom-in animatedParent full-height sub-section"
  id="particuliers"
  style={{ backgroundSize: "contain", backgroundColor: "#fff !important" }}
  data-pgc="CA-LIST_2"
>
  <div className="appsv3-admin-bloc animated fadeIn col-xs-12 col-sm-12 col-md-12 col-lg-12 sub-section bg-white transactions_list">
    <div className="appsv3-admin-bloc-title">
      <p data-pgc-field="appsv3-admin-bloc-title">
        {`Historique du Chiffre d'affaire pour ${store?.concessionnaire?.code}`} 
      </p>
    </div>
    <div className="container-fluid no-padding-section">
      <p
        data-pgc-field="appsv3-admin-bloc-title"
        className="pull-left description"
      >
        Consulter l'historique du chiffre d'affaire du concessionnaire sélectionné
      </p>
    </div>

    <Table 
      templates={
        [
          {title : 'Date', field : 'date', render : (item) =>   <div className="full-height theme-vertical-align">
          <div className="btn btn-default btn-data-highlited">
             {DateHelper.format(item.date)}
          </div>
        </div>
        },
        {title : 'Terminaux', field : 'terminaux', render : (item) =>                           <div
        className="product-name-desc pull-left text-left no-padding-section col-lg-12 col-md-12 col-sm-12 col-xs-12 full-height theme-vertical-align"
        style={{ paddingLeft: "0PX" }}
      >
        <div>
          <p className="bold margin-bottom0 margin-top0 label">
            Terminal
          </p>
          <p className="bold margin-bottom0 margin-top0 data">
            {item.numeroTerminal}
          </p>
          <span className="description hidden" />
        </div>
      </div>
        },
        {title : 'Chiffre d\'affaire brut', hiddenXs : true, field : 'chiffreAffaire', render : (item) =>  
        <div
        className="product-name-desc pull-left text-left no-padding-section col-lg-12 col-md-12 col-sm-12 col-xs-12 full-height theme-vertical-align hidden-xs"
        style={{ paddingLeft: "0PX" }}
      >
        <div>
          <p className="bold margin-bottom0 margin-top0 label">
            Chiffre d'affaires brut
          </p>
          <p className="bold margin-bottom0 margin-top0 data">
          {NumberHelper.formatWithThousandsSeparator(item?.chiffreAffaire ?? 0 )} FCFA
          </p>
          <span className="description hidden" />
        </div>
      </div>},
      {title : 'Remboursement', hiddenXs : true, field : 'remboursement', render : (item) =>  
      <div
      className="product-name-desc pull-left text-left no-padding-section col-lg-12 col-md-12 col-sm-12 col-xs-12 full-height theme-vertical-align hidden-xs"
      style={{ paddingLeft: "0PX" }}
    >
      <div>
        <p className="bold margin-bottom0 margin-top0 label">
          Remboursement
        </p>
        <p className="bold margin-bottom0 margin-top0 data">
        {NumberHelper.formatWithThousandsSeparator(item?.remboursement ?? 0 )} FCFA
        </p>
        <span className="description hidden" />
      </div>
    </div>},
      {title : 'Annulation', hiddenXs : true, field : 'annulation', render : (item) =>  
      <div
      className="product-name-desc pull-left text-left no-padding-section col-lg-12 col-md-12 col-sm-12 col-xs-12 full-height theme-vertical-align hidden-xs"
      style={{ paddingLeft: "0PX" }}
    >
      <div>
        <p className="bold margin-bottom0 margin-top0 label">
          Annulation
        </p>
        <p className="bold margin-bottom0 margin-top0 data">
        {NumberHelper.formatWithThousandsSeparator(item?.annulation ?? 0 )} FCFA
        </p>
        <span className="description hidden" />
      </div>
    </div>},
        {title : 'Chiffre d\'affaire net', field : 'chiffreAffaire', render : (item) =>  
        <div
        className="product-name-desc pull-left text-left no-padding-section col-lg-12 col-md-12 col-sm-12 col-xs-12 full-height theme-vertical-align"
        style={{ paddingLeft: "0PX" }}
      >
        <div>
          <p className="bold margin-bottom0 margin-top0 label">
            Chiffre d'affaires net
          </p>
          <p className="bold margin-bottom0 margin-top0 data">
          {NumberHelper.formatWithThousandsSeparator((item?.chiffreAffaire ?? 0 )  -  (item?.annulation ?? 0 ) - (item?.remboursement ?? 0))} FCFA
          </p>
          <span className="description hidden" />
        </div>
      </div>},
    //   {title : 'Paiement', field : 'paiement', render : (item) =>  
    //   <div
    //   className="product-name-desc pull-left text-left no-padding-section col-lg-12 col-md-12 col-sm-12 col-xs-12 full-height theme-vertical-align"
    //   style={{ paddingLeft: "0PX" }}
    // >
    //   <div>
    //     <p className="bold margin-bottom0 margin-top0 label">
    //       Paiement
    //     </p>
    //     <p className="bold margin-bottom0 margin-top0 data">
    //     {NumberHelper.formatWithThousandsSeparator(item.paiement)} FCFA
    //     </p>
    //     <span className="description hidden" />
    //   </div>
    // </div>},
        {
          title : '', 
          field : 'id',
          width: 50, 
          render : (item) => (   
            <div className="product-name-desc pull-left text-left no-padding-section col-lg-12 col-md-12 col-sm-12 col-xs-12 full-height theme-vertical-align" style={{paddingLeft: '0px', textAlign: 'center'}}>
              <div style={{textAlign: 'center', display: 'inline-block'}}>
                  <div className="btn-group full-height ">
                    <button type="button" className="btn btn-default" style={{position: 'relative', width: '100%', top: '50%'}} onClick={() => {
                              setSelectedChiffreAffaire(item)
                              setDetailsModalOpen(true)
                            }}>
                        <img src={Vue02Image} className="lazy invert" alt="" />
                    </button>
                  </div>
              </div>
            </div>)
        }
        ]
        } 
      data={chiffreAffaires} />

  </div>
</div>
<Modal isOpen={isDetailsModalOpen} onClose={() => setDetailsModalOpen(false)} >
            <div className="appsv3-admin-bloc animated fadeIn no-padding-section container-fluid bg-transparent" style={{backgroundColor: 'transparent !important'}}>
              <div className="appsv3-admin-bloc-title  transactions_list" style={{backgroundColor: 'transparent !important'}}>
                <p data-pgc-field="appsv3-admin-bloc-title" className="pull-left therma">Details du chiffre affaire</p>
                <div className="btn-group pull-right" style={{height: 'auto', marginBottom: '0px !important'}}>
                  <button style={{backgroundColor: 'white'}} type="button" className="btn btn-default pull-right no-border">
                    <img src={printImage} className="lazy invert pull-right icon" alt=""  />
                  </button>
                </div>
              </div>
              <div className="no-border container-fluid transactions_list">
                <div className="row apps-line-data">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <div className="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                      <p>Journée </p>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6 col-sm-6 col-xs-6">
                    <p className="details">{new Date(selectedChiffreAffaire.date).toLocaleString("fr-FR", { year: 'numeric', month: 'long', day: 'numeric' })}</p>
                  </div>
                </div>
                <div className="row apps-line-data">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <div className="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                      <p>Terminal</p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <p className="details">{selectedChiffreAffaire?.numeroTerminal ?? ''}</p>
                  </div>
                </div>
                <div className="row apps-line-data">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <div className="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                      <p>Chiffre d'affaire brut</p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <p className="details">{NumberHelper.formatWithThousandsSeparator(selectedChiffreAffaire?.chiffreAffaire ?? '')}</p>
                  </div>
                </div>
               
                <div className="row apps-line-data">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <div className="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                      <p>Remboursement</p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <p className="details">
                      {NumberHelper.formatWithThousandsSeparator(selectedChiffreAffaire?.remboursement ?? '')}
                    </p>
                  </div>
                </div>

                <div className="row apps-line-data">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <div className="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                      <p>Annulation</p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <p className="details">
                      {NumberHelper.formatWithThousandsSeparator(selectedChiffreAffaire?.annulation ?? '')}
                    </p>
                  </div>
                </div>

                
                <div className="row apps-line-data">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <div className="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                      <p>Paiement</p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <p className="details">
                    {NumberHelper.formatWithThousandsSeparator(selectedChiffreAffaire?.paiement ?? '')}
                    </p>
                  </div>
                </div>
                <div className="row apps-line-data">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <div className="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                      <p>Chiffre d'affaire net</p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <p className="details">
                    {NumberHelper.formatWithThousandsSeparator((selectedChiffreAffaire?.chiffreAffaire ?? 0 )  -  (selectedChiffreAffaire?.annulation ?? 0 )  -  (selectedChiffreAffaire?.remboursement ?? 0 ) ?? '')}
                    </p>
                  </div>
                </div>
              </div>
              <div className="text-center section" data-pgc="nostyle_button" data-pgc-field="nostyle_buttonr">
                <a onClick={() => setDetailsModalOpen(false)} className="btn white thin-border btn-default" >Fermer</a>
              </div>
            </div>
            </Modal>
</LoadableContent>
    )
}


export default ChiffreAffaire;