import {useContext, useEffect} from 'react';
import { AuthContext, LOCAL_STORAGE_KEYS } from '../../common/constants'; 
import { useNavigate, useSearchParams, Link } from 'react-router-dom';

import './home.page.scss'

import ToTtop from '../../layout/themes/images/icon/to-top-02-02.png';
import Logo from '../../layout/themes/images/my_lonaci_logo.png';
import Mobile1 from '../../layout/themes/images/hero/mobile-1.png';
import Mobile3 from '../../layout/themes/images/hero/mobile-3.png';
import Mobile from '../../layout/themes/components/cool_animate/images/mobile.png';
import DashBoard01 from '../../layout/themes/images/icon/dashboard-01.png';
import DashBoard02 from '../../layout/themes/images/icon/eye-03.png';
import DashBoard03 from '../../layout/themes/images/icon/money-03.png';
import DashBoard04 from '../../layout/themes/images/icon/notifcations-03.png';

import SoftAndroid from '../../layout/themes/images/icon/soft-03.png';
import SoftIphone from '../../layout/themes/images/icon/soft-04.png';
import Download03 from '../../layout/themes/images/icon/icons8-plus-24.png' //'../../layout/themes/images/icon/download-03.png';

import { FaFacebook, FaYoutube, FaInstagram, FaTwitter } from 'react-icons/fa';
import { size } from 'lodash';


function Home () {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);

const onSignIn=()=> {
  navigate('/login', {state: {
    signIn: true
  }})
}
return (
 <div style={{backgroundImage:'url(/images/mainback.jpg)',backgroundAttachment: 'scroll'}}>
  <div className="container bg-transparent" style={{width: '40px', height: '40px', opacity: 1, position: 'fixed', zIndex: 6000, bottom: '30px', right: '30px', textIndent: '-9999px', background: 'url("icon_top.png") no-repeat', padding: '0px'}}>
    <a href="#" className="scrollup pull-right" style={{textAlign: 'right'}}>
      <button type="button" className="btn bg-white margin-top0 black thin-border theme-opacity-half" style={{color: '#ffffff', width: '60px', height: '60px', paddingLeft: '10px', paddingRight: '10px', paddingTop: '10px'}}>
        <img src={ToTtop} width="30px" className="invert" />
        <span className="hidden">TOP</span>
      </button>
    </a>
  </div>
  <div className="container-fluid alpha hidden-xs hidden-sm bg-transparent" data-pgc="dubai" data-pgc-field="dubainavigation" style={{backgroundColor: 'transparent !important', background: 'transparent !important'}}>
    <div className="row header pad5 pad-bottom5 section bg-transparent">
      <div className="col-md-9 col-lg-9 col-sm-9 visible-xs hidden-lg hidden-md col-xs-8">
        <a href="#fullmenu2" data-toggle="modal" className="pull-right hidden-lg hidden-md visible-sm visible-xs silver responsive-link"> <span className="glyphicon glyphicon-menu-hamburger" /></a> 
      </div>
    </div>             
  </div>
  

 <div className="navbar-header bg-white" style={{ width: "100%" }}>
  <div className="col-md-6 col-lg-12 hidden-lg hidden-md visible-xs col-xs-4 col-sm-4 visible-sm"
    style={{ paddingLeft: 0 }}>
    <a href="#fullmenu" data-toggle="modal">
      <button type="button" className="navbar-toggle pull-left" style={{ width: "100%" }}>
        <div style={{ float: "left!important" }}>
          <img src={Logo} className="margin-top5" style={{ marginLeft: 3 }} width="100px"/>
        </div>
        <span className="sr-only">Toggle navigation</span>
      </button>
    </a>
  </div>
  <div className="col-md-6 col-lg-12 hidden-lg hidden-md visible-xs margin-top5 visible-sm col-xs-8 col-sm-8">
    <ul className="nav navbar-nav pad-bottom0 no-border bg-transparent" style={{ width: "100% !important" }} >
      {!auth?.isAuthenticated  ? ( <li className="nav-item pull-right">
          <a data-hover="dropdown" data-delay={0} data-close-others="false" href="/login" target="_self" className="white theme-maincolorback-dark" 
           style={{ color: "#fff !important" }}>SE CONNECTER 
          </a> 
          </li>): ( <li className="nav-item pull-right">
          <Link data-hover="dropdown" data-delay={0} data-close-others="false" style={{cursor:'pointer', color: "#fff !important"}}  to="/dashboard" className="white theme-maincolorback-dark" >
            Mon compte
          </Link> 
        </li>)}
        <li className="nav-item pull-right">
        <a data-hover="dropdown" data-delay={0} data-close-others="false" href="#faq" target="_self" 
        className="faq-link">
          {/* <FaQuestionCircle size={25} color="white" /> */}
          {/* <img src={Faq} alt="faq" style={{height: "20px", width: '20px'}}/> */}
        </a>
      </li>
    </ul>
  </div>
 </div>
  <header className="hidden-xs hidden-sm no-padding-section no-border" data-pgc="scrolling_header" data-pgc-field="scrolling_header" style={{paddingTop: '0px!IMPORTANT', width: '100%'}}>
    <div className="pad0 hidden-xs hidden-sm visible-md visible-lg container bg-transparent" style={{padding: '0px!important', marginBottom: '0px'}}>
      <div className="container-fluid alpha animated fadeIndown fadeInDown bg-transparent hidden" data-pgc="dubai" data-pgc-field="dubainavigation" style={{marginBottom: '0px'}}>
        <div className="row header alpha animated fadeIn bg-transparent">
          <div className="col-md-9 col-lg-9 col-sm-9 visible-xs hidden-lg hidden-md col-xs-8">
            <a href="#fullmenu2" data-toggle="modal" className="pull-right hidden-lg hidden-md visible-sm visible-xs silver responsive-link"><span className="glyphicon glyphicon-menu-hamburger" /></a> 
          </div>
          <div className="hidden-xs full-height bg-transparent col-lg-5 col-md-5 col-sm-5" style={{height: '100%', display: 'inline-block', float: 'left'}}>
            <ul className="nav navbar-nav pad-bottom0 no-border topped" style={{width: '100%'}}> 
              <li className="margin-top0 hidden">
                <a href="#searchin" className="ivonedlink animated fadeIn theme-text-medium black margin-bottom0 margin-top20" data-pgc="iconelink" data-toggle="modal"><span className="glyphicon glyphicon-search" style={{marginTop: '5px', float: 'left'}} /></a>
              </li>
              <li className="nav-item">
                <a data-hover="dropdown" data-delay={0} data-close-others="false" href="#particuliers" target="_self">PARTICULIERS<i className="fa fa-angle-down" /></a> 
              </li>
              <li className="nav-item">
                <a data-hover="dropdown" data-delay={0} data-close-others="false" href="#entreprises" target="_self">ENTREPRISES<i className="fa fa-angle-down" /></a> 
              </li>                                 
            </ul>
          </div>
          <div className="hidden-xs full-height col-lg-7 col-md-7 col-sm-7 col-xs-7" style={{height: '100%', display: 'inline-block', float: 'left'}}>
            <ul className="nav navbar-nav pad-bottom0 no-border topped" style={{width: '100%'}}> 
              <li className="margin-top0 pull-right hidden">
                <a href="#searchin" className="ivonedlink animated fadeIn theme-text-medium black margin-bottom0 margin-top20" data-pgc="iconelink" data-toggle="modal"><span className="glyphicon glyphicon-search" style={{marginTop: '5px', float: 'left'}} /></a>
              </li>
              <li className="nav-item pull-right">
                <a data-hover="dropdown" data-delay={0} data-close-others="false" href="#pricing" target="_self" className="white">Inscription <i className="fa fa-angle-down" /></a> 
              </li>
              {!auth?.isAuthenticated  ? ( <li className="nav-item pull-right">
                <a data-hover="dropdown" data-delay={0} data-close-others="false" href="/login" target="_self" className="white theme-maincolorback-dark" >
                  SE CONNECTER 
                </a> 
              </li>): ( <li className="nav-item pull-right">
              <Link data-hover="dropdown" data-delay={0} data-close-others="false" style={{cursor:'pointer', color: "#fff !important"}}  to="/dashboard" className="white theme-maincolorback-dark" >
                Mon compte
              </Link> 
              </li>)}  
              <li className="dropdown nav-item pull-right hidden"> 
                <a href="#" className="dropdown-toggle" data-toggle="dropdown">Dropdown <b className="caret" /></a> 
                <ul className="dropdown-menu"> 
                  <li>
                    <a href="#">Action</a>
                  </li>                                         
                  <li>
                    <a href="#">Another action</a>
                  </li>                                         
                  <li>
                    <a href="#">Something else here</a>
                  </li>                                         
                  <li className="divider" />                                         
                  <li>
                    <a href="#">Separated link</a>
                  </li>                                         
                  <li className="divider" />                                         
                  <li>
                    <a href="#">One more separated link</a>
                  </li>                                         
                </ul>                                     
              </li>
              <li className="nav-item pull-right">
                <a data-hover="dropdown" data-delay={0} data-close-others="false" href="#success-story" target="_self">SUPPORTS<i className="fa fa-angle-down" /></a> 
              </li>
              <li className="nav-item pull-right">
                <a data-hover="dropdown" data-delay={0} data-close-others="false" href="#missions" target="_self" className="soft-scroll">FAQ   <i className="fa fa-angle-down" /></a> 
              </li>                                 
            </ul>
          </div>
        </div>                     
      </div>
      <nav className="navbar navbar-default gc-van-navbar margin-bottom0 no-border bg-transparent sub-section theme-maincolorback-dark" role="navigation" data-pgc="hover-drop" data-pgc-field="hover-drop" style={{width: '100%', paddingLeft: '0px', paddingTop: '0px !important'}}> 
        <div style={{padding: '0px'}} className="container-fluid"> 
          <div className="navbar-header">
            <div className="col-xs-6 col-sm-6 col-md-6 col-lg-12 hidden-lg hidden-md hidden-sm visible-xs" style={{paddingLeft: '0px'}}>
              <button type="button" className="navbar-toggle pull-left" id="show-responsivemenu" style={{width: '100%'}}> 
                <span className="glyphicon margin-top10 glyphicon-align-justify" style={{float: 'left'}} />
                <div style={{float: 'left!important'}}>
                  <img src="images/Capture%252520d%2525E2%252580%252599e%2525CC%252581cran%2525202020-05-14%252520a%2525CC%252580%25252011.46.16.png" className="margin-top5" style={{marginLeft: '15px'}} width="50px" />
                </div>
                <span className="sr-only">Toggle navigation</span> 
              </button>                                 
            </div>
            <div className="col-xs-6 col-sm-6 col-md-6 col-lg-12 hidden-lg hidden-md hidden-sm visible-xs margin-top5">
              <ul className="nav navbar-nav pad-bottom0 bg-transparent" style={{width: '95%'}}> 
                <li className="pull-right">
                  <a target="_self">En</a>
                </li>
                <li className="pull-right">
                  <a target="_self" className="silver">Fr</a>
                </li>
              </ul>                                 
            </div>                             
          </div>
          <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1" style={{padding: '0px'}}> 
            <ul className="nav navbar-nav pad-bottom0 no-border topped bg-transparent sub-section" style={{width: '100%'}}> 
              <li className="dropdown appv3-left-popup visible-xs visible-sm" data-animation-pro-1  data-animation-pro-auto-update-1="true"  data-animation-pro-scroll-direction-1="down" data-animation-pro-offset-1="250px" data-animation-pro-init-state-1 data-pgc="appsv3-popupleft"> 
                <div className="modal-container" data-pg-name="From right to left" data-pgc-define data-pgc-define-auto-update="false">
                  <button type="button" className="btn appv3-topmenu-popup-btn" data-animation-pro-1 style={{marginTop: '0px'}} data-animation-pro-trigger-1="click" data-animation-pro-target-1="| .modal-bg" data-animation-pro-add-classes-1="open" data-animation-pro-sd-click-add-classes-1="open" data-animation-pro-2 data-animation-pro-trigger-2="click" data-animation-pro-target-2="| .ap-modal" data-animation-pro-add-classes-2="open animate" data-animation-pro-sd-click-add-classes-2="open animate" data-animation-pro-duration-1={0} data-animation-pro-duration-2={0} data-pgc-define data-pgc-define-auto-update="false" data-animation-pro-3> 
                    <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                      <img src="images/icon/hammer-02.png" /> 
                    </a>                                             
                  </button>
                  <div className="modal-bg"  />
                  <div className="ap-modal 3D-sign"  data-animation-pro-1 data-animation-pro-trigger-1="class" data-animation-pro-class-name-1="animate" data-animation-pro-target-1="> .modal-content" data-animation-pro-init-state-1="opacity:0;transform:translateX(-100%);" data-animation-pro-class-removed-step-1-1="opacity:0;transform:translateX(100%);" data-animation-pro-2 data-animation-pro-trigger-2="class" data-animation-pro-class-name-2="animate" data-animation-pro-class-removed-rm-classes-2="open" data-animation-pro-delay-2={500} data-animation-pro-easing-1="ease-out-quad" data-animation-pro-step-1-1="opacity:1;transform:translateX(0);" data-animation-pro-duration-1={300} data-animation-pro-class-removed-step-2-1="transform:translateX(-100%);" data-pgc-field="modal-lg">
                    <div className="modal-content" style={{opacity: 0, transform: 'translateX(-100%)', paddingRight: '0px', height: '100%'}}>
                      <div className="thin-border" data-pgc-field="modal-title">
                        <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9" style={{verticalAlign: 'middle', display: 'inline-block'}}> 
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3" style={{margin: '0px', padding: '0px'}}> 
                          <button type="button" data-animation-pro-1 style={{border: 'none', margin: '0px', width: '100%', padding: '0px', position: 'absolute', zIndex: 1000, backgroundColor: 'transparent !important'}} data-animation-pro-trigger-1="click" data-animation-pro-target-1="^ .ap-modal | .modal-bg" data-animation-pro-rm-classes-1="open" data-animation-pro-sd-click-rm-classes-1="open" data-animation-pro-2 data-animation-pro-trigger-2="click" data-animation-pro-target-2="^ .ap-modal" data-animation-pro-rm-classes-2="animate" data-animation-pro-sd-click-rm-classes-2="animate" data-pgc-field="btn-close-title">
                            <img src="images/icon/map-02.png" className="invert" />
                          </button>                                                         
                        </div>                                                     
                      </div>
                      <div className="appsv3-tabpanes" data-pgc="appsv3-admin-tabpanes">
                        <div className="container-fluid profileslide no-padding-section shadow-6" data-pgc-define="profileslide" data-pgc-define-name="profile-slide" data-pgc-define-auto-update="false" data-pgc-edit="profileslide[class, style]" data-pgc-edit-bckimage data-pgc-section="profiles">
                          <div className="container-fluid sub-section thin-border-bottom">
                            <h1 className="theme-text-medium theme-text-bold">Mon compte</h1>
                          </div>
                          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 sub-section">
                            <div className="profileimg grayscale">
                              <img src="components/apps/images/1619287_829025000448211_764867811_n-2.jpg" className="img-responsive img-rounded" width="100%" />
                            </div>                                                             
                          </div>
                          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center theme-smallblock-space" style={{padding: '0px'}}>
                            <h2 className="text-center theme-text-bold">Ahmed Ben Adams BAMBA</h2>
                            <h3 className="text-center">@ivorycoast Best-designer</h3> 
                            <p><span>Connecté</span> <span>Heure de connexion:12h30</span></p>
                            <div className="bg-transparent sub-section" data-pgc="gcs-filter-link">
                              <ul> 
                                <a href="#">
                                  <li>A propos de moi</li>
                                </a>                                                                     
                                <a href="#">
                                  <li>Afficher le compte</li>
                                </a>                                                                     
                                <a href="#">
                                  <li>Se deconnecter</li>
                                </a>                                                                     
                              </ul>
                            </div>                                                             
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>                                     
                <ul className="dropdown-menu"> 
                  <li>
                    <a href="#">Saisine</a>
                  </li>
                  <li>
                    <a href="#">Gestion des procédures pénales</a>
                  </li>                                         
                  <li>
                    <a href="#">Citation directe par exploit d'huissier</a>
                  </li>                                         
                  <li>
                    <a href="#">Plainte avec constitution de parties civiles</a>
                  </li>                                         
                  <li>
                    <a href="#">Plaintes et PV</a>
                  </li>
                  <li className="divider" />
                  <li>
                    <a href="#">Requêtes</a>
                  </li>
                  <li>
                    <a href="#">Saisie d' une requête</a>
                  </li>
                  <li className="divider" />
                  <li>
                    <a href="#">Gestion des détenus</a>
                  </li>                                         
                  <li>
                    <a href="#">Saisie de détenus</a>
                  </li>
                  <li>
                    <a href="#">Saisie de détenus</a>
                  </li>
                  <li>
                    <a href="#">Saisie de détenus</a>
                  </li>
                  <li>
                    <a href="#">Saisie de détenus</a>
                  </li>
                  <li>
                    <a href="#">Saisie de détenus</a>
                  </li>                                         
                  <li className="divider" />
                  <li data-animation-pro-1  data-animation-pro-auto-update-1="true" data-animation-pro-trigger-1="click" data-animation-pro-target-1=".appsv3-left-close" data-animation-pro-add-classes-1="appsv3-left  col-lg-2" data-animation-pro-rm-classes-1="col-lg-1 appsv3-left-close" data-animation-pro-easing-1="ease-in" data-animation-pro-2 data-animation-pro-auto-update-2="true" data-animation-pro-trigger-2="click" data-animation-pro-target-2=".appsv3-right" data-animation-pro-easing-2="ease-in" data-animation-pro-add-classes-2="col-lg-10" data-animation-pro-rm-classes-2="col-lg-12">
                    <a href="#">Afficher le menu</a>
                  </li>                                         
                </ul>                                     
              </li>
              <li>
                <h1 id="logo">
                    <img src={Logo} className="margin-top0" />
                </h1>
              </li>
              <li className="margin-top0 pull-right hidden">
                <a href="#searchin" className="ivonedlink animated fadeIn theme-text-medium black margin-bottom0 margin-top20" data-pgc="iconelink" data-toggle="modal">
                  <span className="glyphicon glyphicon-search" style={{marginTop: '5px', float: 'left'}} />
                </a>
              </li>            
              {!auth?.isAuthenticated  ? ( <li className="nav-item pull-right">
                <a data-hover="dropdown" data-delay={0} data-close-others="false" href="/login" target="_self" className="white theme-maincolorback-dark" >
                  SE CONNECTER 
                </a> 
              </li>): ( <li className="nav-item pull-right">
              <Link data-hover="dropdown" data-delay={0} data-close-others="false" style={{cursor:'pointer', color: "#fff !important"}}  to="/dashboard" className="white theme-maincolorback-dark" >
              Mon compte
              </Link>
              </li>)}   
              <li className="nav-item pull-right">
                <a style={{color: '#0000 !important'}} data-hover="dropdown" data-delay={0} data-close-others="false" href="#faq" target="_self" className="soft-scroll">
                  FAQ
                <i className="fa fa-angle-down" />
                </a> 
              </li>                             
            </ul>                             
          </div>                         
        </div>                     
      </nav>
    </div>
  </header>
  <div className="container-fluid no-padding-section bg-transparent pad0" data-pgc-edit="main_content[class, style]">
    <div className="container-fluid animatedParent section welcome-bloc bg-transparent" id="partners" style={{backgroundColor: 'none !important', paddingTop: '0px !important', backgroundRepeat: 'no-repeat', backgroundOrigin: '-90px -90px'}}>
      <div className="container full-height sub-section"> 
        <div className="animatedParent col-md-6 col-lg-6 theme-maincolorback-dark bg-transparent col-xs-12 col-sm-12">
          <h2 className="theme-text-bold title animated fadeInLeft theme-animate-delay-01s hidden" style={{color: '#131a66', fontSize: '4em !important'}}>MyLONACI</h2>
          <h3 className="theme-text-bold animated fadeInLeft theme-animate-delay-02s calling" style={{color: '#131a66'}}>Gérer, Consulter, Recharger.</h3>
          <h6 className="animated fadeInUp growIn slower gc-main-welcometext theme-animate-delay-03s">L'application clée pour la supervision de votre activité.</h6>
          <p className="gc-bloc-sub-header white text-left animated animatedParent_fadeIn hidden" style={{textAlign: 'left!important', paddingLeft: '0px', paddingRight: '1px'}}>USAID is the world's premier international development agency and a catalytic actor driving development results. USAID's work advances U.S. national security and economic prosperity, demonstrates American generosity, and promotes a path to recipient self-reliance and resilience.</p> 
          <a href="#download" target="_self" style={{clear: 'both'}} onClick={onSignIn}>
            <button  type="button" className="btn btn-xs btn-default btn-download" data-pgc="btn_download">
              {/* <img src={Download03} width="30px" className="invert" /> */}
              <span className='btn-home-download'>Créer mon compte</span>
            </button>
          </a>
        </div>
        <div className="animatedParent col-md-5 col-lg-5 text-center right col-sm-12 col-xs-12" style={{marginBottom: 25}}>
          <div className="threebloc-animate no-padding-section right col-lg-12 col-md-12 pull-right col-sm-12 col-xs-12" data-pgc="3slide_animate">
            <img src={Mobile1} className="animated fadeInUp animated-very-fast transition-soft threebloc-animate-item" style={{position: 'absolute', zIndex: 3, animationDelay: '0.1s', left: '0%'}} />
            <img src={Mobile3} className="animated fadeInUp animated-fast threebloc-animate-item" style={{position: 'absolute', top: '15px', zIndex: 2, left: '15%', animationDelay: '0.2s'}} />
            <img src={Mobile} className="animated fadeInUp animated-slow threebloc-animate-item" style={{position: 'absolute', zIndex: 1, left: '25%', marginTop: '0px', top: '0px', animationDelay: '0.3s'}} /> 
          </div>
          <div style={{zIndex: 7000}} className="animatedParent pull-right main_img_hero hidden">
            <img alt="Client Logo" src="images/hero/my_lonaci_app_mobile.png" style={{width: '150%', height: 'auto', marginTop: '-67px'}} className="text-center fadeInUp animated growIn main_img_hero" /> 
          </div>                         
        </div>                     
      </div>
    </div>
    <div id data-pgc="Slider_dash" data-pgc-field="Slider_dash_content">
      <div className="sub-section bg-white container-fluid theme-font-elegant7" style={{backgroundImage: 'url(images/two-friends-cheering-supporting-mate-competition-yelling-joyfully-clenching-fists-supporti.jpg)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover',backgroundOrigin: 'right', backgroundPosition: 'right top'}}>
        <div className="container">
          <div className="container-fluid bg-transparent" style={{backgroundColor: 'transparent !important'}}>
            <div className="gc-block-header container-fluid animated text-center bg-transparent slower" data-pgc="gc-block-header" data-pgc-field="gc-block-header">
              <h1 className="title theme-text-bold" style={{color: '#fff'}}>Des fonctionnalités importantes pour la gestion de votre business</h1>
            </div>
            <h3 className="gc-bloc animated animatedParent_fadeIn" style={{ color: '#fff', textAlign: 'center', marginBottom:30 }}>Gérer votre compte depuis votre mobile. </h3>
          </div>
        </div>
        <div className="no-padding-section container">
          <div>
            <div id="owl-demo-3-slides" className="owl-carousel">
              <div className="item no-padding-section fadeInRight animated theme-animate-delay-01s bg-transparent" style={{paddingTop: '0px !important'}}>
                <div className="container-fluid xtron" style={{padding: '0px'}} data-pgc-field="picturey">
                  <div className="xclam col-lg-12 col-md-12 col-sm-12 col-xs-12 pad-bottom0 container-fluid theme-maincolorback2 shadow-6" style={{backgroundSize: 'cover'}}>
                    <div className="container-fluid margin-bottom0" style={{padding: '2%', border: 'none', margin: '-5px'}}>
                      <a href="#faq">
                        <img src={DashBoard01} width="90px" />
                      </a>
                      <li className="media" style={{background: 'none'}}> 
                        <div className="media-body no-padding-section" style={{height: 'auto', background: 'none', paddingBottom: '0px!important'}}> 
                          <h3 className="white margin-bottom0">Tableau de bord</h3>
                          <p className="white margin-bottom0 left-align" style={{textAlign: 'center'}}>supervisez votre activité en toute simplicité grâce à un tableau de bord qui vous offre une vue 360°.</p> 
                        </div>                                                     
                      </li>
                      {/* <Link to="dashboard">Link</Link> */}
                    </div>                                             
                  </div>
                </div>
              </div>
              <div className="item animated fadeInRight theme-animate-delay-02s no-padding-section bg-transparent" style={{paddingTop: '0px !important'}}>
                <div className="container-fluid xtron" style={{padding: '0px'}} data-pgc="exclammm" data-pgc-field="picturey">
                  <div className="xclam col-lg-12 col-md-12 col-sm-12 col-xs-12 pad-bottom0 container-fluid animated theme-maincolorback" style={{backgroundSize: 'cover'}}>
                    <div className="container-fluid margin-bottom0" style={{padding: '2%', border: 'none', margin: '-5px'}}>
                      <a href="#faq"><img src={DashBoard02} width="90px"  /></a>
                      <li className="media" style={{background: 'none'}}> 
                        <div className="media-body no-padding-section" style={{height: 'auto', background: 'none', paddingBottom: '0px!important'}}> 
                          <h3 className="white margin-bottom0">Consultation</h3>
                          <p className="white margin-bottom0 left-align" style={{textAlign: 'center'}}>consultez l'historique détaillé de vos activités et faites des extractions</p> 
                        </div>                                                     
                      </li>
                      {/* <Link to="dashboard/operations">Link</Link> */}
                    </div>                                             
                  </div>
                </div>
              </div>
              <div className="item no-padding-section bg-transparent">
                <div className="container-fluid xtron" style={{padding: '0px'}} data-pgc="exclammm" data-pgc-field="picturey">
                  <div className="xclam col-lg-12 col-md-12 col-sm-12 col-xs-12 pad-bottom0 container-fluid animated bg-clouds theme-maincolorback2" style={{backgroundSize: 'cover'}}>
                    <div className="container-fluid margin-bottom0" style={{padding: '2%', border: 'none', margin: '-5px', background: 'none'}}>
                      <a href="#faq"><img src={DashBoard03} width="90px" /></a>
                      <li className="media" style={{background: 'none'}}> 
                        <div className="media-body no-padding-section" style={{height: 'auto', background: 'none', paddingBottom: '0px!important'}}> 
                          <h3 className="white margin-bottom0">Opérations</h3>
                          <p className="white margin-bottom0 left-align" style={{textAlign: 'center'}}>réalisez vos opérations de rechargement en toute simplicité, sans vous déplacer
                            .</p> 
                        </div>                                                     
                      </li>
                      
                      {/* <Link to="dashboard/rechargements">Link</Link> */}
                    </div>                                             
                  </div>
                </div>
              </div>
              <div className="item no-padding-section bg-transparent">
                <div className="container-fluid xtron" style={{padding: '0px'}} data-pgc="exclammm" data-pgc-field="picturey">
                  <div className="xclam col-lg-12 col-md-12 col-sm-12 col-xs-12 pad-bottom0 container-fluid theme-maincolorback" style={{backgroundSize: 'cover'}}>
                    <div className="container-fluid margin-bottom0" style={{padding: '2%', border: 'none', margin: '-5px'}}>
                      <a href="#faq"><img src={DashBoard04} width="90px" /></a>
                      <li className="media" style={{background: 'none'}}> 
                        <div className="media-body no-padding-section" style={{height: 'auto', background: 'none', paddingBottom: '0px!important'}}> 
                          <h3 className="white margin-bottom0">Notifications</h3>
                          <p className="white margin-bottom0 left-align" style={{textAlign: 'center'}}>soyez informés en temps réel par SMS et/ou email des évènements marquants liés à votre compte
                            .</p> 
                        </div>                                                     
                      </li>
                      {/* <Link to="dashboard/notifications">Link</Link> */}
                    </div>                                             
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="container-fluid animatedParent section thin-border-top bg-offwhite hidden" id="partners" style={{backgroundImage: 'url("images/background/all_back-01-01-02-02.png")'}}>
      <div className="container">
        <section id="content-1-6" className="content-1-6 content-block animated slow" style={{padding: '0px', backgroundColor: 'transparent !important'}}>
          <div className="sub-section col-lg-12 col-md-12" style={{paddingLeft: '0px!important'}}>
            <div className="gc-block-header container-fluid animated fadeIn text-center theme-smallblock-space bg-transparent">
              <p className="description text-center theme-font-elegant9 black">My lonaci.</p>
              <h1 className="title theme-text-bold black">LA PLATEFORME REFERENCE</h1>
              <p className="description text-center theme-font-elegant9 black">Description</p>
            </div>
            <div className="container-fluid margin-bottom30 no-padding-section">
              <div className="no-padding-section col-md-5">
                <div id="id-47965328-7bb2-4e85-bc6f-5b29db11307a" className="panel secondary-panel fos has-disclaimer bg-transparent text-center" data-track-name="forty-panel" data-track-index={2}>
                  <img src="images/hero/my_lonaci_app_mobile.png" className="text-center" width="80%" />
                </div>                                     
              </div>
              <div className="no-padding-section col-md-7">
                <div id="id-47965328-7bb2-4e85-bc6f-5b29db11307a" className="panel secondary-panel fos has-disclaimer bg-transparent sub-section" data-track-name="forty-panel" data-track-index={2}>
                  <div>
                    <h3 className="theme-text-bold">Trouvez des informations synthétique sur le client et sa situation (nom, nombre de terminaux, solde, rechargement du mois courant, chiffre d’affaire du mois courant, commission estimée pour le mois)</h3>
                    <div className="content">
                      <disclaimer></disclaimer>
                    </div>
                  </div>
                </div>
                <div id="id-47965328-7bb2-4e85-bc6f-5b29db11307a" className="panel secondary-panel fos has-disclaimer bg-transparent sub-section thin-border-top" data-track-name="forty-panel" data-track-index={2}>
                  <div className="headline">
                    <h3 className="heading theme-text-bold">Trouvez la liste des dernières opérations (rechargement, paiement ticket, achat consommable)</h3>
                    <div className="content">
                      <disclaimer></disclaimer>
                    </div>
                  </div>
                </div>                                     
              </div>
            </div>                             
          </div>   
        </section>
      </div>
    </div>
    {/* <div className="container-fluid sub-section no-padding-section" style={{padding: '0px!important'}}>
    </div>
    <div className="container-fluid sub-section no-padding-section" style={{padding: '0px!important'}}>
    </div>
    <div className="container-fluid sub-section no-padding-section" style={{padding: '0px!important'}}>
    </div> */}
  </div>
  <div id='download' className="text-left section container-fluid thin-border-top bg-gray theme-maincolorback-light">
    <div className="container">
      <div className="container-fluid bg-transparent">
        <div className="gc-block-header container-fluid animated text-center bg-transparent slower" data-pgc="gc-block-header" data-pgc-field="gc-block-header">
          <h1 className="title theme-text-bold">TÉLÉCHARGER L'APPLICATION</h1>
        </div>
        <p className="gc-bloc-sub-header animated animatedParent_fadeIn">Téléchargez pour Android et Apple iOS </p>
      </div>
    </div>
    <div className="text-center container">
      <div className="col-md-6 col-lg-6 col-sm-6 col-xs-12 sub-section">
        <a href>
          <div className="sub-section theme-maincolorback-dark">
            <img src={SoftAndroid} style={{marginTop: '10px', width: '60%'}} />
          </div>
        </a>                     
      </div>
      <div className="col-md-6 col-lg-6 col-sm-6 col-xs-12 sub-section">
        <a href>
          <div className="sub-section theme-maincolorback-dark">
            <img src={SoftIphone} style={{marginTop: '10px', width: '60%'}} />
          </div>
        </a>                     
      </div>
    </div>
  </div>

  <div
  className="container-fluid theme-full-gradient-back5"
  style={{ padding: 0 }}
>
  <footer id="footer" role="contentinfo">
    <div className="container">
      <div className="container-fluid sub-section">
        <div className="footer--widgets" role="complementary">
          <div
            id="custom_html-1"
            className="col-lg-3 col-md-3 sub-section col-xs-12 col-sm-12"
          >
            <div className="textwidget custom-html-widget">
              <div style={{ textAlign: "justify !import" }}>
                <img
                  src="https://www.lonaci.ci/wp-content/uploads/2021/10/logo.png"
                  width={171}
                  loading="lazy"
                  alt="LONACI"
                />
                <br />
                <br />
                <strong>Siège Social</strong>: Bvd de Marseille Zone 4, Rue du
                Chevalier de Clieu
                <br />
                Abidjan, Marcory
                <br />
                <strong>Tel</strong>: +225 2721753300
                <strong>Poste</strong>: 2223
                <br />
                <strong>Ligne Directe</strong>:
                <a href="tel:+225 2721753323" className="">
                  +225 2721753323
                </a>
                <br />
                01 BP 3878 Abidjan 01
              </div>
            </div>
          </div>
          <div
            id="nav_menu-5"
            className="col-lg-3 col-md-3 sub-section col-xs-12 col-sm-12 hidden-xs hidden-sm"
          >
            <h2 className="widgettitle">Nos Produits</h2>
            <div className="menu-produits-container">
              <ul id="menu-produits" className="menu">
                <li
                  id="menu-item-1476"
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1476"
                >
                  <a href="https://www.lonaci.ci/grattage/">Grattage</a>
                </li>
                <li
                  id="menu-item-1477"
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1477"
                >
                  <a href="https://www.lonaci.ci/loto_bonheur/" className="">
                    Loto Bonheur
                  </a>
                </li>
                <li
                  id="menu-item-1478"
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1478"
                >
                  <a href="https://www.lonaci.ci/sportcash/" className="">
                    Sportcash
                  </a>
                </li>
                <li
                  id="menu-item-1479"
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1479"
                >
                  <a href="https://www.lonaci.ci/pmu/">PMU</a>
                </li>
                <li
                  id="menu-item-1480"
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1480"
                >
                  <a
                    href="https://www.lonaci.ci/loterie-virtuelle/"
                    className=""
                  >
                    Loterie Virtuelle
                  </a>
                </li>
                <li
                  id="menu-item-2351"
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2351"
                >
                  <a href="https://www.lonaci.ci/casino-cash/" className="">
                    Casino Cash
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div
            id="nav_menu-2"
            className="col-lg-3 col-md-3 col-sm-3 col-xs-3 sub-section hidden-xs hidden-sm"
          >
            <h2 className="widgettitle">Liens Utiles</h2>
            <div className="menu-liens-utiles-container">
              <ul id="menu-liens-utiles" className="menu">
                <li
                  id="menu-item-1645"
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1645"
                >
                  <a href="https://www.lonaci.ci/charte-lonaci/" className="">
                    Notre Charte
                  </a>
                </li>
                <li
                  id="menu-item-1649"
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1649"
                >
                  <a
                    href="https://www.lonaci.ci/mentions-legales/"
                    className=""
                  >
                    Mentions Légales
                  </a>
                </li>
                <li
                  id="menu-item-1646"
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1646"
                >
                  <a
                    href="https://www.lonaci.ci/devenir-partenaire/"
                    className=""
                  >
                    Devenir Partenaire
                  </a>
                </li>
                <li
                  id="menu-item-1647"
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1647"
                >
                  <a href="#" className="">
                    Où Jouer ?
                  </a>
                </li>
                <li
                  id="menu-item-1648"
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1648"
                >
                  <a href="https://www.lonaci.ci/faq/">FAQ</a>
                </li>
              </ul>
            </div>
          </div>
          <div
            id="block-8"
            className="col-lg-3 col-md-3 col-sm-3 col-xs-3 sub-section hidden-sm hidden-xs"
          >
            <h2 className="widgettitle">Suivez-nous !</h2>
            <p />
            <ul className="social-networks rounded colored-hover">
              <li>
                <a
                  href="https://www.facebook.com/loterienationaledecotedivoire/"
                  target="_blank"
                  className="facebook"
                  title="Facebook"
                  aria-label="Facebook"
                  rel="noopener"
                >
                  {/* <i className="fab fa-facebook" /> */}
                  <FaFacebook size={13} color="#3b5998" />
                  <span className="name"> Facebook</span>
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UC6B9-wW2GB8GYty7bhzQujQ/featured"
                  target="_blank"
                  className="youtube"
                  title="YouTube"
                  aria-label="YouTube"
                  rel="noopener"
                >
                  {/* <i className="fab fa-youtube" /> */}
                  <FaYoutube size={13} color="red" />
                  <span className="name"> YouTube</span>
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/LONACI_officiel"
                  target="_blank"
                  className="twitter"
                  title="Twitter"
                  aria-label="Twitter"
                  rel="noopener"
                >
                  {/* <i className="fab fa-twitter" /> */}
                  <FaTwitter size={13} color="blue" />
                  <span className="name"> Twitter</span>
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/loterie_nationale_ci/"
                  target="_blank"
                  className="instagram"
                  title="Instagram"
                  aria-label="Instagram"
                  rel="noopener"
                >
                  {/* <i className="fab fa-instagram" /> */}
                  <FaInstagram size={13} color="purple" />
                  <span className="name"> Instagram</span>
                </a>
              </li>
            </ul>
            <p />
          </div>
        </div>
      </div>
    </div>
    <div className="footer-bottom">
      <div className="container">
        <div className="footer-bottom-content">
          <div className="footer-content-left">
            <div className="copyrights site-info">
              <p className="hosted-copy">
                © Copyright 2021&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                <a href="#" target="_blank" rel="noopener">
                  LONACI
                </a>{" "}
                Powered By{" "}
                <a href="" rel="noopener">
                  Direction des Systèmes de l'Information - DSI
                </a>
                &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;All Rights Reserved
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</div>

  <div className="modal pg-show-modal bg-black" id="searchin" tabIndex={-1} role="dialog" aria-hidden="true" style={{filter: 'alpha(opacity=100)', opacity: 1}} data-pgc="modalfullmenu" data-backdrop="true"> 
    <div className="modal-dialog" style={{width: '100%'}}>
      <div className="modal-content" style={{backgroundColor: 'transparent', border: 'none', boxShadow: 'none'}}> 
        <div className="modal-header" style={{border: 'none'}}> 
          <button type="button" className="close" data-dismiss="modal" aria-hidden="true" style={{fontSize: '50px', padding: '10px', paddingRight: '20px', marginTop: '-50px', marginRight: '-25px', backgroundColor: 'black', color: '#fff'}}>×</button>                         
        </div>                     
        <div className="modal-body" style={{backgroundColor: 'transparent', border: 'none'}}>
          <div className="container">
            <div className="row">
              <form action="/recherche" method="get" id="views-exposed-form-search-page" acceptCharset="UTF-8" className="bg-transparent" style={{padding: '30px'}}>
                <div className="amen container-fluid bg-transparent" data-pgc="search-block-amen" style={{padding: '0px'}}>
                  <div className="container-fluid" style={{padding: '0px'}}>
                    <div className="hero-area-form simplified-whois-311408">
                      <input autoComplete="off" autoFocus className="title-sm theme-text-medium" type="text" placeholder="Tapez un mot pour effectuer une recherche" name="domain" pattern=".{3,}" title="3 characters minium" required="required" id="gcsearchdy" />
                      <button className="btn btn-xl btn-primary margin-top0 bg-black white" type="button">
                        <i className="fa fa-search hidden-sm" />
                        <span>Rechercher</span>
                      </button>
                      <div className="suggested-tlds hidden bg-white" style={{padding: '30px'}}>
                      </div>
                    </div>
                  </div>
                  <div style={{position: 'fixe', zIndex: 9000, width: '100%', left: '0px', top: '55px', display: 'inline-block'}} className="container-fluid bg-white">
                    <div className="amen container-fluid bg-transparent pad0 hidden" style={{height: '80%', overflowY: 'auto', display: 'non', marginBottom: '30px'}} id="searchdy_result">
                      <a className="btn btn-default btn-lg white bg-black" style={{width: '100%'}} href="publications_category.html">Recherche avancee</a>
                      <div  className="container-fluid">
                        <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12" style={{color: 'white', paddingLeft: '0px'}}>
                          <p className="gogle-result-resume" style={{color: 'white'}}>Environ 1 170 000 000 résultats (1,06 secondes) </p>
                          <dl className="gogle-result-block pad-bottom10 thin-border-bottom"> 
                            <dd className="h2 theme-text-small black">A description list is perfect for defining terms.</dd>
                            <p style={{color: 'white'}} className="theme-text-normal black">Paragraph LeBron James, est un joueur professionnel américain de basket-ball. Mesurant 2,03 m et pesant 113 kg, il a la possibilité de jouer à la fois ailier et ailier fort. WikipédiaLeBron James, est un joueur professionnel américain de basket-ball. Mesurant 2,03 m et pesant 113 kg, il a la possibilité de jouer à la fois ailier et ailier fort. Wikipédia</p>
                            <p><a href><span>Biographie</span>Link</a><a href><span>Biographie</span>Link</a><a href><span>Biographie</span>Link</a><a href><span>Biographie</span>Link</a> </p> 
                          </dl>
                          <dl> 
                            <dd className="h2 theme-text-normal black">A description list is perfect for defining terms.</dd>
                            <p className="theme-text-normal black" style={{color: 'white'}}>Paragraph LeBron James, est un joueur professionnel américain de basket-ball. Mesurant 2,03 m et pesant 113 kg, il a la possibilité de jouer à la fois ailier et ailier fort. WikipédiaLeBron James, est un joueur professionnel américain de basket-ball. Mesurant 2,03 m et pesant 113 kg, il a la possibilité de jouer à la fois ailier et ailier fort. Wikipédia</p> 
                          </dl>                                                         
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>                         
        </div>                     
      </div>                 
    </div>             
  </div>         
  <div className="modal pg-show-modal no-padding-section" id="form_modal_exemple" tabIndex={-1} role="dialog" aria-hidden="true" data-pgc="coockies_v45" style={{zIndex: 900000, paddingTop: '100px !important'}}> 
    <div className="modal-dialog row no-border animated fadeInUp no-padding-section modal-lg" style={{width: '100%', marginBottom: '-60px !important', animationDuration: '0.3s!important'}}> 
      <div className="modal-content no-border" style={{marginLeft: '-13px'}}> 
        <div className="modal-header no-border theme-maincolorback" style={{borderRadius: '0px'}}> 
          <h2 className="text-left pull-left">Détails de la transaction</h2>
          <button type="button" className="close theme-text-big" data-dismiss="modal" aria-hidden="true">×</button>                         
        </div>                     
        <div className="modal-body no-padding-section"> 
          <div className="text-left container-fluid shadow-6 section theme-opacity-half">
            <div className="sub-section col-sm-12 col-xs-12 col-md-12 col-lg-12">
              <fieldset title="terzeeujke" className="appsv3-form-fieldset no-margin no-border no-padding-section">
                <div className="form-group">
                  <label className="control-label BOOKERFAST" htmlFor="formInput12">Prenom*</label>
                  <input type="text" className="form-control" id="user_surname" placeholder="Nom" data-pgc="gc-form-input" data-pgc-field="input" style={{height: '50px'}} />
                </div>
              </fieldset>
              <fieldset title="terzeeujke" className="appsv3-form-fieldset no-margin no-border no-padding-section">
                <div className="form-group">
                  <label className="control-label BOOKERFAST" htmlFor="formInput12">Prenom*</label>
                  <input type="text" className="form-control" id="user_surname" placeholder="Nom" data-pgc="gc-form-input" data-pgc-field="input" style={{height: '50px'}} />
                </div>
              </fieldset>
              <fieldset title="terzeeujke" className="appsv3-form-fieldset no-margin no-border no-padding-section">
                <div className="form-group">
                  <label className="control-label BOOKERFAST" htmlFor="formInput12">Prenom*</label>
                  <input type="text" className="form-control" id="user_surname" placeholder="Nom" data-pgc="gc-form-input" data-pgc-field="input" style={{height: '50px'}} />
                </div>
              </fieldset>
              <fieldset title="terzeeujke" className="appsv3-form-fieldset no-margin no-border no-padding-section">
                <div className="form-group">
                  <label className="control-label BOOKERFAST" htmlFor="formInput12">Nom*</label>
                  <input type="prenom" className="form-control" id="user_name" placeholder="Prenom" data-pgc="gc-form-input" data-pgc-field="input" style={{height: '50px'}} />
                </div>
              </fieldset>
              <fieldset title="terzeeujke" className="appsv3-form-fieldset no-margin no-padding-section no-border">
                <div className="form-group">
                  <label className="control-label BOOKERFAST" htmlFor="formInput12">Téléphone*</label>
                  <div className="container-fluid gc-divider-1" data-pgc="gc-divider" data-pgc-field="gc-divider_content" style={{height: '0px', backgroundColor: 'transparent'}} />
                  <input type="tel" className="form-control bg-transparent" id="userphone_indicatif" placeholder="Numéro de téléphone" data-pgc="gc-form-input" data-pgc-field="input" style={{height: '50px', width: '20%', borderRadius: '0px', float: 'left'}} defaultValue={+225} disabled="disabled" />
                  <input type="tel" className="form-control" id="userphone" placeholder="Numéro de téléphone" data-pgc="gc-form-input" data-pgc-field="input" style={{height: '50px', width: '80%', float: 'left', borderRadius: '0px'}} />
                </div>
              </fieldset>
              <fieldset title="terzeeujke" className="appsv3-form-fieldset no-margin no-padding-section no-border margin-top20">
              </fieldset>                                 
            </div>
          </div>
          <div className="text-left container-fluid sub-section shadow-6">
            <p className="pull-right"><a className="btn btn-lg" href="#" role="button">ENREGISTRER ET QUITTEZ</a></p>
          </div>                         
        </div>                     
      </div>                 
    </div>             
  </div>
    {/* <div className="modal pg-show-modal text-left no-shadow animated full-height secttion-responsive no-padding-section bg-transparent modal_coockie btn-default" id="cookies_modal" tabIndex={-1} role="dialog" aria-hidden="true" data-backdrop="false" style={{overflow: 'hidden', zIndex: 90000}}> 
    <div className="modal-dialog no-shadow modal-lg full-height margin-top0 bg-transparent" style={{width: '100%'}}> 
      <div className="modal-content full-height no-border bg-transparent" style={{boxShadow: 'none !important', width: '100%', overflow: 'hidden', backgroundColor: 'transparent !important'}}> 
        <div className="modal-header no-padding-section no-border hidden" style={{height: '10%'}}> 
          <button type="button" className="close hidden" data-dismiss="modal" aria-hidden="true">×</button>                         
        </div>                     
        <div className="modal-body container-fluid text-center full-height no-padding-section" style={{padding: '0px', height: '90%', width: '100%', overflow: 'hidden', backgroundColor: 'transparent !important'}}> 
          <div className="container-fluid theme-opacity-third bg-transparent no-padding-section" style={{height: '85%', backgroundColor: 'transparent !important'}}>
          </div>
         <div className="sub-section container-fluid shadow-6 bg-white" style={{height: '15%', overflow: 'hidden'}}>
            <div className="no-padding-section container" style={{marginTop: '-10px'}}>
              <div className="col-sm-8 sub-section col-xs-8 col-lg-9 col-md-9">
                <p className="margin-top0 text-left black" style={{marginBottom: '0px'}}>En poursuivant votre navigation sur le site, vous acceptez l'utilisation des cookies pour vous proposer notamment des publicités ciblées en fonction de vos centres d'intérêt.        <a href="https://www.rapidecard.com/contrat-de-conditions-dutilisation/">Terms of Service</a>.</p> 
              </div>
              <div className="col-sm-8 col-xs-4 col-lg-3 col-md-3 sub-section">
                <button onclick="closeCookie()" data-dismiss="modal" type="button" className="animated fadeIn pull-right margin-top0 btn-default btn bg-transparent tan" style={{right: '0px', width: '100%'}}>NE PAS ACCEPTER</button>
                <button onclick="closeCookie()" data-dismiss="modal" aria-hidden="true" type="button" className="btn animated fadeIn pull-right bg-guardsman white margin-top0 btn-default" style={{right: '0px', width: '100%'}}>ACCEPTER</button>                                     
              </div>
            </div>
</div>
        </div>                     
      </div>                 
    </div>        
  </div>*/}     
</div>
)
}

export default Home;
