import { useState, useEffect } from 'react';
import {LOCAL_STORAGE_KEYS } from '../constants';
import {UserService} from '../services';

export default function UseAuthentification() {

  function  handleLogout() {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.CURRENT_USER);
    if(localStorage.getItem(LOCAL_STORAGE_KEYS.DEVICE_MODE) === "MOBILE"){ 
      if(!window.location.href.endsWith("/login")) window.location.href = "/login"
    }
    else window.location.href = "/"
  }

 function  handleLogin(response, navigate) {
    localStorage.setItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, response.token);
    //message.success("STORE MANAGER : Bienvenue", 10);
    navigate && navigate("/dashboard");
  }

  const [currentUser, setCurrentUser] = useState(null);
  useEffect(() => {
    UserService.getUserInfo().then(setCurrentUser)
  },[localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN)]);

 const [isAuthenticated, setIsAuthenticated] = useState(true);

  useEffect(() => {
      setIsAuthenticated(((LOCAL_STORAGE_KEYS && LOCAL_STORAGE_KEYS.ACCESS_TOKEN && localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN)) ? true : false))
  }, [localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN)]);

  return {onLogout : handleLogout, onLogin : handleLogin, isAuthenticated : isAuthenticated, currentUser:currentUser};
}