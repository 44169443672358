import { PlusCircleOutlined } from "@ant-design/icons";
import { Alert, Drawer, Form, Space, Table } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { Button } from "../../components";


import AddCode from "./AddCode";
import { ConcessionnaireService } from "../../common/services";
import { AppContext } from "../../common/constants";
import { UseAuthentification } from "../../common/hooks";
import { render } from "@testing-library/react";

const mockData = [
    {
        produitLibelle: "EDITEC",
        site: "BIETRI",
        codeConcessionnaire: "1234",
        nomPrenom: 'PATRICK PATRICK'
    }
];
const CodeDistributeur=()=> {
    const {store} = useContext(AppContext)
    const auth  = UseAuthentification();
    const [form] = Form.useForm();
    const [alertErrors, setAlertErrors] = useState(null)
    const [alertInfo, setAlertInfo] = useState(null)
    const [openDrawer, setOpenDrawer] = useState(false)
    const [newCodes, setNewCodes] = useState([])
    const [isAddingCode, setIsAddingCode] = useState(false)
    const [state, setState]=useState({
        codes: [],
        isLoadingCodes: false,
    })

    useEffect(()=> {
        loadCodeConcessionnaires()
    }, [])

    const initAlert=()=> {
        setAlertInfo(null)
    }

    const loadCodeConcessionnaires= ()=> {
        initAlert()
        setState(state=> ({...state, isLoadingCodes: true}))
        ConcessionnaireService.get()
        .then((response)=> {
            setState(state=> ({...state, codes: response || []}))
        })
        .catch(error=> {
            console.log(error);
            setAlertErrors("Désolé! Une erreur est survenue, veuillez réessayer plus tard")
        })
        .finally(setState(state=> ({...state, isLoadingCodes: false})))
    }

    const onSubmit =()=> {
        // console.log("codes: ", codes)
        console.log("store : ", store)
        console.log("auth : ", auth)
        setIsAddingCode(true)
        const registerRequest= {
            id: auth?.currentUser?.id,
            lcaisseId: auth?.currentUser?.lcaisseId,
            nom: auth?.currentUser?.nom,
            prenom: auth?.currentUser?.prenom,
            mobile: auth?.currentUser?.contact,
            email: auth?.currentUser?.email, 
            concessionnaires: [...((newCodes || []).map((code)=> code?.concessionnaireId)), ...((state?.codes || []).map((code)=> code?.id))]
        }

        console.log("registerRequest : ", registerRequest);

        ConcessionnaireService.update(registerRequest)
        .then((response)=> {
            //setConfirmed(response?.status)
            setOpenDrawer(false)
            loadCodeConcessionnaires()
        })
        .catch(error=> {
            console.log(error)
            setAlertErrors("Une erreur est survenue pendant le traitement de votre requête, veuillez réessayer")
        })
        .finally(setIsAddingCode(false))
    }

    const columns = [
        {
            title: "Code",
            dataIndex: "codeConcessionnaire",
            key: "codeConcessionnaire",
            width: 80,
            responsive: ['lg', 'md']
        },
        {
            title: "Produit",
            dataIndex: "produit",
            key: "produit",
            responsive: ['ls', 'md']
        },
        {
            title: "Produit",
            dataIndex: "produit",
            key: "produit",
            responsive: ['xs'],
            render:(_, item)=> {
                return (`${item?.codeConcessionnaire} | ${item?.produit} | ${item?.site} `)
            }
        },

        {
            title: "Agence",
            dataIndex: "site",
            key: "site",
            responsive: ['lg', 'md']
        }
    ];

    return (
        <React.Fragment>
            <div className="container-fluid theme-hover-bg-zoom-in animatedParent full-height" style={{marginRight: 10}}>
                
                <div style={{padding: 10}}>
                    <div style={{display: 'flex', justifyContent: 'end'}}>
                        <Button className="btn btn-default btn-override virgin-button" onClick={()=> setOpenDrawer(true)} >Ajouter</Button>,
                    </div>
                    <Table 
                        columns={columns}
                        dataSource={state?.codes || []}
                        loading={state?.isLoadingCodes}
                    />
                </div>
                
            </div>

            <Drawer
                open={openDrawer}
                title={"Enrolement de code distributeur"}
                width={550}
                onClose={()=> loadCodeConcessionnaires()}
                footer={[
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <Button className="btn btn-default btn-override virgin-button" onClick={()=> setOpenDrawer(false)} >Annuller</Button>,
                        {alertErrors && (<div className="animated"><Alert message={`${alertErrors}`} type="error" /></div>)}
                        <Button className="white thin-border btn-override btn-primary" onClick={onSubmit} isLoading={isAddingCode}>Valider</Button>
                    </div>
                    
                ]}
            >
                { openDrawer && <AddCode setCodes={setNewCodes}/>}
            </Drawer>
            
        </React.Fragment>
    )
}

export default CodeDistributeur;