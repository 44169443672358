import { Form, Input } from 'antd';
import React from 'react';

const SimpleInputField=({label, placeholder, name, type, required=false, requireMsg, disable=false, size='large', iProps, suffix})=> {
    return (
        <React.Fragment>
                <Form.Item
                    style={{width: "100%"}}
                    label={label ?? ''} 
                    name={name}
                    rules={[{ required: required, type: type, message: requireMsg ?? "Champ obligatoire" }]}
                >
                    <Input placeholder={placeholder ?? label} size={size} disabled={disable} suffix={suffix} {...iProps} />
                    
                </Form.Item>
        </React.Fragment>
    )
}

export default SimpleInputField;