import {exeRequest} from '../utils/APIUtils'
import { LOCAL_STORAGE_KEYS } from '../constants';

export default {
 getUserInfo : async  () => (await exeRequest("/users/me", "GET")).data,
 signin : async (data) =>  (await exeRequest("/auth/signin", "POST", data)).data,
 changePassword : async (data) =>  (await exeRequest("/users/update-password", "POST", data)),
 forgetPassword : async  (data) => (await exeRequest("/auth/me/forget-password", "POST", data)),
 resetPassword : async  (data) => (await exeRequest("/auth/me/reset-password", "POST", data)).data,
 update : async (data) =>  (await exeRequest("/users", "PUT", data))
}