import { Alert, Col, Divider, Form, Row, Space, Table } from "antd";
import React, { useEffect, useState } from "react";
import ComboField from "../../../components/forms/fields/ComboField";
import SimpleInputField from "../../../components/forms/fields/SimpleInputField";
import NumberFormatField from "../../../components/forms/fields/NumberFormatField";
import { Button } from "../../../components";
import { ConcessionnaireService, ProduitService } from "../../../common/services";
import { DeleteOutlined } from "@ant-design/icons";
import _ from "lodash";

const Step1=({goToNext})=> {
    const [form] = Form.useForm();
    const [alertErrors, setAlertErrors] = useState(null)
    const [alertInfo, setAlertInfo] = useState(null)
    const [state, setState]=useState({
        products: [],
        isProductsLoding: false,

        isConcessionnaireVerifying: false,
        // table 
        tmpData: [
            // {
            //     produitId: 1,
            //     produitLibelle: "EDITEC",
            //     codeConcessionnaire: "12345",
            //     numeroTerminal: "1234",
            //     chiffreAffaire: 20000
            // }
        ]
    })
    
    useEffect(()=> {
        loadProducts();
    }, [])
    
    const loadProducts =()=> {
        ProduitService.getAll().then((data)=> {
            setState(state=> ({...state, products: (data || [])?.filter((product)=> product?.id != 10)}))
        }).catch((error)=> {}).finally(setState(state=> ({...state, isProductsLoding: false})))
    }

    const initAlert=()=> {
        setAlertInfo(null)
        setAlertErrors(null)
    }

    const findItem=(item, elt)=> item.produitId === elt?.produitId && 
    item.codeConcessionnaire === elt?.codeConcessionnaire; // && 
    //item.numeroTerminal === elt?.numeroTerminal;

    const onAddSubmit=()=> {
        initAlert();
        form.validateFields().then((values)=> {
            setState(state=> ({...state, isConcessionnaireVerifying: true}))
            const chiffreAffaire = _.replace(values?.chiffreAffaire, new RegExp("[\\s-]","g"), "");
            const verifyRequest ={...values, chiffreAffaire: parseInt(chiffreAffaire)}
            
            ConcessionnaireService.verify(verifyRequest).then((response)=> {
                console.log("Response : ", response);
                
                if(response?.status){
                    const data = response?.data;
                    const elt = state?.tmpData.find((item)=> findItem(item, data));
                    
                    if(!elt) {
                        setState(state=> ({
                            ...state, 
                            tmpData: [...state?.tmpData, {
                                produitId: data?.produitId,
                                produitLibelle: state?.products.find((item)=> item?.id === data?.produitId)?.libelle,
                                concessionnaireId: data?.concessionnaireId,
                                codeConcessionnaire: data?.codeConcessionnaire,
                                numeroTerminal: data?.numeroTerminal,
                                chiffreAffaire: data?.chiffreAffaire,
                                site: data?.site,
                                nomPrenom: data?.nomPrenom
                            }]
                        }))
                    } else setAlertInfo("Le code concessionnaire est déjà pris en compte")

                } else setAlertErrors(response?.message)
            }).catch(error=> {
                console.log("error :", error)
                setAlertErrors("Une erreur est survenue, veillez réessayer")
            }).finally(()=> setState(state=> ({...state, isConcessionnaireVerifying: false})))
        })
    }

    const onSubmit=()=> {
        if(_.size(state?.tmpData) > 0) {
            goToNext({step1: [...state?.tmpData]})
        }
        else {
            console.log("Table size: ", _.size(state?.tmpData))
        }

    }

    const delToTmpData = (tmpDataItem)=> {
        
        const _tmpDataItems = state?.tmpData.filter((item)=> !findItem(item, tmpDataItem));
        setState(state=> ({...state, tmpData: _tmpDataItems}))
    }

    const columns = [
        {
            title: "Produit",
            dataIndex: "produitLibelle",
            key: "produitLibelle",
            responsive: ['ls', 'md']
        },
        {
            title: "Produit",
            dataIndex: "produitLibelle",
            key: "produitLibelle",
            responsive: ['xs'],
            render:(_, item)=> {
                return (`${item?.produitLibelle} | ${item?.site} | ${item?.codeConcessionnaire} | ${item?.nomPrenom}`)
            }
        },

        {
            title: "Agence",
            dataIndex: "site",
            key: "site",
            responsive: ['lg', 'md']
        },

        {
            title: "Code",
            dataIndex: "codeConcessionnaire",
            key: "codeConcessionnaire",
            width: 80,
            responsive: ['lg', 'md']
        },
        {
            title: "Nom & Prénoms",
            dataIndex: "nomPrenom",
            key: "nomPrenom",
            responsive: ['lg', 'md']
        },
        // {
        //     title: "Terminal",
        //     dataIndex: "numeroTerminal",
        //     key: "numeroTerminal",
        //     width: 100,
        // },
        // {
        //     title: "Chiffre d'affaire",
        //     dataIndex: "chiffreAffaire",
        //     key: "chiffreAffaire",
        // },
        {
            title: "",
            dataIndex: "action",
            key: "action",
            width: 50,
            fixed: 'right',
            align: 'center',
            render: (_, produit)=> {
                return (
                    <Space>
                        <DeleteOutlined onClick={()=> delToTmpData(produit)}/>
                    </Space>
                )
            }
        }
    ]
    
    return(
        <React.Fragment>
            <Form form={form}>
                <div className="container-fluid no-padding-section">
                    <h3>Etape 1 / 2 : Ajout de code distributeur</h3>
                    <p>Veuillez renseignez les informations précises relatives à l'un des terminaux du code distributeur à ajouter. Faites cela pour chaque code distributeur que vous détenez.</p>
                </div>
                {/* gc-form-data */}
                <div className="container-fluid  no-padding-section " >
                    {/* {errors && (<div  className="form-group rechargement__errors" >{ errors}</div>)}    */}
                    <Space direction='vertical' style={{width: "100%"}}>
                        <Row gutter={[8,8]} >
                            <Col md={12} xs={24}>
                                <ComboField name={'produitId'} showSearch={false} placeholder={"Produit"} options={(state?.products || []).map((product)=> ({name: product?.libelle, value: product?.id}))} height={45} required={true}/>
                            </Col>
                            <Col md={12} xs={24}>
                                <SimpleInputField name={"codeConcessionnaire"} placeholder={"Code concessionnaire"} className="form-control" required={true} />
                            </Col>
                            <Col md={12} xs={24}>
                                <SimpleInputField name={"numeroTerminal"} placeholder={"Numéro de rechargement d'un terminal"} className="form-control" required={true} />
                            </Col>
                            <Col md={12} xs={24}>
                            <NumberFormatField name={"chiffreAffaire"} placeholder="Dernier chiffre d'affaire net journalier" className="form-control" required={true}/>
                            </Col>
                        </Row>
                        
                        {/* <div className="form-group"  style={{display: 'flex', justifyContent: 'space-between', gap: 5}} >
                            <ComboField name={'produitId'} showSearch={false} placeholder={"Produit"} options={(state?.products || []).map((product)=> ({name: product?.libelle, value: product?.id}))} height={45} required={true}/>
                            <SimpleInputField name={"codeConcessionnaire"} placeholder={"Code distributeur"} className="form-control" required={true} />
                        </div> */}
                        {/* <div style={{display: 'flex', justifyContent: 'space-between', gap: 5}} >
                            <SimpleInputField name={"numeroTerminal"} placeholder={"Numéro de terminal"} className="form-control" required={true} />
                            <NumberFormatField name={"chiffreAffaire"} placeholder="Dernier chiffre d'affaire" className="form-control" required={true}/>
                        </div> */}
                        {alertInfo && (<div className="animated"><Alert message={`${alertInfo}`} type="warning" showIcon closable/></div>)}
                        {alertErrors && (<div className="animated"><Alert message={`${alertErrors}`} type="error" /></div>)}
                        
                    </Space>
                    
                    <Divider children={<Button onClick={onAddSubmit}  className="thin-border btn-override add-btn" isLoading={state?.isConcessionnaireVerifying}><span >Ajouter</span></Button>}/>
                    <p>
                        <Table 
                            columns={columns} 
                            dataSource={state?.tmpData || []} 
                            //loading={isLoading}
                            // pagination={{ position: ["topRight"], pageSize: state?.pageSize }}
                            pagination={false}
                            scroll={{
                                x: 620,
                                y: 150,
                            }}
                            bordered
                            loading={state?.isConcessionnaireVerifying}
                            size="small"
                            />
                    </p>
                </div>
                <div className="virgin-button" data-pgc="nostyle_button" data-pgc-field="nostyle_buttonr">
                    <Button className="btn btn-default btn-override virgin-button" onClick={()=> window.location.href='/'} >Retour</Button>
                    <Button className="white thin-border btn-override btn-primary" onClick={onSubmit}>Suivant</Button>
                </div>
            </Form>
        </React.Fragment>
    )
}

export default Step1;