
import { useContext, useEffect, useState, useMemo } from 'react';
import { withMask } from 'use-mask-input';
import { AppContext } from '../../common/constants';

import { Link } from 'react-router-dom';
import OrangeMoneyLogo from '../../layout/themes/images/partners/orangemoneylogo.jpg';
import MomoLogo  from '../../layout/themes/images/partners/mtn-momo.png';
import MoovLogo  from '../../layout/themes/images/partners/moov-money.png';
import WaveLogo  from '../../layout/themes/images/partners/wave-logo.png';
import SuccessImage from '../../layout/themes/images/icon/success-02.png'
import EchecImage from '../../layout/themes/images/icon/echec.png'
import {RechargementService, TerminalService} from '../../common/services';
import { Breadcrumbs, Button, LoadableContent } from '../../components';
import { DateHelper } from '../../common/helper';

import './Rechargements.component.scss'


const Rechargement = () => {
  const initializeData = () => ({terminal : {id : ""}, amount : "", phone : "", typeMomo: ""})

  const {store} = useContext(AppContext);
  const [currentTab, setCurrentTab] = useState(0)
  const [terminaux, setTerminaux] = useState([]);
  const [data, setData] = useState(initializeData())
  const [response, setResponse] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [errors, setErrors] = useState("");
  const [amountFormatError, setAmountFormatError] = useState(false);

  const date = useMemo(() => DateHelper.getMonthFirstAndLastDate(`${store.month}-01`), [store.month])


  useEffect(() => {
    if(store?.concessionnaire?.id) {
      setLoading(true)
      TerminalService.get(store.concessionnaire.id, {debut : date.firstDay?.toISOString(), fin : date.lastDay?.toISOString()})
      .then(setTerminaux)
      .finally(() => setLoading(false))
    }
  }, [store?.concessionnaire?.id])

  const onChange = (fieldData) => {
    setErrors(null)
    setAmountFormatError(parseInt(fieldData?.amount) % 5 != 0)
    setData(_data => ({..._data, ...fieldData}))
  }

  const isValidUrl = urlString=> {
    var urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
    '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
  return !!urlPattern.test(urlString);
}

  const dynamicText = "Rechargement";
  
    return (
    <>
     <Breadcrumbs secondLinkText={dynamicText} />
      <div className="appsv3-admin-bloc animated fadeIn col-xs-12 col-sm-12 col-md-12 col-lg-12 sub-section" style={{paddingTop: '10px !important'}}>
        <div className="appsv3-admin-bloc-title" style={{paddingLeft: '12px'}}>
          <p data-pgc-field="appsv3-admin-bloc-title">Effectuer un rechargement</p> 
        </div>
      
        <div className="container-fluid sub-section">
          <div className="no-padding-section col-md-6">
          <LoadableContent {...{isLoading}}>
            <div className="container-fluid theme-maincolorback-light sub-section">
              <form id="regForm" action style={{marginTop: '0px', width: '100%', marginBottom: '0px', backgroundColor: 'transparent', paddingTop: '0px', paddingBottom: '0px'}} className="no-padding-section">
                {/* One "tab" for each step in the form: */}
                <div className="tab" style={{backgroundColor: 'transparent', display : currentTab === 0 ? 'block' : 'none'}}>
                  <div className="container-fluid no-padding-section">
                    <h3>Etape 1 / 2 : Saisie des informations de rechargement</h3>
                    <p>Renseignez les informations relatives au rechargement</p>
                  </div>
                  <div className="container-fluid no-padding-section">
                  {errors && (<div  className="form-group rechargement__errors" >{ errors}</div>)}   
                    <p>
                      <select value={data.terminal?.id} onChange={(event) => onChange({terminal : terminaux.find(ter => ter.id?.toString() === event.target.value)})} className="form-control">
                          <option value="">Sélectionnez un terminal</option>                                                             
                          {terminaux.map(terminal => <option key={terminal.numero} value={terminal.id}>{terminal.numero}</option>  )}                                                               
                      </select>
                    </p>
                    <p>
                      <select value={data.typeMomo} className="form-control" onChange={(event) => {
                         if(event.target.value === "VISA Africards" || data.typeMomo === "VISA Africards"){
                           setData(_data => ({..._data, phone : ""}))
                         }
                         onChange({typeMomo : event.target.value});
                        }}>
                          <option value="">Sélectionnez votre moyens de paiement</option>                                                             
                          <option value="ORANGE Money">ORANGE Money</option>                                                           
                          <option value="MTN Money">MTN Money</option>                                                           
                          <option value="MOOV Money">MOOV Money</option>                                                           
                          <option value="WAVE">WAVE </option>                                                           
                          <option value="VISA Africards">VISA Africards</option>                                                           
                      </select>
                    </p>
                    <p>
                       <input ref={withMask('9999 9999 9999 9999')} style={{display : data.typeMomo === "VISA Africards" ? "block" : "none"}} placeholder="Entrez le numero de la carte" value={data.phone} onChange={(event) =>onChange({phone : event.target.value})} className="form-control" />
                       <input ref={withMask('99 99 99 99 99')} style={{display : data.typeMomo !== "VISA Africards" ? "block" : "none"}}  placeholder="Entrez le numero de téléphone" value={data.phone} onChange={(event) => onChange({phone : event.target.value})} className="form-control" />
                    </p>
                    <p>
                      <input  placeholder="Entrez le montant" 
                      value={data.amount ? Number.parseInt(data.amount).toLocaleString("fr-FR") : ""} onChange={(event) =>{ 
                        const newValue = event.target.value.replace(/\s/g,'');
                        if(isNaN(newValue)) return;
                        onChange({amount : newValue})
                        }} className="form-control" /> 
                        {amountFormatError && (<><br/> <span  className="form-group rechargement__errors" >Le montant doit être un multiple de 5</span></>)} 
                    </p>    
                  </div>
                  <div style={{overflow: 'auto'}} className="virgin-button">
                    <div style={{float: 'right'}}>
                      <button type="button" className="btn white thin-border btn-default btn-primary virgin-button" onClick={() =>{
                        if(!data.terminal?.id || !data.amount || !data.phone || !data.typeMomo || amountFormatError) { 
                          setErrors("Veuillez renseigner tous les champs correctement");
                          return;
                        }
                        
                        setCurrentTab(currentTab + 1)
                        }}  >Suivant</button>
                    </div>
                  </div>
                </div>
                <div className="tab" style={{backgroundColor: 'transparent', display : currentTab === 1 ? 'block' : 'none'}}>
                  <div className="container-fluid no-padding-section">
                    <h3>Étape 2 / 2 : Confirmation des informations de rechargement</h3>
                    <p>Verifiez les informations saisies et confirmez le rechargement</p>
                  </div>
                  <div className="appsv3-admin-bloc animated fadeIn no-padding-section container-fluid bg-transparent thin-border" style={{backgroundColor: 'transparent !important'}}>
                    <div id="loaded" className="container-fluid" />
                    <div className="container-fluid no-padding-section">
                      <div className="appsv3-admin-bloc-title container-fluid sub-section transactions_list" style={{backgroundColor: 'transparent !important'}}>
                        <p data-pgc-field="appsv3-admin-bloc-title" className="pull-left">Résumé</p> 
                      </div>
                      <div className="no-border container-fluid transactions_list">
                        <div className="row apps-line-data">
                          <div className="col-md-5 col-lg-5 col-sm-5 col-xs-5">
                              <p style={{ fontSize: '2em !important' }}>Terminal</p>                                                                
                          </div>
                          <div className="col-md-6 col-lg-6 col-sm-6 col-xs-6">
                          <p className="details">{`${data.terminal?.numero} (${store?.concessionnaire?.code})`}</p>
                          </div>
                        </div>
                        <div className="row apps-line-data">
                          <div className="col-md-5 col-lg-5 col-sm-5 col-xs-5">
                              <p>Moyens de paiement</p>                                                                
                          </div>
                          <div className="col-md-6 col-lg-6 col-sm-6 col-xs-6">
                            <p className="details">{`${data.typeMomo}`}</p> 
                          </div>
                        </div>
                        <div className="row apps-line-data">
                          <div className="col-md-5 col-lg-5 col-sm-5 col-xs-5">
                              <p>{data.typeMomo === "VISA Africards" ? "Carte" : "Téléphone"}</p>                                                           
                          </div>
                          <div className="col-md-6 col-lg-6 col-sm-6 col-xs-6">
                            <p className="details">{`${data.phone}`}</p> 
                          </div>
                        </div>
                        <div className="row apps-line-data">
                          <div className="col-md-5 col-lg-5 col-sm-5 col-xs-5">
                              <p>Montant</p>                                                              
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <p className="details">{Number.parseInt(data.amount ?? 0).toLocaleString("fr-FR")}</p> 
                          </div>
                        </div>
                      </div>
                      <div style={{display : 'flex', justifyContent : 'flex-end'}} className="virgin-button text-right sub-section" data-pgc="nostyle_button" data-pgc-field="nostyle_buttonr">
                        <button type="button" onClick={() => setCurrentTab(currentTab - 1)}  className="btn white thin-border btn-default virgin-button" style={{marginRight : '5px'}} >Precedent</button>
                        <Button type="button" 
                        isLoading={isBtnLoading}
                        onClick={async() => {
                          try{
                          setBtnLoading(true);
                          const response = await RechargementService.rechargeMomo({...data,terminal: data.terminal.id, phone : data.phone.replaceAll(" ", "")});
                          setResponse(response);
                          setCurrentTab(currentTab + 1)
                          }catch{
                            setCurrentTab(currentTab + 2)
                          }finally{
                            setBtnLoading(false);
                          }
                        }} 
                        className="btn white thin-border btn-default btn-primary virgin-button launch_transaction" >
                          Valider
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab" style={{backgroundColor: 'transparent', display : currentTab === 2 ? 'block' : 'none'}}>
                  <div className="full-height section container-fluid" style={{}}>
                    <div className="container-fluid" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                      {!response?.operatorId ? (
                        <img src={EchecImage} style={{verticalAlign: 'middle', display: 'inline-block', width : '100px'}} data-pgc-field="loading_div_icon" className="bounceIn clearfix" />
                      ) : (
                        <img src={SuccessImage} style={{verticalAlign: 'middle', display: 'inline-block', width : '100px'}} data-pgc-field="loading_div_icon" className="bounceIn clearfix" />
                      )}
                    </div>
                    <br />
                    <h3 className="clearfix text-center">
                      {!response?.operatorId ? "Echec Transaction" : "Transaction réussie"}
                    </h3>
                    <center style={{fontSize : '15px', marginTop : '15px', color: 'red'}} id='transError'>
                      {response &&  (isValidUrl(response.msg) ? <a className='rechargement__link' href={response.msg} >Cliquez ici pour finaliser votre paiement</a> : response.msg)}
                    </center>
                    <div className="virgin-button text-center section" data-pgc="nostyle_button" data-pgc-field="nostyle_buttonr">
                      <Link className="btn white thin-border btn-default virgin-button" style={{marginRight : '5px'}} to="/dashboard" >Fermer</Link>
                      <button style={{ height: '45px'}} type="button" onClick={() => {
                        setData(initializeData());
                        setCurrentTab(0);
                      }} className="btn white thin-border btn-default btn-primary virgin-button launch_transaction">
                        Effectuer une autre transaction
                      </button>
                    </div>
                  </div>
                </div>

                <div className="tab" style={{backgroundColor: 'transparent', display : currentTab === 3 ? 'block' : 'none'}}>
                <div className="full-height section container-fluid" style={{}}>
                  <div className="container-fluid" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <img src={EchecImage} style={{verticalAlign: 'middle', display: 'inline-block', height: '50px'}} data-pgc-field="loading_div_icon" className="bounceIn clearfix" />
                  </div>
                  <br />
                  <h3 className="clearfix text-center">Echec Transaction</h3>
                  <center style={{fontSize : '13px', marginTop : '15px', color: 'red'}}> {response && response.msg}</center>
                  <div className="virgin-button text-center section" data-pgc="nostyle_button" data-pgc-field="nostyle_buttonr">
                    <Link className="btn white thin-border btn-default virgin-button" style={{marginRight : '5px'}} to="/dashboard" >Fermer</Link>
                    <button style={{ height: '45px'}} type="button" onClick={() => {
                      setData(initializeData());
                      setCurrentTab(0)
                      }} className="btn white thin-border btn-default btn-primary virgin-button launch_transaction">Effectuer une autre transaction
                    </button>
                  </div>
                </div>
                {/* console.log(res); */}
                </div>
                {/* Circles which indicates the steps of the form: */}
                <div style={{textAlign: 'center', marginTop: '40px'}}>
                  <span className={`step ${currentTab === 0 ? 'active' : ''}`} />
                  <span className={`step ${currentTab === 1 ? 'active' : ''}`} />
                  <span className={`step ${currentTab === 2 ? 'active' : ''}`} />
                </div>
              </form>
            </div>             
           </LoadableContent>                                    
          </div>
          <div className="col-md-6 text-center sub-section">
            <h4 className="theme-text-thin">Paiement par mobile money <br />via la plateforme LonaciPay.</h4>
            <div className="container-fluid" style={{alignItems: 'center', justifyContent: 'space-between !important'}}>
              <img src={OrangeMoneyLogo} style={{width: '80px', height: '60px'}} />
              <img src={MomoLogo} style={{width: '70px', height: '50px', marginRight: '4px'}} />
              <img src={MoovLogo} style={{width: '80px',height: '50px', marginRight: '4px'}} />
              <img src={WaveLogo} style={{width: '80px',height: '40px'}} />
            </div>                                     
          </div>
        </div>
    </div>
    </>

    )
}

export default Rechargement;